import { Button, Modal, Table, message } from "antd";
import { FichaGestacionalAPI } from "api/models/FichaGestacional";
import useAxios from "core/hooks/useAxios";
import { Column, Heading, Row, Typography } from "core/util-styled-components";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import React, { useState } from "react";
import AgregarEditarCambioSeguro from "./CambioSeguro/AgregarEditar";
import { InformePDF } from "core/exports/generate_pdf";

const FactorRiesgoTag = ({ ubicacion, color, factorRiesgo, onClick = () => {}, fo, uo }) => {
  return (
    <Row justifyContent="space-between" gap="10px">
      <Typography variant="label" onClick={onClick}>
        <Row gap="10px">
          {fo && (
            <Typography style={{ fontWeight: "600" }}>
              <Row gap="5px">
                {color && <div>{getColor(color)}</div>}
                {factorRiesgo}
              </Row>
            </Typography>
          )}
          {uo && ubicacion}
        </Row>
      </Typography>
    </Row>
  );
};

const getColor = (color) => {
  return color === "AMARILLO" ? "🟡" : color === "ROJO" ? "🔴" : color === "VERDE" ? "🟢" : "⚪";
};
const CambioSeguro = () => {
  const ListMigraciones = useAxios(FichaGestacionalAPI.listCambioSeguro);
  const EliminarMigracion = useAxios(FichaGestacionalAPI.delete);
  const [modalVisible, setModalVisible] = useState(false);
  const [registroSeleccionado, setRegistroSeleccionado] = useState<any>({});

  const eliminarMigracionCtrl = async (data) => {
    Modal.confirm({
      title: "Eliminar",
      content: "¿Desea eliminar este registro?",
      onOk: async () => {
        const response = await EliminarMigracion.startRequest(data);
        if (response.check) {
          message.success("Se ha modificado una migración");
          setModalVisible(false);
          ListMigraciones.startRequest();
        } else {
          message.error("No se ha modificado ninguna migración");
        }
      },
    });
  };

  const descargarPDF = (row) => {
    InformePDF({ informe: row });
  };

  return (
    <Column gap="10px">
      <Row justifyContent="space-between">
        <Heading>Lista de cambios de seguro</Heading>
        <Row gap="10px">
          {/* <Input /> */}
          <Button
            type="primary"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Agregar cambio de seguro
          </Button>
        </Row>
      </Row>
      <Table
        dataSource={ListMigraciones.data}
        expandable={{
          columnWidth: 48,
          expandedRowRender: (record) => (
            <Column>
              <Typography style={{ margin: 0 }}>
                <b>Motivo:</b> {record.motivoCambioSeguro}
              </Typography>
            </Column>
          ),
          rowExpandable: (record) => record,
        }}
        columns={generateMapTable([
          {
            dataIndex: "nroDocumento",
            title: "Nro de Documento",
          },
          {
            dataIndex: "nombres",
            title: "Apellidos y Nombres",
          },
          {
            dataIndex: "edadRegistro",
            title: "Edad",
          },
          {
            title: "Fecha de ficha",
            dataIndex: "fecha",
            render: (f) => format(addHours(new Date(f), 5), "dd/MM/yyyy"),
          },
          {
            title: "Factores de riesgo",
            dataIndex: "factores",
            render: (factores) => (
              <Column alignItems="flex-start" style={{ maxHeight: "60px", overflow: "auto" }}>
                {factores.map((item, index) => {
                  const { FactorRiesgo, color, UBICACION } = item;
                  return <FactorRiesgoTag key={index} ubicacion={UBICACION?.split("|")[UBICACION.split("|").length - 1]} color={color} factorRiesgo={FactorRiesgo} fo={true} uo={false} />;
                })}
              </Column>
            ),
          },
          {
            render: (row) => (
              <Row gap="5px">
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    setRegistroSeleccionado(row);
                    setModalVisible(true);
                  }}
                  style={{ backgroundColor: "#67C4FF" }}
                >
                  <i className="ri-edit-line" />
                </Button>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#4A64F4" }}
                  shape="circle"
                  onClick={() => {
                    descargarPDF(row);
                  }}
                >
                  <i className="ri-printer-line" />
                </Button>
                <Button type="primary" danger shape="circle" onClick={() => eliminarMigracionCtrl(row)}>
                  <i className="ri-delete-bin-line" />
                </Button>
              </Row>
            ),
          },
        ])}
      />
      {modalVisible && (
        <AgregarEditarCambioSeguro
          active={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            setRegistroSeleccionado(null);
          }}
          onSuccess={() => {
            setModalVisible(false);
            setRegistroSeleccionado(null);
            ListMigraciones.startRequest();
          }}
          defaultValue={registroSeleccionado}
        />
      )}
    </Column>
  );
};

export default CambioSeguro;
