import styled from "styled-components";
import { Theme } from "./styled";

export const GlobalStyled = styled.div`
  * {
    box-sizing: border-box;
  }
  body, html {
    padding: 0;
    margin: 0;
  }
  .no-padding {
    margin: 0 !important;
    padding:0 !important;
  }
  a {
    text-decoration: none;
  }
  .react-dropdown-select-dropdown,
  .react-dropdown-select-dropdown * {
    font-family: ${({ theme }) => theme.fonts.fontFamily} !important;
  }

  .p-datatable-wrapper {
    font-family: ${({ theme }) => theme.fonts.fontFamily} !important;
    border-radius: 0.35rem !important;
  }
  .p-datatable .p-datatable-thead > tr > th {
    font-family: ${({ theme }) => theme.fonts.fontFamily} !important;
    background-color: #5e5bff !important;
    color: #fff !important;
  }

  .p-datatable-header {
    border: none !important;
    background-color: #fff !important;

  }


  /* responsive for p-datatable */
  @media (max-width: 768px) {
    .p-datatable .p-datatable-thead > tr > th {
      font-size: 0.7rem !important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
      font-size: 0.7rem !important;
  }
  .p-component.p-panelmenu-header{
    padding: 0.05rem 0.2rem !important;
  }

  .p-component.p-panelmenu-header.p-highlight {
    outline: none !important;
  }

  .p-component.p-panelmenu-header.p-disabled {
    display: none !important;
  }

  .p-panelmenu-header-link:active {
    outline: none !important;
  }
  .p-panelmenu .p-panelmenu-header > a:focus {
    box-shadow: none !important;
  }
  .ant-table-cell {
    font-family: ${Theme.fonts.fontFamily} !important
  }
`;
