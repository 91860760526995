import React, { useState } from "react";
import {
  // ReportEnviromentAPI,
  ReportLevelDropdownDefinition,
} from "api/models/Filters";
import { generateMapForm } from "core/utils/tables";
import { Formulario } from "core/ts_formulario_model";
import { Card } from "antd";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { addDefaultValues } from "core/utils/form";
// import useAxios from "core/hooks/useAxios";
const FilterHomeGlobal = ({ onFilter, onTutorial }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  // const [enviromenteSelected, setEnviromenteSelected] = useState([]);

  // const EnviromentList = useAxios(ReportEnviromentAPI);

  return (
    <Card size="small" bodyStyle={{ padding: "0" }}>
      <Formulario
        onSubmit={(data) => {
          const nivel = data.idnivelReporte[0].descripcion;
          let ambs = [];
          if (nivel !== "REGION") {
            ambs = data.ambito?.filter((amb) => amb.NIVEL === nivel);
          }
          onFilter({
            anio: (Array.isArray(data.anio) && data.anio.length ? data.anio[0].name : null) || new Date().getFullYear(),
            nivel,
            ambito: ambs?.length ? ambs[0].AMBITO : "",
          });
        }}
      >
        <Formulario.FormControls
          cols={isTabletOrMobile ? 1 : 2}
          inputs={generateMapForm(
            addDefaultValues(
              [
                {
                  name: "anio",
                  type: "dropdown",
                  optionsDropdown: [{ name: "2023" }, { name: "2024" }],
                  optionLabel: "name",
                  optionValue: "name",
                  defaultValue: [{ name: "2024" }],
                  customProps: {
                    displayName: "Año",
                    defaultValue: [{ name: "2024" }],
                  },
                },
                ReportLevelDropdownDefinition,
              ],
              {
                anio: "2024",
              }
            )
          )}
          formControlStyles={{
            flexDirection: isTabletOrMobile ? "column" : "row",
            widht: "100%",
          }}
          submitLabel="Buscar..."
          cancelLabel="Dame un tutorial"
          onCancel={onTutorial}
          orientation={isTabletOrMobile ? "column" : "row"}
          buttonProps={{
            margin: "0",
            style: {
              flexDirection: "row-reverse",
            },
            cancel: {
              style: { width: "100%" },
            },
            submit: {
              style: { width: "100%" },
            },
          }}
          buttonWidthPanel={isTabletOrMobile ? "100%" : "30%"}
        />
      </Formulario>
    </Card>
  );
};

export default FilterHomeGlobal;
