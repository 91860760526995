import { AUTH_ROUTE_PREFFIX } from "api/constants";
import { generateRelationCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";
import { ModuleDropdownDefinition } from "../Module";
import { EndpointDropdownDefinition } from "../EndPoint";

export interface ModuleEndPoint {
  idModulo: number;
  modulo?: string;
  idEndPoint: number;
  endPoint?: string;
}

export const ModuleEndPointAPI = generateRelationCrudApi(
  AUTH_ROUTE_PREFFIX + "module-enpoint"
);

export const ModuleEndPointForm: IInputPayload[] = generateMapForm([
  ModuleDropdownDefinition,
  EndpointDropdownDefinition,
]);
