import { ModuleEndPointForm, ModuleEndPointAPI } from ".";
import { ModuleEndPointTable } from "./table";

export const ModuleEndPointConfig = {
  id: 4,
  name: "ModuleEndPoint",
  apiConfig: ModuleEndPointAPI,
  columns: ModuleEndPointTable,
  form: ModuleEndPointForm,
};
