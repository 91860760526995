import { Card, Input, message, Table } from "antd";
import { PacientDocs } from "api/models/Pacient/data";
import { VigilanceAPI } from "api/models/Vigilance";
import useAxios from "core/hooks/useAxios";
import { Row, Typography } from "core/util-styled-components";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import React, { useEffect, useState } from "react";

const TermEmb = () => {
  const TermEmbList = useAxios(VigilanceAPI.terminosEmb);
  const [anio, setAnio] = useState("2024");

  useEffect(() => {
    TermEmbList.startRequest({ anio });
    return () => {};
    // eslint-disable-next-line
  }, [anio]);

  const onChangeYear = ({ target }) => {
    const value = target.value;
    if (value >= 1990 && target.value <= 2099) {
      setAnio(value);
    } else {
      message.error("Escoge un año correcto");
    }
  };

  const getRenderTipDoc = (idTipoDoc) => {
    const doc = PacientDocs.find((el) => el.Id_Tipo_Documento === idTipoDoc);
    return (
      <Row>
        <Typography>{doc.Abrev_Tipo_Doc}</Typography>
        {/* <Typography variant="label">{doc.Descripcion_Tipo_Documento}</Typography> */}
      </Row>
    );
  };

  return (
    <Card title="Terminos de embarazo" extra={<Input type="number" min="1900" max="2099" step="1" value={anio} onChange={onChangeYear} />}>
      <Table
        dataSource={TermEmbList.data}
        columns={generateMapTable([
          {
            dataIndex: "idTipoDocumento",
            title: "Tipo de documento",
            render: getRenderTipDoc,
          },
          {
            dataIndex: "Num_Doc",
            title: "Numero de documento",
          },
          {
            dataIndex: "FechaTerminoEmbarazo",
            title: "Fecha de termino de embarazo",
            render: (f) => format(addHours(new Date(f), 5), "dd / MM / yyyy"),
          },
          //   "idTipoTerminoEmbarazo",
          {
            title: "Tipo de termino de embarazo",
            dataIndex: "Descripcion",
          },
          "Observacion",
        ])}
      />
    </Card>
  );
};

export default TermEmb;
