import { AUTH_ROUTE_PREFFIX } from "api/constants";
import { generateCrudApi, generateDropdown } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";

export interface Module {
  idModulo?: number;
  nombre: string;
  icono?: string;
  estado?: number;
  link?: string;
  idSuperModulo?: number;
}

export const ModuleAPI = generateCrudApi(AUTH_ROUTE_PREFFIX + "module");

export const ModuleForm: IInputPayload[] = generateMapForm([
  {
    name: "nombre",
    type: "input",
    customProps: {
      spanCol: "span 2",
      displayName: "Nombre",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "icono",
    type: "input",
    customProps: {
      spanCol: "span 2",
      displayName: "Icono (remix-icon)",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "estado",
    type: "radio-button",
    optionsDropdown: [
      { name: "Activo", value: 1 },
      { name: "Inactivo", value: 0 },
    ],
    optionLabel: "name",
    optionValue: "value",
  },
  {
    name: "link",
    type: "input",
    customProps: {
      spanCol: "span 2",
    },
  },
  {
    name: "idSuperModulo",
    type: "input",
    customProps: {
      spanCol: "span 2",
    },
  },
]);

export const ModuleDropdownDefinition: IInputPayload = generateDropdown({
  name: "nombre",
  idName: "idModulo",
  description: "Modulo",
  list: ModuleAPI.list,
});
