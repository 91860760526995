import { useLogged } from "core/hooks/loggedContext";
import useAxios from "core/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { DashboardMatrix2API } from "api/models/Pregnat/api";
import DashboardMatrixBase from "../DashboardMatrixBase";

const DashboardMatrix3 = ({ anio }) => {
  const { metadata } = useLogged();
  const DashboardMatrix = useAxios(DashboardMatrix2API);
  const [dropdownValues, setDropdownValues] = useState({});
  const [rank, setRank] = useState("");
  const getData = async () => {
    const data = await DashboardMatrix.startRequest({
      anio,
    });
    if (data && data.data && data.data.length) {
      setRank(0);
      setDropdownValues({
        [data.jerarquia[0]]: [data.data[0]],
      });
    }
  };

  const getDataEESS = async () => {
    console.log("getDataEESS");
    const data = await DashboardMatrix.startRequest({
      anio,
    });
    if (data && data.data && data.data.length) {
      setRank(data.jerarquia.length - 1);
      // generate dropdown values for each rank

      let _dropdownValues = {
        [data.jerarquia[0]]: [data.data[0]],
      };

      for (let i = 1; i < data.jerarquia.length; i++) {
        const rank = _dropdownValues[data.jerarquia[i - 1]];
        _dropdownValues = {
          ..._dropdownValues,
          [data.jerarquia[i]]: rank[0].data,
        };
      }

      console.log("dropdownValues", _dropdownValues);

      setDropdownValues(_dropdownValues);
    }
  };

  useEffect(() => {
    if (metadata.esDiresa === 0 && metadata.subregion === "0") {
      getDataEESS();
    } else {
      getData();
    }
    return () => {};
    // eslint-disable-next-line
  }, [metadata, anio]);

  return (
    <DashboardMatrixBase
      name="Gestantes con anemia"
      data={DashboardMatrix.data?.data}
      rank={rank}
      setRank={setRank}
      ranks={DashboardMatrix.data?.jerarquia}
      dropdownValues={dropdownValues}
      setDropdownValues={setDropdownValues}
      cardConfig={[
        {
          name: "TOTAL",
          color: "#48B9FF",
          prop: "cantTotal",
        },
        {
          name: "ANEMIAG",
          color: "#F875AA",
          prop: "cantGestATD",
        },
      ]}
    />
  );
};

export default DashboardMatrix3;
