import { Button } from "antd";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Row } from "core/util-styled-components";
import React, { useState, useEffect } from "react";
import Search from "./Search";
import MasiveUpload from "./MasiveUpload";

const ActionBar = ({
  onAdd,
  searchValue,
  setSearchValue,
  masiveUpload,
  apiConfig,
}) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [buttonNuevoVisible, setButtonNuevoVisible] = useState(true);
  const { isTabletOrMobile } = useMediaQueryCustom();

  useEffect(() => {
    if (!isTabletOrMobile) {
      setButtonNuevoVisible(true);
    } else {
      setButtonNuevoVisible(!searchVisible);
    }

    return () => {};
  }, [searchVisible, isTabletOrMobile]);

  return (
    <Row gap="10px">
      {!searchVisible && (
        <Button
          type="link"
          shape="round"
          onClick={() => setSearchVisible(true)}
          icon={<i className="ri-search-line" />}
        >
          {!isTabletOrMobile && <span>Buscar</span>}
        </Button>
      )}
      {searchVisible && (
        <Search
          value={searchValue}
          onChange={setSearchValue}
          handleVisibility={setSearchVisible}
        />
      )}
      {buttonNuevoVisible && (
        <Button
          type="primary"
          shape="round"
          onClick={onAdd}
          icon={<i className="ri-heart-add-line" />}
        >
          <strong>Agregar registro</strong>
        </Button>
      )}
      {masiveUpload && <MasiveUpload apiConfig={apiConfig} />}
    </Row>
  );
};

export default ActionBar;
