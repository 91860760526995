import { BaseCutsForm, BaseCutsAPI } from ".";
import { BaseCutsTable } from "./table";

export const BaseCutsConfig = {
  id: 13,
  name: "Corte de bases",
  apiConfig: BaseCutsAPI,
  columns: BaseCutsTable,
  form: BaseCutsForm,
};
