import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import CRUDPage from "..";

const RelationModal = ({ data, relationShip }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <Tooltip title="Relacion">
        <Button
          type="link"
          onClick={() => {
            setVisible(true);
          }}
          icon={
            <i style={{ color: "black" }} className="ri-git-branch-line"></i>
          }
        ></Button>
      </Tooltip>
      <Modal
        title="Relación"
        centered
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        style={{
          minWidth: "80%",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        {relationShip.map((el) => (
          <CRUDPage
            title={`${el?.tableRelation?.name}`}
            apiConfig={el?.tableRelation?.apiConfig}
            rows={el?.tableRelation?.columns}
            form={el?.tableRelation?.form}
            useDefaultActions
            height="auto"
            useRelationShipGetId
            relationShipIdGet={el?.relNameId}
            relationShipIdValueGet={data[el?.relNameId]}
          />
        ))}
      </Modal>
    </div>
  );
};

export default RelationModal;
