export interface DiccionarioItem {
  item: number;
  anteriorDescripcion: string;
  nuevaDenominacion: string;
  size?: number;
}

export const PregnatDataDictionary: DiccionarioItem[] = [
  { item: 1, anteriorDescripcion: "GEST_ATD_1ER", nuevaDenominacion: "GEST 1º TRIM", size: 150 },
  { item: 2, anteriorDescripcion: "Hem", nuevaDenominacion: "Primero Hemoglobina", size: 180 },
  { item: 4, anteriorDescripcion: "Vih", nuevaDenominacion: "VIH", size: 120 },
  { item: 5, anteriorDescripcion: "Sif", nuevaDenominacion: "Sifilis", size: 150 },
  { item: 6, anteriorDescripcion: "Bact", nuevaDenominacion: "Bacteriuria", size: 180 },
  { item: 7, anteriorDescripcion: "1RA BATERIA_OPORT", nuevaDenominacion: "BATERIA 1", size: 150 },
  { item: 8, anteriorDescripcion: "ADOLES_1ER_Ctrl", nuevaDenominacion: "GEST ADOLEC", size: 150 },
  { item: 9, anteriorDescripcion: "GEST_ATD", nuevaDenominacion: "GEST ATD", size: 150 },
  { item: 10, anteriorDescripcion: "GEST_2do_Ctrl", nuevaDenominacion: "2º APN", size: 120 },
  { item: 11, anteriorDescripcion: "GEST_3er_Ctrl", nuevaDenominacion: "3º APN", size: 120 },
  { item: 12, anteriorDescripcion: "GEST_4to_Ctrl", nuevaDenominacion: "4º APN", size: 120 },
  { item: 13, anteriorDescripcion: "GEST_5to_Ctrl", nuevaDenominacion: "5º APN", size: 120 },
  { item: 14, anteriorDescripcion: "GEST_6to_Ctrl", nuevaDenominacion: "6º APN", size: 120 },
  { item: 15, anteriorDescripcion: "GEST_7mo_Ctrl", nuevaDenominacion: "7º APN", size: 120 },
  { item: 16, anteriorDescripcion: "GEST_8vo_Ctrl", nuevaDenominacion: "8º APN", size: 120 },
  { item: 17, anteriorDescripcion: "GEST_9no_Ctrl", nuevaDenominacion: "9º APN", size: 120 },
  { item: 18, anteriorDescripcion: "GEST_10mo_Ctrl", nuevaDenominacion: "10º APN", size: 120 },
  { item: 19, anteriorDescripcion: "GEST_11vo_Ctrl", nuevaDenominacion: "11º APN", size: 120 },
  { item: 20, anteriorDescripcion: "GEST_12vo_Ctrl", nuevaDenominacion: "12º APN", size: 120 },
  { item: 21, anteriorDescripcion: "2DA BATERIA_OPORT", nuevaDenominacion: "BATERIA 2", size: 150 },
  { item: 22, anteriorDescripcion: "G_SF1", nuevaDenominacion: "SF1", size: 120 },
  { item: 23, anteriorDescripcion: "G_SF2", nuevaDenominacion: "SF2", size: 120 },
  { item: 24, anteriorDescripcion: "G_SF3", nuevaDenominacion: "SF3", size: 120 },
  { item: 25, anteriorDescripcion: "G_SF4", nuevaDenominacion: "SF4", size: 120 },
  { item: 26, anteriorDescripcion: "G_SF5", nuevaDenominacion: "SF5", size: 120 },
  { item: 27, anteriorDescripcion: "G_SF6", nuevaDenominacion: "SF6", size: 120 },
  { item: 28, anteriorDescripcion: "G_SF7", nuevaDenominacion: "SF7", size: 120 },
  { item: 29, anteriorDescripcion: "G_SF8", nuevaDenominacion: "SF8", size: 120 },
  { item: 30, anteriorDescripcion: "G_SF9", nuevaDenominacion: "SF9", size: 120 },
  { item: 31, anteriorDescripcion: "VIOLENCIA_G", nuevaDenominacion: "VIOLENCIA", size: 150 },
  { item: 32, anteriorDescripcion: "P_Parto1", nuevaDenominacion: "P_Parto1", size: 150 },
  { item: 33, anteriorDescripcion: "P_Parto2", nuevaDenominacion: "P_Parto2", size: 150 },
  { item: 34, anteriorDescripcion: "P_Parto3", nuevaDenominacion: "P_Parto3", size: 150 },
  { item: 35, anteriorDescripcion: "P_PartoEfec", nuevaDenominacion: "P_PartoEfec", size: 150 },
  { item: 36, anteriorDescripcion: "ANEMIAG", nuevaDenominacion: "ANEMIA", size: 150 },
  { item: 37, anteriorDescripcion: "ANEMIAG_PR", nuevaDenominacion: "ANEMIA PR", size: 150 },
  { item: 38, anteriorDescripcion: "TTO_SF1", nuevaDenominacion: "TTO_SF1", size: 150 },
  { item: 39, anteriorDescripcion: "TTO_SF2", nuevaDenominacion: "TTO_SF2", size: 150 },
  { item: 40, anteriorDescripcion: "TTO_SF3", nuevaDenominacion: "TTO_SF3", size: 150 },
  { item: 41, anteriorDescripcion: "TTO_SF4", nuevaDenominacion: "TTO_SF4", size: 150 },
  { item: 42, anteriorDescripcion: "TTO_SF5", nuevaDenominacion: "TTO_SF5", size: 150 },
  { item: 43, anteriorDescripcion: "TTO_SF6", nuevaDenominacion: "TTO_SF6", size: 150 },
  { item: 44, anteriorDescripcion: "TTO_SF7", nuevaDenominacion: "TTO_SF7", size: 150 },
  { item: 45, anteriorDescripcion: "TTO_SF8", nuevaDenominacion: "TTO_SF8", size: 150 },
  { item: 46, anteriorDescripcion: "TTO_SF9", nuevaDenominacion: "TTO_SF9", size: 150 },
  { item: 47, anteriorDescripcion: "ITU_1", nuevaDenominacion: "ITU_1", size: 150 },
  { item: 48, anteriorDescripcion: "ITU_2", nuevaDenominacion: "ITU_2", size: 150 },
  { item: 49, anteriorDescripcion: "ITU_3", nuevaDenominacion: "ITU_3", size: 150 },
  // { item: 50, anteriorDescripcion: "Eclampsia", nuevaDenominacion: "Eclampsia", size: 180 },
  // { item: 51, anteriorDescripcion: "Preeclampsia", nuevaDenominacion: "Preeclampsia", size: 180 },
  // { item: 52, anteriorDescripcion: "S. Hellp", nuevaDenominacion: "S. Hellp", size: 180 },
];
