import { generateRelationCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";
import { PersonDropdownDefinition } from "../Person";
import { EstablishmentDropdownDefinition } from "../Establishment";

export interface PersonEstablishment {
  idPersona: number;
  idEstablecimiento: number;
}

export const PersonEstablishmentAPI = generateRelationCrudApi(
  "person-establishment"
);

export const PersonEstablishmentForm: IInputPayload[] = generateMapForm([
  PersonDropdownDefinition,
  EstablishmentDropdownDefinition,
]);
