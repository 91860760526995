import {
  EndPointConfig,
  ModuleConfig,
  RoleConfig,
  RouteConfig,
  UserConfig,
} from "api/models/ConfigAPI";

export const Tablas = [
  UserConfig,
  EndPointConfig,
  ModuleConfig,
  RoleConfig,
  RouteConfig,
];
