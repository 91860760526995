import { NotificationForm, NotificationAPI } from ".";
import { NotificationTable } from "./table";

export const NotificationConfig = {
  id: 13,
  name: "Notificaciones",
  apiConfig: NotificationAPI,
  columns: NotificationTable,
  form: NotificationForm,
};
