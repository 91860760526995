import { NotificationConfig } from "api/models/Notifications/config";
import CRUDPage from "core/components/organism/CRUDPage";
import React from "react";

const Notifications = () => {
  return (
    <CRUDPage
      title={`Lista de ${NotificationConfig?.name}`}
      apiConfig={NotificationConfig?.apiConfig}
      rows={NotificationConfig?.columns}
      form={NotificationConfig?.form}
      useDefaultActions
      relationShip={NotificationConfig?.relationShip}
    />
  );
};

export default Notifications;
