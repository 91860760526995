import { generateMapTable } from "core/utils/tables";

export const NotificationTable = generateMapTable([
  "idNotificacion",
  "titulo",
  "contenido",
  "fechaCreacion",
  "fechaLimite",
  "idUsuario",
  "estado",
]);
