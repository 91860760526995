import { BaseCutsAPI } from "api/models/BaseCuts";
import useAxios from "core/hooks/useAxios";
import { Row, Typography } from "core/util-styled-components";
import { useEffect, useState } from "react";

export const FooterCard = ({ type = "PRINCIPAL", fuente = null }) => {
  const list = useAxios(BaseCutsAPI.list);

  const [fontSelected, setFontSelected] = useState("");
  useEffect(() => {
    const response = list.data.filter((el) => el.tipo === type && el.Estado === 1);
    if (response.length) {
      setFontSelected(response[0].fuente);
    }

    return () => {};
    // eslint-disable-next-line
  }, [list]);

  return (
    <Row>
      <Typography>
        Fuente: <b>{fuente || fontSelected}</b>
      </Typography>
    </Row>
  );
};
