import { Button, Modal, Table, message } from "antd";
import { FichaGestacionalAPI } from "api/models/FichaGestacional";
import { InformePDF } from "core/exports/generate_pdf";
import useAxios from "core/hooks/useAxios";
import { Column, Row, Typography } from "core/util-styled-components";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import React from "react";

const FactorRiesgoTag = ({ ubicacion, color, factorRiesgo, onClick = () => {}, fo, uo }) => {
  return (
    <Row justifyContent="space-between" gap="10px">
      <Typography variant="label" onClick={onClick}>
        <Row gap="10px">
          {fo && (
            <Typography style={{ fontWeight: "600" }}>
              <Row gap="5px">
                {color && <div>{getColor(color)}</div>}
                {factorRiesgo}
              </Row>
            </Typography>
          )}
          {uo && ubicacion}
        </Row>
      </Typography>
    </Row>
  );
};

const getColor = (color) => {
  return color === "AMARILLO" ? "🟡" : color === "ROJO" ? "🔴" : color === "VERDE" ? "🟢" : "⚪";
};
const MigracionRecibidos = () => {
  const ListMigracionesRecibidos = useAxios(FichaGestacionalAPI.listRecibidos);
  const EliminarMigracion = useAxios(FichaGestacionalAPI.delete);

  const eliminarMigracionCtrl = async (data) => {
    Modal.confirm({
      title: "Eliminar",
      content: "¿Desea eliminar este registro?",
      onOk: async () => {
        const response = await EliminarMigracion.startRequest(data);
        if (response.check) {
          message.success("Se ha modificado una migración");
          ListMigracionesRecibidos.startRequest();
        } else {
          message.error("No se ha modificado ninguna migración");
        }
      },
    });
  };

  const descargarPDF = (row) => {
    InformePDF({ informe: row });
  };

  return (
    <div>
      <Table
        dataSource={ListMigracionesRecibidos.data}
        expandable={{
          columnWidth: 48,
          expandedRowRender: (record) => (
            <Column>
              <Typography style={{ margin: 0 }}>
                <b>Origen:</b> {record.NombreIpressOrigen}
              </Typography>
              <Typography style={{ margin: 0 }}>
                <b>Destino:</b> {record.NombreIpressAcudira} ({record.regionMigra.replaceAll("|", " ➡️ ")})
              </Typography>
              <Typography>
                <b>Motivo:</b> {record.motivoCambioDomicilio}
              </Typography>
            </Column>
          ),
          rowExpandable: (record) => record,
        }}
        columns={generateMapTable([
          {
            dataIndex: "nroDocumento",
            title: "Nro de Documento",
          },
          {
            dataIndex: "nombres",
            title: "Apellidos y Nombres",
          },
          {
            dataIndex: "edadRegistro",
            title: "Edad",
          },
          {
            title: "Fecha de ficha",
            dataIndex: "fecha",
            render: (f) => format(addHours(new Date(f), 5), "dd/MM/yyyy"),
          },
          {
            title: "Factores de riesgo",
            dataIndex: "factores",
            render: (factores) => (
              <Column alignItems="flex-start" style={{ maxHeight: "60px", overflow: "auto" }}>
                {factores.map((item, index) => {
                  const { FactorRiesgo, color, UBICACION } = item;
                  return <FactorRiesgoTag key={index} ubicacion={UBICACION?.split("|")[UBICACION.split("|").length - 1]} color={color} factorRiesgo={FactorRiesgo} fo={true} uo={false} />;
                })}
              </Column>
            ),
          },
          {
            render: (row) => (
              <Row gap="5px">
                <Button
                  type="primary"
                  style={{ backgroundColor: "#4A64F4" }}
                  shape="circle"
                  onClick={() => {
                    descargarPDF(row);
                  }}
                >
                  <i className="ri-printer-line" />
                </Button>
                <Button type="primary" danger shape="circle" onClick={() => eliminarMigracionCtrl(row)}>
                  <i className="ri-delete-bin-line" />
                </Button>
              </Row>
            ),
          },
        ])}
      />
    </div>
  );
};

export default MigracionRecibidos;
