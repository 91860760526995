import { AUTH_ROUTE_PREFFIX } from "api/constants";
import { generateCrudApi, generateDropdown } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";

export interface Route {
  idRuta?: number;
  ruta?: string;
  path?: string;
  estado?: number;
}

export const RouteAPI = generateCrudApi(AUTH_ROUTE_PREFFIX + "route");

export const RouteForm: IInputPayload[] = generateMapForm([
  {
    name: "ruta",
    type: "input",
    customProps: {
      spanCol: "span 2",
      displayName: "Ruta",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "path",
    type: "input",
    customProps: {
      spanCol: "span 2",
      displayName: "Path de importación (frontend)",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "estado",
    type: "radio-button",
    optionsDropdown: [
      { name: "Activo", value: 1 },
      { name: "Inactivo", value: 0 },
    ],
    optionLabel: "name",
    optionValue: "value",
  },
]);

export const RouteDropdownDefinition: IInputPayload = generateDropdown({
  name: "ruta",
  idName: "idRuta",
  description: "Ruta",
  list: RouteAPI.list,
});
