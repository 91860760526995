const useLocalStorage = (name) => {
  const setContent = (content) => {
    localStorage.setItem(name, JSON.stringify(content));
  };
  const getContent = () => {
    try {
      return JSON.parse(localStorage.getItem(name));
    } catch (error) {
      return null;
    }
  };
  return [setContent, getContent];
};
export default useLocalStorage;
