import { Input } from "antd";
import React, { useState, useEffect } from "react";

export const convertTo24HourFormat = (time12Hour) => {
  const match = time12Hour.match(/(\d{1,2}):(\d{2})\s(AM|PM)/i);

  if (!match) {
    // La entrada no coincide con el formato esperado
    return null;
  }

  let [, hours, minutes, period] = match;

  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);

  if (period.toUpperCase() === "PM" && hours !== 12) {
    hours += 12;
  } else if (period.toUpperCase() === "AM" && hours === 12) {
    hours = 0;
  }

  const formattedHour = hours.toString().padStart(2, "0");
  const formattedMinute = minutes.toString().padStart(2, "0");

  return `${formattedHour}:${formattedMinute}`;
};

export const convertTo12HourFormat = (time24Hour) => {
  console.log(time24Hour);
  // Dividir la cadena de tiempo en horas y minutos
  if (!time24Hour?.split(":").length) {
    return null;
  }
  const [hourStr, minuteStr] = time24Hour.split(":");
  const hour = parseInt(hourStr, 10);
  // const minute = parseInt(minuteStr, 10);

  // Determinar si es AM o PM
  const period = hour < 12 ? "AM" : "PM";

  // Convertir la hora a formato de 12 horas
  let hour12 = hour % 12;
  if (hour12 === 0) {
    hour12 = 12; // 0 horas en formato de 12 horas es 12 AM
  }

  // Crear la cadena en formato de 12 horas
  const time12Hour = `${hour12}:${minuteStr} ${period}`;

  return time12Hour;
};

// Ejemplo de uso:
const time24Hour = "15:30"; // Cambia esto por la hora que desees convertir
const time12Hour = convertTo12HourFormat(time24Hour);
console.log(time12Hour); // Esto imprimirá la hora en formato de 12 horas

const TimeSelector = ({ value, onChange, defaultValue = "9:00 am" }) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    // Validar si la entrada coincide con el formato hh:mm AM/PM
    const regex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
    const isValidFormat = regex.test(newValue);

    setIsValid(isValidFormat);
    setInputValue(newValue);
  };

  useEffect(() => {
    onChange(inputValue);

    return () => {};
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <div>
      <Input
        prefix={<i className="ri-time-line"></i>}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        suffix={
          <i
            className={isValid ? "ri-check-line" : "ri-close-line"}
            style={{ color: isValid ? "green" : "red" }}
          />
        }
      />
    </div>
  );
};

export default TimeSelector;
