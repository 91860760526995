import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { DropdownStyled } from "core/ts_formulario_model/src/App/Components/Molecules/FormControl/styled";
import { Row, Typography } from "core/util-styled-components";
import React from "react";

const FilterRank = ({ rank, ranks, options, values, setValues }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <Row
      gap="10px"
      style={{
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: isTabletOrMobile ? "20px" : 0,
        flexWrap: isTabletOrMobile ? "wrap" : "nowrap",
      }}
      justifyContent={isTabletOrMobile ? null : "center"}
      margin="0 0 20px 0"

      // responsiveReorder
    >
      {!isTabletOrMobile && <Typography>Filtrar por:</Typography>}
      {ranks?.map((el, id) => {
        if (id === 0) {
          return (
            <DropdownStyled
              style={{
                width: "100%",
              }}
              values={values[el]}
              onChange={(value) =>
                setValues({
                  ...values,
                  [el]: value,
                })
              }
              disabled={rank <= id}
              options={options}
              labelField={el}
              valueField={el}
              searchBy={el}
            />
          );
        }

        if (id === ranks.length - 1) {
          return null;
        }

        return (
          <DropdownStyled
            style={{
              width: "100%",
            }}
            values={values[el]}
            onChange={(value) =>
              setValues({
                ...values,
                [el]: value,
              })
            }
            options={values[ranks[id - 1]]?.length ? values[ranks[id - 1]][0].data : []}
            disabled={rank <= id}
            labelField={el}
            valueField={el}
            searchBy={el}
          />
        );
      })}
    </Row>
  );
};

export default FilterRank;
