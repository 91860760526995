import { Modal } from "antd";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Formulario } from "core/ts_formulario_model";
import { addDefaultValues } from "core/utils/form";
import React from "react";

const EditRegistersForm = ({ open, onCancel, design, dataSelected, form, onSubmit, cols = 1 }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();

  const onSubmitForm = (data) => {
    if (Boolean(dataSelected)) {
      onSubmit("edit", { ...dataSelected, ...data });
    } else {
      onSubmit("add", data);
    }
  };

  return (
    <Modal
      open={open}
      title={!design.removeTitle && `${Boolean(dataSelected) ? "Editar" : "Crear"} ${design.title || "registro"}`}
      onHide={onCancel}
      bodyStyle={{
        padding: design.modalPadding || 5,
        width: design.bodyWidth || "92.5%",
      }}
      style={{
        width: design.mbSize || isTabletOrMobile ? "100%" : "70%",
      }}
      centered
      width={design.modalWidth}
      footer={null}
      destroyOnClose
      closable={false}
    >
      <Formulario onSubmit={onSubmitForm} style={design.formStyleContent}>
        <Formulario.FormControls
          cols={design.formCols || cols}
          controlGap={design.controlGaps || "15px 15px"}
          gap="0"
          buttonSubmitLargerAndOnly
          submitLabel={"Guardar"}
          onCancel={onCancel}
          inputs={addDefaultValues(form, dataSelected)}
        />
      </Formulario>
    </Modal>
  );
};

export default EditRegistersForm;
