import React from "react";
import { Card, Modal, Table, message } from "antd";
import { useState, useEffect } from "react";
import { generateMapTable } from "core/utils/tables";
import useAxios from "core/hooks/useAxios";
import { getValueDropdown } from "core/utils/form";
import EditRegistersForm from "./components/EditRegistersForm";
import ActionRender from "./components/ActionRender";
import ActionBar from "./components/ActionBar";

const defaultForm = [{ name: "Descripcion", type: "input" }];

const _defaultActions = [
  {
    key: "edit",
    label: "Modificar",
    icon: "ri-edit-line",
    type: "link",
  },
  {
    key: "delete",
    label: "Eliminar",
    icon: "ri-delete-bin-line",
    type: "link",
    danger: true,
  },
];

function CRUDPage({
  form = defaultForm,
  title = "Listado de registros",
  rows = ["Descriptions"],
  actions = _defaultActions,
  useNotDefaultActions = false,
  useDefaultActions = false,
  cols = 1,
  readOnly = false,
  customDesignProps = {},
  searchFields = ["Descripcion"],
  apiConfig = {},
  relationShip = null,
  height = "86vh",
  useRelationShipGetId = false,
  relationShipIdGet = "idRol",
  relationShipIdValueGet = 1,
  masiveUpload = false,
  onActionNotDefault = (action, payload) => {},
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [registroSeleccionado, setRegistroSeleccionado] = useState();
  const [searchField, setSearchField] = useState("");
  const List = useAxios();
  const Update = useAxios();
  const Create = useAxios();
  const Delete = useAxios();

  useEffect(() => {
    List.updateApiConfig(apiConfig.list);
    // eslint-disable-next-line
  }, [apiConfig.list]);

  useEffect(() => {
    Create.updateApiConfig(apiConfig.add);
    // eslint-disable-next-line
  }, [apiConfig.add]);
  useEffect(() => {
    Delete.updateApiConfig(apiConfig.delete);
    // eslint-disable-next-line
  }, [apiConfig.delete]);
  useEffect(() => {
    Update.updateApiConfig(apiConfig.update);
    // eslint-disable-next-line
  }, [apiConfig.update]);

  const validateRelationShip = () => List.apiConfig?.method === "POST" && useRelationShipGetId && relationShipIdValueGet && relationShipIdGet;

  const reloadListRelationShip = () => {
    List.startRequest({
      [relationShipIdGet]: relationShipIdValueGet,
    });
  };

  useEffect(() => {
    if (validateRelationShip()) {
      reloadListRelationShip();
    }

    return () => {};
    // eslint-disable-next-line
  }, [List.apiConfig]);

  const onAction = async (action, payload) => {
    const actions = {
      list: List,
      add: Create,
      addMessage: "añadido",
      delete: Delete,
      deleteMessage: "eliminado",
      edit: Update,
      editMessage: "actualizado",
    };

    if (useNotDefaultActions) {
      return onActionNotDefault(action, payload, actions);
    }

    if (useDefaultActions) {
      const dropdowns = getValueDropdown(form, payload);

      const response = await actions[action].startRequest({
        ...payload,
        ...dropdowns,
      });
      if (response?.check) {
        message.success(`Se ha ${actions[action + "Message"]} un registro`);
        if (validateRelationShip()) {
          reloadListRelationShip();
        } else {
          List.startRequest();
        }
      } else {
        message.error(`No se ha ${actions[action + "Message"]} un registro`);
      }
    }
  };

  const defaultActions = (action, payload) => {
    switch (action) {
      case "edit":
        setRegistroSeleccionado(payload);
        setModalVisible(true);
        break;
      case "delete":
        Modal.confirm({
          title: "Eliminar registro",
          content: `¿Está seguro que desea eliminar el registro "${payload.Descripcion ? payload.Descripcion : ""}"?`,
          onOk: () => {
            onAction("delete", payload);
          },
        });
        break;
      case "add":
        setRegistroSeleccionado(null);
        setModalVisible(true);
        break;
      default:
        break;
    }
  };

  const onActionClick = (action, row) => {
    if (useDefaultActions) {
      if (["edit", "delete", "add"].includes(action.key)) {
        return defaultActions(action.key, row);
      }
      return onAction(action.key, row);
    }
    onAction(action.key, row);
  };

  const filter = (el) => {
    if (searchField) {
      return searchFields.reduce((acc, currentVal) => acc || el[currentVal]?.toLowerCase().includes(searchField.toLowerCase()), false);
    }
    return true;
  };

  return (
    <Card
      title={title}
      extra={
        !readOnly && (
          <ActionBar
            onAdd={() => {
              if (!useDefaultActions) {
                return onAction("add", null);
              }
              defaultActions("add", null);
            }}
            masiveUpload={masiveUpload}
            searchValue={searchField}
            setSearchValue={setSearchField}
            apiConfig={apiConfig}
          />
        )
      }
      style={{
        border: "none",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        minHeight: height,
        maxHeight: height,
        overflow: "auto",
      }}
      bodyStyle={{
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      <Table
        size="small"
        loading={List.loading}
        dataSource={Array.isArray(List?.data) ? List?.data?.filter(filter) : []}
        columns={generateMapTable([
          ...rows,
          {
            className: "no-padding",
            render: (_, row) => <ActionRender readOnly={readOnly} relationShip={relationShip} actions={actions} data={row} onActionClick={onActionClick} />,
          },
        ])}
      />

      <EditRegistersForm
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setRegistroSeleccionado(null);
        }}
        design={customDesignProps}
        onSubmit={(action, data) => {
          onAction(action, data);
          setModalVisible(false);
        }}
        dataSelected={registroSeleccionado}
        form={form}
        cols={cols}
      />
    </Card>
  );
}

export default CRUDPage;
