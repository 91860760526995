import { AUTH_ROUTE_PREFFIX } from "api/constants";
import { generateCrudApi, generateDropdown } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";

export interface Role {
  idRol?: number;
  nombre: string;
  tokenAccess: string;
  fechaRegistro: Date;
  estado: number;
}

export const RoleAPI = generateCrudApi(AUTH_ROUTE_PREFFIX + "role");

export const RoleForm: IInputPayload[] = generateMapForm([
  {
    name: "nombre",
    type: "input",
    customProps: {
      displayName: "Nombre",
      spanCol: "span 2",
    },
  },
  {
    name: "estado",
    type: "radio-button",
    optionsDropdown: [
      { name: "Activo", value: 1 },
      { name: "Inactivo", value: 0 },
    ],
    optionLabel: "name",
    optionValue: "value",
  },
]);

export const RoleDropdownDefinition: IInputPayload = generateDropdown({
  name: "nombre",
  idName: "idRol",
  description: "Rol",
  list: RoleAPI.list,
});
