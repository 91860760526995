import { generateCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";

export interface Notification {
  idNotificacion: number;
  titulo: string;
  contenido: string;
  fechaCreacion: Date;
  fechaLimite: Date;
  idUsuario: number;
  estado: number;
}

export const NotificationAPI = generateCrudApi("notification");

export const NotificationsByUser = {
  method: "POST",
  url: `/api/v1/notification/list-by-user`,
  executeAtStart: true,
};
export const NotificationsActives = {
  method: "GET",
  url: `/api/v1/notification/list-active`,
  executeAtStart: true,
};

export const NotificationListPregnatData = {
  method: "POST",
  url: `/api/v1/notification/pregnat-data/list`,
  executeAtStart: true,
};

export const NotificationForm: IInputPayload[] = generateMapForm([
  "titulo",
  "contenido",
  {
    name: "fechaCreacion",
    type: "input",
    required: true,
    customProps: {
      required: true,
      type: "date",
    },
  },
  {
    name: "fechaLimite",
    type: "input",
    required: true,
    customProps: {
      required: true,
      type: "date",
    },
  },
  "idUsuario",
  "estado",
]);
