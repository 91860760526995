import { PersonEstablishmentForm, PersonEstablishmentAPI } from ".";
import { PersonEstablishmentTable } from "./table";

export const PersonEstablishmentConfig = {
  id: 8,
  name: "PersonEstablishment",
  apiConfig: PersonEstablishmentAPI,
  columns: PersonEstablishmentTable,
  form: PersonEstablishmentForm,
};
