import { Alert, message } from "antd";
import { LoginAPIConfig } from "api/models/User";
import Logo from "core/components/atoms/Logo";
import Container from "core/components/organism/Container";
import { useLogged } from "core/hooks/loggedContext";
import useAxios from "core/hooks/useAxios";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Formulario } from "core/ts_formulario_model";
import { Column, Div, Row, Typography } from "core/util-styled-components";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const LoginContent = styled(Column)`
  background-color: white;
  border-radius: 2em 2em 2em 2em;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  margin: 2em auto;
`;

const MediaHolder = styled.div`
  width: 30%; /* Ajusta el ancho según lo que necesites */
  transform: scale(1.05);
  border-radius: 0 1em 1em 0;
  background-color: blue;
  clip-path: polygon(13% 0, 100% 0%, 100% 100%, 0 100%);
  // clip-path: path(
  //   "M7.20092 375.299L51.8888 123.078C58.2276 87.3015 62.2486 52.6275 93.2145 33.497C114.513 20.3391 146.703 15.4717 171.707 13.9028C279.997 7.10811 377.373 4.61804 515.929 1.0749L526.874 0.794942C560.344 -0.0615993 595.078 0.234001 626.035 12.956C675.004 33.0809 681.038 96.2185 683.376 148.994C687.915 251.411 691.748 331.658 691.217 406.371C690.369 423.924 687.672 436.993 681.54 447.476C661.622 481.524 613.782 483.345 574.629 488.718C394.89 513.384 275.163 529.171 176.374 537.112C128.43 540.967 76.1961 543.319 36.7577 515.855C20.8598 504.784 10.3768 490.218 4.54529 469.666C-4.11278 439.153 1.62887 406.747 7.16279 375.514L7.20092 375.299Z"
  // );
  overflow: hidden; /* Para ocultar partes de la imagen que estén fuera del recorte */
`;

const Login = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const { login, isLogged } = useLogged();
  const nav = useNavigate();

  const LoginPaciente = useAxios(LoginAPIConfig);

  const onLoginForm = async (data) => {
    const response = await LoginPaciente.startRequest(data);
    if (response.error) {
      message.error(response.message);
    } else {
      message.success(response.message);
      login({ SSID: response.token, username: data.username });
    }
  };

  useEffect(() => {
    if (isLogged) {
      nav("/");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);
  return (
    <Container style={{ padding: 0 }}>
      <Row
        style={{
          height: "100vh",
          maxHeight: "100vh",
        }}
      >
        <LoginContent
          center={isTabletOrMobile}
          width={isTabletOrMobile ? "90%" : "80%"}
        >
          <Row>
            <Column
              alignSelf="flex-start"
              style={{
                width: isTabletOrMobile ? "100%" : "70%",
                padding: "2em",
              }}
            >
              <Logo
                contentWidth={isTabletOrMobile ? "auto" : "35em"}
                width={isTabletOrMobile ? null : "7em"}
                letters={true}
                variant={isTabletOrMobile ? "span" : "h2"}
                text={"Bienvenido a SEGESPPIURA"}
                subtextVariant={isTabletOrMobile ? "label" : "h3"}
              />
              <Formulario onSubmit={onLoginForm}>
                <div style={{ margin: "0 0 25px 0" }}>
                  <Alert
                    type="info"
                    message="Asegurese de tener un usuario y contraseña."
                  ></Alert>
                </div>
                <Formulario.FormControls
                  cols={1}
                  inputs={[
                    {
                      name: "username",
                      type: "input",
                      required: true,
                      customProps: {
                        required: true,
                        prefix: <i className="ri-user-line" />,
                        displayName: "Usuario",
                      },
                    },
                    {
                      name: "password",
                      type: "input",
                      required: true,
                      customProps: {
                        required: true,
                        type: "password",
                        prefix: <i className="ri-key-line" />,
                        displayName: "Contraseña",
                      },
                    },
                  ]}
                  submitLabel={
                    <Typography color="inherit">
                      <i className="ri-lock-line" /> Iniciar sesión
                    </Typography>
                  }
                  buttonProps={{
                    cancel: {
                      style: { display: "none" },
                    },
                    submit: {
                      size: "large",
                      style: { width: "100%" },
                    },
                  }}
                ></Formulario.FormControls>
              </Formulario>
            </Column>
            {!isTabletOrMobile && (
              <MediaHolder>
                <img
                  src="Splash2.jpg"
                  alt="Mujer embaraza - Unsplash.com"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    padding: 0,
                    transform: "scale(1.1)",
                  }}
                />
              </MediaHolder>
            )}
          </Row>
        </LoginContent>
      </Row>
    </Container>
  );
};

export default Login;
