import { generateCrudApi } from "api/generador";

export const HomePregnatReportAPI = {
  method: "POST",
  url: "/api/v1/pregnat/home/general-report/",
  executeAtStart: false,
};
export const HomePregnatReport2API = {
  method: "POST",
  url: "/api/v1/pregnat/home/general-report-2/",
  executeAtStart: false,
};
export const HomePregnatReport4API = {
  method: "POST",
  url: "/api/v1/pregnat/home/general-report-4/",
  executeAtStart: false,
};
export const DashboardMatrixAPI = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/matrix-1/",
  executeAtStart: false,
};
export const DashboardMatrix5API = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/matrix-5/",
  executeAtStart: false,
};
export const DashboardMatrix2API = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/matrix-2/",
  executeAtStart: false,
};
export const DashboardMatrix3API = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/matrix-3/",
  executeAtStart: false,
};
export const DashboardMatrix4API = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/matrix-4/",
  executeAtStart: false,
};
export const ListByDNIAPI: any = {
  method: "POST",
  url: "/api/v1/pregnat/list/by-dni",
  executeAtStart: false,
};
export const ListAttendanceDetails: any = {
  method: "POST",
  url: "/api/v1/pregnat/attendace/details/list",
  executeAtStart: false,
};
export const ExportDataByBirth: any = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/export-by-birth",
  executeAtStart: false,
};

export const ExportDataByTeens: any = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/export-by-teens",
  executeAtStart: false,
};

export const ExportDataByVariable: any = {
  method: "POST",
  url: "/api/v1/pregnat/dashboard/export-by-variable",
  executeAtStart: false,
};
export const EstimatedDate = generateCrudApi("pregnat/estimated-date");

export const PregnatAPIConfig = {};
