export const HomeMaternalDeathsReportAPI = {
  method: "POST",
  url: "/api/v1/maternal-deaths/home/general-report/",
  executeAtStart: false,
};

export const DashboardMaternalDeathsReportMatrixAPI = {
  method: "POST",
  url: "/api/v1/maternal-deaths/dashboard/matrix-1",
  executeAtStart: false,
};

export const ExportDataMaternalDeath: any = {
  method: "POST",
  url: "/api/v1/maternal-deaths/dashboard/export-data",
  executeAtStart: false,
};
