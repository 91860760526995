const useSesionStorage = (name) => {
    const setContent = (content) => {
        sessionStorage.setItem(name,JSON.stringify(content))
    }
    const getContent = () => {
        return JSON.parse(sessionStorage.getItem(name))
    }
    return ([setContent,getContent])
}
export default useSesionStorage;
