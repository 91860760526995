import { Button, Input } from "antd";
import React from "react";

const Search = ({ value, onChange, handleVisibility }) => {
  return (
    <Input
      prefix={
        <Button
          type="link"
          shape="round"
          icon={<i className="ri-search-line" />}
          onClick={() => handleVisibility(true)}
        />
      }
      autoFocus={true}
      placeholder="Buscar..."
      style={{
        border: "none",
        borderBottom: "1px dashed #c3c3c3",
        padding: 0,
      }}
      onBlur={() => handleVisibility(Boolean(value))}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      allowClear
    />
  );
};

export default Search;
