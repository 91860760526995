import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { HOST_API } from "core/hooks/host_api";

const name = "ficha-gestacional";
export const FichaGestacionalAPI = {
  add: {
    method: "POST",
    url: `/api/v1/${name}/create`,
    executeAtStart: false,
    host_api: HOST_API,
  },
  update: {
    method: "POST",
    url: `/api/v1/${name}/update`,
    executeAtStart: false,
    host_api: HOST_API,
  },
  delete: {
    method: "POST",
    url: `/api/v1/${name}/delete`,
    executeAtStart: false,
    host_api: HOST_API,
  },
  list: {
    method: "POST",
    url: `/api/v1/${name}/list-migracion`,
    executeAtStart: true,
    host_api: HOST_API,
  },
  listCambioSeguro: {
    method: "POST",
    url: `/api/v1/${name}/list-cambio-seguro`,
    executeAtStart: true,
    host_api: HOST_API,
  },
  listRegionesYEESS: {
    method: "POST",
    url: `/api/v1/${name}/regiones-y-establecimientos`,
    executeAtStart: true,
    host_api: HOST_API,
  },
  listTipoSeguro: {
    method: "GET",
    url: `/api/v1/${name}/tipos-seguro/listar`,
    executeAtStart: true,
    host_api: HOST_API,
  },
  listaFactoresRiesgo: {
    method: "POST",
    url: `/api/v1/${name}/factores-riesgo`,
    executeAtStart: false,
    host_api: HOST_API,
  },
  atpList: {
    method: "POST",
    url: `/api/v1/${name}/atp`,
    executeAtStart: false,
    host_api: HOST_API,
  },
  ultimoLugarAtencion: {
    method: "POST",
    url: `/api/v1/${name}/ultimo-lugar-atencion`,
    executeAtStart: false,
    host_api: HOST_API,
  },
  listEnviados: {
    method: "POST",
    url: `/api/v1/${name}/list-enviados`,
    executeAtStart: true,
    host_api: HOST_API,
  },
  listRecibidos: {
    method: "POST",
    url: `/api/v1/${name}/list-recibidos`,
    executeAtStart: true,
    host_api: HOST_API,
  },
};

export const FichaGestacionalFormComun: IInputPayload[] = [
  {
    name: "idRegistro",
    type: "input",
    disabled: true,
    required: true,
    customProps: {
      type: "number",
      required: true,
    },
  },
  {
    name: "idTipoFicha",
    type: "radio-button",
    disabled: true,
    optionsDropdown: [
      { value: 1, name: "Migracion" },
      { value: 2, name: "Cambio de seguro" },
    ],
    optionLabel: "name",
    optionValue: "value",
    required: true,
    customProps: {
      required: true,
      disabled: "true",
    },
  },
  // {
  //   name: "Persona",
  //   type: "title-separator",
  // },
  {
    name: "fecha",
    type: "input",
    required: true,
    disabled: true,
    customProps: {
      required: true,
      type: "date",
      disabled: true,
      displayName: "Fecha del registro",
    },
  },
  // {
  //   name: "nroDocumento",
  //   type: "input",
  //   customProps: {
  //     type: "number",
  //     displayName: "Nro de documento",
  //   },
  // },
  // {
  //   name: "edadRegistro",
  //   type: "input",
  //   customProps: {
  //     type: "number",
  //     displayName: "Edad de registro",
  //   },
  // },
  {
    name: "telefono",
    required: true,
    disabled: true,
    type: "input",
    customProps: {
      disabled: true,
      required: true,
      displayName: "Telefono de gestante",
    },
  },
  {
    name: "Datos clinicos",
    type: "title-separator",
  },

  {
    name: "antecedentesPersonales",
    type: "input-description",
    required: true,
    customProps: {
      required: true,
      displayName: "Antecedentes personales",
    },
  },
  // {
  //   name: "antecedentesObstetricos",
  //   type: "input-description",
  //   required: true,
  //   customProps: {
  //     required: true,
  //     displayName: "Antecedentes obstetricos",
  //   },
  // },
  // {
  //   name: "riesgosObstetricos",
  //   type: "radio-button",
  //   optionsDropdown: [
  //     { name: "ALTO RIESGO", value: "ALTO RIESGO" },
  //     { name: "BAJO RIESGO", value: "BAJO RIESGO" },
  //   ],
  //   optionLabel: "name",
  //   optionValue: "value",
  //   required: true,
  //   customProps: {
  //     required: true,
  //     displayName: "Riesgos obstetricos",
  //   },
  // },
  {
    name: "diagnosticoActual",
    type: "input-description",
    required: true,
    customProps: {
      required: true,
      displayName: "Diagnostico actual",
    },
  },
  {
    name: "GP",
    required: true,
    type: "input",
    customProps: {
      required: true,
    },
  },
  {
    name: "FUR",
    type: "input",
    required: true,
    customProps: {
      type: "date",
      required: true,
      displayName: "Fecha de ultima regla",
    },
  },
  {
    name: "FPP",
    type: "input",
    required: true,
    customProps: {
      type: "date",
      required: true,
      displayName: "Fecha probable de parto",
    },
  },
  // {
  //   name: "nroATP",
  //   type: "input",
  //   required: true,
  //   customProps: {
  //     required: true,
  //   },
  // },
];

export const FichaGestacionalFormComunPart2: IInputPayload[] = [
  {
    name: "observacion",
    type: "input-description",
    required: true,

    customProps: {
      spanCol: "span 4",
      required: true,
    },
  },
  {
    name: "estado",
    type: "radio-button",
    required: true,

    optionsDropdown: [
      { value: 1, label: "Activo" },
      { value: 2, label: "Inactivo" },
    ],
    disabled: true,
    optionLabel: "label",
    optionValue: "value",
    customProps: {
      required: true,
    },
  },
  {
    name: "estadoRegistro",
    type: "input",
    disabled: true,
    required: true,
    customProps: {
      required: true,
    },
  },
];

export const FichaGestacionalForm: IInputPayload[] = [
  ...FichaGestacionalFormComun,
  {
    name: "Migracion",
    type: "title-separator",
  },
  {
    name: "ipressProcedencia",
    type: "dropdown",
    optionsDropdown: [],
    optionLabel: "Nombre_Establecimiento",
    optionValue: "Codigo_Unico",
    customProps: {
      listApiConfig: FichaGestacionalAPI.listRegionesYEESS,
      spanCol: "span 2",
      displayName: "IPRESS de procedencia",
      placeholder: "Selecciona una IPRESS",
    },
  },
  {
    name: "ipressAcudira",
    type: "dropdown",
    optionsDropdown: [],
    optionLabel: "Nombre_Establecimiento",
    optionValue: "Codigo_Unico",
    customProps: {
      listApiConfig: FichaGestacionalAPI.listRegionesYEESS,
      spanCol: "span 2",
      displayName: "IPRESS que acudirá",
      placeholder: "Selecciona una IPRESS",
    },
  },
  {
    name: "motivoCambioDomicilio",
    type: "input-description",
    customProps: {
      spanCol: "span 4",
      displayName: "Motivo de cambio de domicilio",
    },
  },
  {
    name: "direccionNuevoDomicilio",
    type: "input",
    customProps: {
      displayName: "Dirección del nuevo domicilio",
    },
  },
  {
    name: "referenciaNuevoDomicilio",
    type: "input",
    customProps: {
      displayName: "Referencia de nuevo domicilio",
    },
  },
  // {
  //   name: "regionMigra",
  //   type: "input",
  //   customProps: {
  //     display: "Región a la que migra",
  //   },
  // },
  ...FichaGestacionalFormComunPart2,
];

export const FichaGestacionalFormCambioSeguro: IInputPayload[] = [
  ...FichaGestacionalFormComun,
  {
    name: "Cambio de seguro",
    type: "title-separator",
  },
  {
    name: "tipoSeguro",
    type: "input",
  },
  { name: "motivoCambioSeguro", type: "input-description" },

  {
    name: "Datos familiares",
    type: "title-separator",
  },

  {
    name: "datosFamiliar",
    type: "input",
  },
  {
    name: "parentesco",
    type: "input",
  },
  ...FichaGestacionalFormComunPart2,
];
