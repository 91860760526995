import { useLogged } from "core/hooks/loggedContext";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { addDefaultValues } from "core/utils/form";
import { generateMapForm } from "core/utils/tables";
import React, { useState } from "react";
import { Button, Card, Modal, Table, message } from "antd";
import useAxios from "core/hooks/useAxios";
import { Formulario } from "core/ts_formulario_model";
import { Row } from "core/util-styled-components";
import { UserConfig } from "api/models/ConfigAPI";
import { RoleDropdownDefinition } from "api/models/Role";
import PacientFilter from "../Vigilance/components/PacientFilter";
import UserView from "./UserView";

const Usuarios = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [pacientSeleccionado, setPacientSeleccionado] = useState({});
  const UsuarioList = useAxios(UserConfig.apiConfig.list);
  const AddUsuario = useAxios(UserConfig.apiConfig.add2);
  const UpdateUsuario = useAxios(UserConfig.apiConfig.update);
  const [editData, setEditData] = useState({});
  const [modalType, setModalType] = useState("add");
  const [activeModal, setActiveModal] = useState("");

  const onAction = async (payload) => {
    const params = {
      ...payload,
      idRol: payload.idRol[0].idRol,
      nroDocumento: pacientSeleccionado?.dni,
    };
    if (modalType === "add") {
      const response = await AddUsuario.startRequest(params);
      if (response?.check) {
        setActiveModal(false);
        message.success(response?.message);
        UsuarioList.startRequest();
      } else {
        message.error(response?.message);
      }
    }
    if (modalType === "edit") {
      const response = await UpdateUsuario.startRequest({
        ...params,
        idVigilancia: editData.idVigilancia,
      });
      if (response?.check) {
        setActiveModal(false);
        message.success(response?.message);
        UsuarioList.startRequest();
      } else {
        message.error(response?.message);
      }
    }
  };

  const validateFormReload = () => {
    if (modalType === "add") {
      return true;
    }
    if (modalType === "edit" && Object.keys(editData).length > 0) {
      return true;
    }
    return false;
  };

  const isView = () => modalType === "view";

  const selectPacient = (data) => {
    setPacientSeleccionado({
      dni: data.nroDocumento,
      fechaNacimiento: data.fechaNacimiento,
      direccion: data.direccionDomicilio,
      edad: data.edad,
      nombreCompleto: `${data.nombres} ${data.apellidoPaterno} ${data.apellidoMaterno}`,
      genero: data.idTipoSexo === 1 ? "MASCULINO" : "FEMENINO",
    });
  };

  return (
    <div>
      <Card
        title="Usuario"
        style={{ marginBottom: "1em" }}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setModalType("add");
              setActiveModal(true);
            }}
            icon={<i className="ri-heart-add-line" />}
            shape="round"
          >
            Agregar usuario
          </Button>
        }
      >
        <Table
          dataSource={UsuarioList.data}
          columns={[
            {
              title: "",
              className: "no-padding",
              render: (data) => (
                <Row justifyContent="space-between">
                  <Row gap="5px" style={{ margin: "10px 5px" }}>
                    <i className="ri-user-3-fill" />
                    {data.usuario}
                  </Row>
                  <Row style={{ maxWidth: 60 }} gap=".5em">
                    <Button
                      size="small"
                      icon={<i className="ri-file-text-line"></i>}
                      onClick={() => {
                        setModalType("view");
                        setEditData(data);
                        selectPacient(data);
                        setActiveModal(true);
                      }}
                    />
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setModalType("edit");
                        setEditData(data);
                        selectPacient(data);
                        setActiveModal(true);
                      }}
                      icon={<i className="ri-edit-line" />}
                    ></Button>
                  </Row>
                </Row>
              ),
            },
          ]}
        />
      </Card>

      <Modal
        open={activeModal}
        footer={null}
        width="80%"
        bodyStyle={{
          padding: "0",
          width: "100%",
        }}
        centered
        destroyOnClose
        closable={false}
        onCancel={() => {
          setModalType("add");
          setActiveModal(false);
          setPacientSeleccionado({});
          setEditData({});
        }}
      >
        {validateFormReload() && (
          <Formulario width="100%" style={{ padding: 0 }} onSubmit={onAction}>
            <Formulario.FormControls
              cols={isTabletOrMobile ? 1 : 4}
              controlGap="0 .5em"
              gap="0px"
              onCancel={() => {
                setModalType("add");
                setActiveModal(false);
              }}
              inputs={addDefaultValues(
                generateMapForm([
                  {
                    name: "Buscar persona",
                    type: "custom",
                    customProps: {
                      render: <PacientFilter validateTelf={false} data={pacientSeleccionado} onChange={(data) => setPacientSeleccionado(data)} />,
                    },
                  },
                  {
                    name: "usuario",
                    type: "input",
                    customProps: {
                      displayName: "Usuario",
                      gap: "2.5px",
                      style: {
                        maxHeight: "100px",
                      },
                    },
                  },
                  {
                    name: "contraseña",
                    type: "input",
                    customProps: {
                      displayName: "Contraseña",
                      gap: "2.5px",
                      style: {
                        maxHeight: "100px",
                      },
                    },
                  },

                  {
                    ...RoleDropdownDefinition,
                    customProps: {
                      ...RoleDropdownDefinition.customProps,
                      gap: "2.5px",
                    },
                  },
                  {
                    name: "estado",
                    type: "radio-button",
                    optionsDropdown: [
                      { name: "Activo", value: 1 },
                      { name: "Inactivo", value: 0 },
                    ],
                    optionLabel: "name",
                    optionValue: "value",
                    customProps: {
                      gap: "2.5px",
                    },
                  },
                  {
                    name: "avatar",
                    type: "input",
                    customProps: {
                      gap: "2.5px",
                      displayName: "URL Foto Perfil",
                      spanCol: "span 3",
                    },
                  },
                ]),
                {
                  ...editData,
                }
              )}
            />
          </Formulario>
        )}
        {isView() && <UserView data={{ pacientSeleccionado, editData }} />}
      </Modal>
    </div>
  );
};

export default Usuarios;
