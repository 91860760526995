import { EndPointForm, EndpointAPI } from ".";
import { EndPointTable } from "./table";

export const EndPointConfig = {
  id: 1,
  name: "Endpoint",
  apiConfig: EndpointAPI,
  columns: EndPointTable,
  form: EndPointForm,
};
