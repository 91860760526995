import React, { useEffect } from "react";
import { GeneralControlProps } from "../../../../types/interfaces";
import { DropdownStyled } from "../FormControl/styled";
import useAxios from "core/hooks/useAxios";
const getValues = (
  hookForm,
  data = [],
  optionValue = "",
  defaultValue = ""
) => {
  if (Array.isArray(hookForm?.field?.value)) {
    return hookForm?.field?.value;
  }

  if (Array.isArray(hookForm?.value)) {
    return hookForm?.value;
  }

  if (defaultValue) {
    return getDefaultValue(data, optionValue, defaultValue);
  }

  return [];
};

const getDefaultValue = (data, optionValue, defaultValue) => {
  return data.filter((el) => el[optionValue] === defaultValue);
};

const DropdownControl = ({ hookForm, rest }: GeneralControlProps) => {
  const List = useAxios();

  useEffect(() => {
    if (rest.customProps?.listApiConfig) {
      List.updateApiConfig(rest.customProps?.listApiConfig);
    }

    return () => {};
    // eslint-disable-next-line
  }, [rest.customProps?.listApiConfig]);

  return (
    <div>
      {rest.optionsDropdown && rest.optionLabel && rest.optionValue ? (
        <DropdownStyled
          values={getValues(
            hookForm,
            rest.customProps?.listApiConfig ? List.data : rest.optionsDropdown,
            rest.optionValue,
            rest.customProps?.defaultValue
          )}
          options={
            rest.customProps?.listApiConfig ? List.data : rest.optionsDropdown
          }
          labelField={rest.optionLabel}
          valueField={rest.optionValue}
          searchBy={rest.optionLabel}
          dropdownGap={5}
          clearable
          searchable
          dropdownPosition="auto"
          onChange={(e: any) => {
            rest.onChange && rest.onChange(e);
            if (hookForm.field) {
              hookForm.field.onChange(e);
            } else {
              hookForm.onChange(e);
            }
          }}
          {...rest.customProps}
        />
      ) : (
        "Problema al carga este elemento..."
      )}
    </div>
  );
};

export default DropdownControl;
