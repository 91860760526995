import { Button, Input, Modal, Table, message } from "antd";
import { Column } from "core/util-styled-components";
import React, { useEffect, useState } from "react";
import { readExcel } from "../functions/readFile";
import { generateMapTable } from "core/utils/tables";
import useAxios from "core/hooks/useAxios";

const MasiveUpload = ({ apiConfig }) => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const MasiveUploadApi = useAxios();

  useEffect(() => {
    MasiveUploadApi.updateApiConfig(apiConfig.masive);
    // eslint-disable-next-line
  }, [apiConfig.masive]);

  const onLoadData = async ({ target }) => {
    try {
      message.loading("Cargando datos....");
      const _data = await readExcel(target.files[0]);
      setColumns(generateMapTable(Object.keys(_data[0])));
      setData(_data);
      message.success("Ha terminado de cargar");
    } catch (error) {
      message.error("Ocurrió un error al intentar cargar los datos");
    }
  };

  const onSubmitMasive = async () => {
    const response = await MasiveUploadApi.startRequest({ data });
    console.log(response);
  };

  return (
    <div>
      <Button
        icon={<i className="ri-upload-line" />}
        onClick={() => setOpenModal(true)}
      >
        <strong>Cargar datos (masivo)</strong>
      </Button>
      <Modal
        title="Carga masiva de datos"
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
        }}
        destroyOnClose
        style={{
          minWidth: "80%",
          maxHeight: "80vh",
        }}
        onOk={onSubmitMasive}
        centered
      >
        <Column>
          <Input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="excel-masivo"
            onChange={onLoadData}
          />
          <div
            style={{
              maxWidth: "100%",
              maxHeight: "70vh",
              overflow: "auto",
            }}
          >
            <Table size="small" dataSource={data} columns={columns} />
          </div>
        </Column>
      </Modal>
    </div>
  );
};

export default MasiveUpload;
