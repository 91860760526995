import { generateCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";

export interface BaseCut {
  fuente: string;
  fechaCorteBase: string;
  horaCorteBase: string;
  SemanaEpidemiologica: string;
  Estado: string;
}

export const BaseCutsAPI = generateCrudApi("base-cuts");
export const BaseCutsForm: IInputPayload[] = [
  {
    name: "idfuente",
    type: "input",
    disabled: true,
  },
  {
    name: "tipo",
    type: "radio-button",
    defaultValue: "1",
    optionsDropdown: [
      {
        value: "PRINCIPAL",
      },
      {
        value: "MUERTES MATERNAS",
      },
    ],
    optionLabel: "value",
    optionValue: "value",
    customProps: {
      displayName: "Tipo",
      gap: "0px",
      defaultValue: "1",
    },
  },
  {
    name: "fuente",
    type: "input",
    defaultValue: "HIS-MINSA",
    customProps: {
      displayName: "Fuente",
      gap: "0px",
      defaultValue: "HIS-MINSA",
    },
  },
  {
    name: "fechaCorteBase",
    type: "input",
    customProps: {
      displayName: "Fecha de corte de base",
      gap: "0px",
      type: "date",
    },
  },
  {
    name: "horaCorteBase",
    type: "input",
    defaultValue: "00:00",
    disabled: true,
    customProps: {
      displayName: "Hora de corte de base",
      gap: "0px",
      type: "time",
      defaultValue: "00:00",
    },
  },
  {
    name: "SemanaEpidemiologica",
    type: "input",
    customProps: {
      displayName: "Semana epidemiológica",
      gap: "0px",
    },
  },
  {
    name: "Estado",
    type: "radio-button",
    optionsDropdown: [
      {
        id: 1,
        value: "ACTIVO",
      },
      {
        id: 2,
        value: "INACTIVO",
      },
    ],
    optionLabel: "value",
    optionValue: "id",
    defaultValue: "1",
    customProps: {
      displayName: "Estado",
      gap: "0px",
      defaultValue: "1",
    },
  },
];
