import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";

export const ReportLevelAPI = {
  method: "GET",
  url: `/api/v1/filters/report-level/list`,
  executeAtStart: true,
};
export const ReportEnviromentAPI = {
  method: "GET",
  url: `/api/v1/filters/report-enviroment/list`,
  executeAtStart: true,
};

export const ReportLevelDropdownDefinition: IInputPayload = {
  name: "idnivelReporte",
  type: "dropdown",
  optionsDropdown: [],
  optionLabel: "descripcion",
  optionValue: "idnivelReporte",
  required: true,
  customProps: {
    required: true,
    listApiConfig: ReportLevelAPI,
    displayName: "Nivel ",
    placeholder: "Selecciona un " + "Nivel de reporte"?.toLowerCase(),
  },
};
