import { Table, Tag, Divider } from "antd";
import { VigilanceAPI, VigilanceVariablesList } from "api/models/Vigilance";
import useAxios from "core/hooks/useAxios";
import { Column, Typography } from "core/util-styled-components";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import React, { useEffect } from "react";

const getColor = (color) => {
  return color === "AMARILLO" ? "🟡" : color === "ROJO" ? "🔴" : color === "VERDE" ? "🟢" : "⚪";
};
const VigilanceHistory = ({ nroDocumento }) => {
  const History = useAxios(VigilanceAPI.history);
  const VariableList = useAxios(VigilanceVariablesList);

  useEffect(() => {
    nroDocumento &&
      History.startRequest({
        Num_doc: nroDocumento,
      });

    VariableList.startRequest({
      nroDocumento: nroDocumento,
    });
    return () => {};
    // eslint-disable-next-line
  }, [nroDocumento]);

  // const orderByDate = (a, b) => {
  //   return new Date(b.Fecha_atencion).getTime() - new Date(a.Fecha_atencion).getTime();
  // };

  return (
    <Column gap="5px">
      <Divider>
        <Typography variant="span">Historial de vigilancia</Typography>
      </Divider>
      <Table
        size="small"
        pagination={false}
        dataSource={History.data}
        columns={generateMapTable([
          {
            title: "Factor de riesgo",
            render: (text: any) => `${getColor(text.color)} ${text.FactorRiesgo}`,
          },
          "SUBREGION",
          "DISTRITO",
          "Nombre_Establecimiento",
          {
            dataIndex: "FECHA_ATENCION",
            title: "Fecha de atención",
            render: (text: any) => (text ? format(addHours(new Date(text), 5), "dd/MM/yyyy") : ""),
          },
        ])}
      />

      {/* <Divider>
        <Typography variant="span">
          Atenciones en medicina <Tag>No disponible</Tag>
        </Typography>
      </Divider> */}

      {/* <Typography variant="span">Variables</Typography>
      <Table
        dataSource={VariableList.data.sort(orderByDate)}
        columns={generateMapTable([
          "anio",
          "Num_doc",
          "Variables",
          {
            dataIndex: "Fecha_atencion",
            title: "Fecha de atención",
            render: (text: any) => format(addHours(new Date(text), 5), "dd/MM/yyyy"),
          },
          "RENAES",
          "edad",
        ])}
        size="small"
      /> */}
    </Column>
  );
};

export default VigilanceHistory;
