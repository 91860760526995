import { HOST_API } from "core/hooks/host_api";
import useAxios from "core/hooks/useAxios";
import { DropdownStyled } from "core/ts_formulario_model/src/App/Components/Molecules/FormControl/styled";
import { Row, RowWrap } from "core/util-styled-components";
import React, { useEffect, useState } from "react";

const Filter = ({ value, onChange }) => {
  const IpressPertenencias = useAxios({
    method: "GET",
    url: "/api/v1/ipress/ipress-pertenencias",
    executeAtStart: true,
    host_api: HOST_API,
  });

  const [diresa, setDiresa] = useState<any[]>([]);
  const [provincia, setProvincia] = useState<any[]>([]);
  const [distrito, setDistrito] = useState<any[]>([]);
  const [Ipress, setIpress] = useState<any>([]);

  useEffect(() => {
    // en base a  diresa, provincia, distrito, ipress hay que construir un string que sea el primer valor label de cada dropdown separado por un |, se debe validar que exista el valor
    // si no existe el valor se ignorará lo que sigue
    const diresaValue = diresa.length ? diresa[0].label : "";
    const provinciaValue = provincia.length ? provincia[0].label : "";
    const distritoValue = distrito.length ? distrito[0].label : "";
    const ipressValue = Ipress.length ? Ipress[0].label : "";
    const value = `${diresaValue}|${provinciaValue}|${distritoValue}|${ipressValue}`
      .split("|")
      .filter((el) => el.length)
      .join("|");
    onChange(value);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diresa, provincia, distrito, Ipress]);

  return (
    <RowWrap gap="5px" justifyContent="center">
      <DropdownStyled
        placeholder="Seleccione SUBREGION"
        options={Object.keys(IpressPertenencias.data).map((el) => {
          return {
            label: el,
            value: IpressPertenencias.data[el],
          };
        })}
        values={diresa}
        onChange={(e) => {
          setDiresa(e);
          setProvincia([]);
          setDistrito([]);
          setIpress([]);
        }}
        labelField="label"
        valueField="value"
        style={{
          minWidth: "250px",
        }}
        clearable
      />
      <DropdownStyled
        placeholder="Seleccione Provincia"
        clearable
        options={Object.keys(diresa.length ? diresa[0].value : {}).map((el) => {
          return {
            label: el,
            value: diresa[0].value[el],
          };
        })}
        values={provincia}
        onChange={(e) => {
          setProvincia(e);
          setDistrito([]);
          setIpress([]);
        }}
        labelField="label"
        valueField="value"
        style={{
          minWidth: "250px",
        }}
      />
      <DropdownStyled
        placeholder="Seleccione Distrito"
        clearable
        options={Object.keys(provincia.length ? provincia[0].value : {}).map((el) => {
          return {
            label: el,
            value: provincia[0].value[el],
          };
        })}
        values={distrito}
        onChange={(e) => {
          setDistrito(e);
          setIpress([]);
        }}
        labelField="label"
        valueField="value"
        style={{
          minWidth: "250px",
        }}
      />
      <DropdownStyled
        placeholder="Seleccione una Ipress"
        clearable
        options={Object.keys(distrito.length ? distrito[0].value : {}).map((el) => {
          return {
            label: el,
            value: distrito[0].value[el],
          };
        })}
        values={Ipress}
        onChange={(e) => {
          setIpress(e);
        }}
        labelField="label"
        valueField="value"
        style={{
          minWidth: "250px",
        }}
      />
    </RowWrap>
  );
};

export default Filter;
