import { VigilanceAPI } from ".";
import { VigilanceTable } from "./table";

export const VigilanceConfig = {
  id: 14,
  name: "Vigilancia",
  apiConfig: VigilanceAPI,
  columns: VigilanceTable,
  form: [],
};
