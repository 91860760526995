import { Row } from "core/util-styled-components";
import React from "react";
import RelationModal from "./RelationModal";
import { Button, Tooltip } from "antd";

const ActionRender = ({
  data,
  relationShip,
  onActionClick,
  readOnly,
  actions,
}) => {
  return (
    <Row
      justifyContent="space-between"
      style={{
        borderBottom: "dashed 1px #f0f0f0",
        padding: 10,
        borderRadius: 0,
      }}
    >
      {!readOnly && (
        <Row gap="5px">
          {relationShip ? (
            <RelationModal data={data} relationShip={relationShip} />
          ) : (
            ""
          )}
          {
            // @ts-ignore
            actions.map((action) => (
              <Tooltip title={action.label}>
                <Button
                  type={action.type}
                  danger={action.danger}
                  onClick={() => onActionClick(action, data)}
                  icon={<i className={action.icon} />}
                >
                  {/* {!isTabletOrMobile && <span>{action.label}</span>} */}
                </Button>
              </Tooltip>
            ))
          }
        </Row>
      )}
    </Row>
  );
};

export default ActionRender;
