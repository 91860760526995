export const PacientDocs = [
  {
    Id_Tipo_Documento: 1,
    Abrev_Tipo_Doc: "DNI",
    Descripcion_Tipo_Documento: "Documento Nacional de Identidad",
  },
  {
    Id_Tipo_Documento: 2,
    Abrev_Tipo_Doc: "CE",
    Descripcion_Tipo_Documento: "Carnet de Extranjería",
  },
  {
    Id_Tipo_Documento: 3,
    Abrev_Tipo_Doc: "PASS",
    Descripcion_Tipo_Documento: "Pasaporte",
  },
  {
    Id_Tipo_Documento: 4,
    Abrev_Tipo_Doc: "DIE",
    Descripcion_Tipo_Documento: "Documento de Identidad Extranjero",
  },
  {
    Id_Tipo_Documento: 5,
    Abrev_Tipo_Doc: "S/ DOCUMENTO",
    Descripcion_Tipo_Documento: "Sin Documento",
  },
  {
    Id_Tipo_Documento: 6,
    Abrev_Tipo_Doc: "CNV",
    Descripcion_Tipo_Documento: "Certificado de Nacido Vivo",
  },
];

export const TipoDocDropdown = {
  name: "idDocIdentidad",
  type: "dropdown",
  optionsDropdown: PacientDocs,
  optionLabel: "Abrev_Tipo_Doc",
  optionValue: "Abrev_Tipo_Doc",
  required: true,
  customProps: {
    required: true,
    displayName: "TIp. Documento",
    disabled: true,
  },
};

export const TipoDocDropdownNotDisabled = {
  name: "idDocIdentidad",
  type: "dropdown",
  optionsDropdown: PacientDocs,
  optionLabel: "Abrev_Tipo_Doc",
  optionValue: "Abrev_Tipo_Doc",
  required: true,
  customProps: {
    required: true,
    displayName: "TIp. Documento",
  },
};
