import { Divider, Modal } from "antd";
import useAxios from "core/hooks/useAxios";
import { Formulario } from "core/ts_formulario_model";
import { Row, Typography } from "core/util-styled-components";
import { addDefaultValues } from "core/utils/form";
import { generateMapForm } from "core/utils/tables";
import React, { useEffect, useState } from "react";
import { _form_busqueda, _form_paciente } from "./_form";
import { transformLocalData, transformReniecToFormData } from "./_transform";
import { PersonAPI } from "api/models/Person";
import { PacientAPI } from "api/models/Pacient";
import { PacientDocs } from "api/models/Pacient/data";

const PacientSearch = ({ onOk, defaultSearch = null }) => {
  const [paciente, setPaciente] = useState(null);
  const [habilitaFormulario, setHabilitaFormulario] = useState(false);
  const [fuente, setFuente] = useState("");

  const ReniecService = useAxios(PersonAPI.reniecService);
  const SearchPacient = useAxios(PacientAPI.search);

  const searchPacientReniec = async (value: string) => {
    const response = await ReniecService.startRequest({
      dni: value,
    });
    if (response?.nombreCompleto !== "undefined undefined undefined") {
      setPaciente(transformReniecToFormData(response));
      setHabilitaFormulario(true);
    } else {
      setPaciente(null);
      Modal.error({
        content: "No se encontró el paciente, es posible que no esté registrado en RENIEC o que haya ingresado un DNI incorrecto",
      });
    }
  };

  const searchPacient = async (data) => {
    const idTipoDocumento = data.idDocIdentidad[0].Id_Tipo_Documento;
    const response = await SearchPacient.startRequest({
      idTipoDocumento,
      nroDocumento: data.nroDocumento,
    });
    if (response.length) {
      const data = response[0];
      return data;
    }
    return null;
  };

  useEffect(() => {
    if (defaultSearch) {
      onSearch({
        idDocIdentidad: PacientDocs.filter((el) => el.Id_Tipo_Documento === (defaultSearch.idTipoDoc || 1)),
        nroDocumento: defaultSearch.nroDocumento,
      });
    }

    return () => {};
    // eslint-disable-next-line
  }, [defaultSearch]);

  const onSearch = async (data) => {
    setHabilitaFormulario(false);
    setPaciente(null);
    const LocalData = await searchPacient(data);
    if (LocalData) {
      setFuente("Datos locales");
      setPaciente(transformLocalData(LocalData));
      setHabilitaFormulario(true);
    } else {
      const tipDoc = data.idDocIdentidad[0];
      if (tipDoc.Id_Tipo_Documento === 1) {
        setFuente("Datos de reniec");
        searchPacientReniec(data.nroDocumento);
      } else {
        setFuente("Datos nuevos");
        setPaciente({});
        setHabilitaFormulario(true);
        Modal.error({
          content: "No es posible buscar si no es un DNI, por favor llene los datos manuales",
        });
      }
    }
  };

  return (
    <div>
      <Formulario onSubmit={onSearch} style={{ marginBottom: 0, paddingBottom: 0 }}>
        <Formulario.FormControls
          formControlStyles={{
            flexDirection: "row",
            alignItems: "flex-end",
          }}
          buttonWidthPanel="auto"
          buttonProps={{
            style: {
              margin: "5px 0",
              padding: 0,
            },
            cancel: {
              style: {
                display: "none",
              },
            },
          }}
          submitLabel={
            <Row gap={"8px"}>
              <i className="ri-search-line" />
              <Typography color="inherit">Buscar</Typography>
            </Row>
          }
          cols={12}
          controlGap="10px 10px"
          inputs={generateMapForm(_form_busqueda)}
        />
      </Formulario>
      {habilitaFormulario && (
        <Formulario onSubmit={onOk}>
          <Divider orientation="left" style={{ margin: 0, marginBottom: "5px" }}>
            Datos del paciente ({fuente})
          </Divider>
          <Formulario.FormControls
            buttonProps={{
              cancel: {
                style: { display: "none" },
              },
            }}
            submitLabel="Siguiente"
            onCancel={() => {
              //   setVisibleModal(false);
            }}
            cols={3}
            inputs={addDefaultValues(generateMapForm(_form_paciente), paciente)}
          />
        </Formulario>
      )}
    </div>
  );
};

export default PacientSearch;
