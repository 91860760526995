import { Card, Radio, Typography } from "antd";
import React from "react";
import { Heading, Row } from "core/util-styled-components";
import FilterRank from "./components/FilterRank";
import DataView from "./components/DataView";
import ChartView from "./components/ChartView";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
const DashboardMatrixBase = ({
  name = "Muertes maternas",
  rank,
  setRank,
  dropdownValues,
  setDropdownValues,
  data,
  ranks,
  cardConfig,
  chartConfig,
  cardOrientation = "row",
  onCardClick = null,
  chartType = "line",
  footerType = "PRINCIPAL",
  fuente = null,
}) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <Card
      size={isTabletOrMobile ? "small" : "default"}
      title={
        <Heading
          variant="h2"
          style={{
            fontWeight: "700",
            textTransform: "uppercase",
            // need a distintive color for each card
            fontStyle: "italic",
          }}
        >
          {name}
        </Heading>
      }
      extra={
        isTabletOrMobile ? null : (
          <Row gap="10px">
            <Typography>Vista por: </Typography>
            <Radio.Group value={rank} onChange={(e) => setRank(e.target.value)}>
              {ranks?.map((el, idx) => {
                return <Radio value={idx}>{el}</Radio>;
              })}
            </Radio.Group>
          </Row>
        )
      }
    >
      {isTabletOrMobile && (
        <Radio.Group value={rank} onChange={(e) => setRank(e.target.value)}>
          {ranks?.map((el, idx) => {
            return <Radio value={idx}>{el}</Radio>;
          })}
        </Radio.Group>
      )}

      <FilterRank rank={rank} ranks={ranks} values={dropdownValues} setValues={setDropdownValues} options={data} />
      <Row gap="10px" alignItems="flex-start" responsiveReorder>
        <Card
          size={isTabletOrMobile ? "small" : "default"}
          title={"Cantidad de " + name.toLowerCase()}
          style={{
            width: isTabletOrMobile ? "100%" : "40%",
            alignSelf: "flex-start",
          }}
        >
          <DataView dataSet={data} data={dropdownValues} rank={rank} ranks={ranks} cardConfig={cardConfig} cardOrientation={cardOrientation} onCardClick={onCardClick} />
        </Card>
        <ChartView dataSet={data} data={dropdownValues} rank={rank} ranks={ranks} cardConfig={chartConfig ? chartConfig : cardConfig} chartType={chartType} footerType={footerType} fuente={fuente} />
      </Row>
    </Card>
  );
};

export default DashboardMatrixBase;
