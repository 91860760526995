import React, { useEffect, useState } from "react";
import PacientFilter from "../Vigilance/components/PacientFilter";
import { Button, Descriptions, Divider, Modal } from "antd";
import { Div, Row, Typography } from "core/util-styled-components";
import useAxios from "core/hooks/useAxios";
import { PersonEstablishmentAPI } from "api/models/PersonEstablishment";
import { Formulario } from "core/ts_formulario_model";
import { generateMapForm } from "core/utils/tables";
import { EstablishmentDropdownDefinition } from "api/models/Establishment";
import { addDefaultValues } from "core/utils/form";

const UserView = ({ data }) => {
  const { pacientSeleccionado, editData } = data;
  const [activeModal, setActiveModal] = useState(false);

  const Pertenencia: any = useAxios(PersonEstablishmentAPI.list);
  const PertenenciaAD: any = useAxios(PersonEstablishmentAPI.add);
  const PertenenciaUP: any = useAxios(PersonEstablishmentAPI.update);

  useEffect(() => {
    if (!editData) return;
    Pertenencia.startRequest({
      idPersona: editData.idPersona,
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const savePersonEstablishment = async (values) => {
    if (Pertenencia.data?.length > 0) {
      // edit mode
      await PertenenciaUP.startRequest({
        idEstablecimientoNew: values.idEstablecimientoNuevo[0].idEstablecimiento,
        idPersona: editData.idPersona,
        idEstablecimiento: Pertenencia.data[0].idEstablecimiento,
      });
      Pertenencia.startRequest({
        idPersona: editData.idPersona,
      });
    } else {
      await PertenenciaAD.startRequest({
        idEstablecimiento: values.idEstablecimiento[0].idEstablecimiento,
        idPersona: editData.idPersona,
      });
      Pertenencia.startRequest({
        idPersona: editData.idPersona,
      });
    }
    setActiveModal(false);
  };

  return (
    <div>
      <PacientFilter data={pacientSeleccionado} onChange={null} validateTelf={false} />
      <Divider dashed>Usuario</Divider>
      <Descriptions bordered>
        <Descriptions.Item label="Usuario">{editData.usuario}</Descriptions.Item>
        <Descriptions.Item label="Rol">{editData.rol}</Descriptions.Item>
        <Descriptions.Item label="Estado">{editData.estado ? "Activo" : "Inactivo"}</Descriptions.Item>
      </Descriptions>
      <Row justifyContent="space-between" margin={"10px 0"}>
        <Typography>Pertenencia</Typography>
        <Button
          onClick={() => {
            setActiveModal(true);
          }}
        >
          <Row gap="5px">
            <i className={`ri-${Pertenencia.data?.length > 0 ? "edit" : "add"}-line`} />
            {Pertenencia.data?.length > 0 ? "Cambiar pertenencia a unidad ejecutora" : "Agregar pertenencia a unidad ejecutora"}
          </Row>
        </Button>
      </Row>
      {Pertenencia.data?.map((p) => (
        <Descriptions bordered>
          <Descriptions.Item label="Categoria">{p?.categoria}</Descriptions.Item>
          <Descriptions.Item label="Diresa">{p?.diresa}</Descriptions.Item>
          <Descriptions.Item label="Red">{p?.red}</Descriptions.Item>
          <Descriptions.Item label="Direccion">{p?.direccion}</Descriptions.Item>
          <Descriptions.Item label="Codigo">{p?.codigoRenaes}</Descriptions.Item>
          <Descriptions.Item label="RENAES">{p?.nombre}</Descriptions.Item>
        </Descriptions>
      ))}

      <Modal
        centered
        footer={null}
        open={activeModal}
        onCancel={() => {
          setActiveModal(false);
        }}
        destroyOnClose
      >
        <Div width={"90%"}>
          <Formulario onSubmit={savePersonEstablishment}>
            <Formulario.FormControls
              inputs={
                Pertenencia.data?.length > 0
                  ? addDefaultValues(
                      generateMapForm([
                        EstablishmentDropdownDefinition,
                        {
                          ...EstablishmentDropdownDefinition,
                          name: "idEstablecimientoNuevo",
                          required: true,
                          customProps: {
                            ...EstablishmentDropdownDefinition.customProps,
                            required: true,
                            displayName: "Establecimiento nuevo",
                          },
                        },
                      ]),
                      { idEstablecimiento: Pertenencia.data[0].idEstablecimiento }
                    )
                  : generateMapForm([EstablishmentDropdownDefinition])
              }
            />
          </Formulario>
        </Div>
      </Modal>
    </div>
  );
};

export default UserView;
