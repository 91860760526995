import { Button, Card, Tour, message } from "antd";
import React, { useRef } from "react";
import { useToPng } from "@hugocxl/react-to-image";
import ExtraCardTotalChart from "core/components/atoms/ExtraCardTotalChart";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Column, Row } from "core/util-styled-components";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { FooterCard } from "../FooterCard";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);
const DefaultConfig = { labels: [], datasets: [] };

// const FooterCard = () => {
//   return (
//     <Row>
//       <Typography>
//         Fuente: <b>OEPI-DIRESA PIURA </b> | <b>SE 44-2023</b>
//       </Typography>
//     </Row>
//   );
// };
const onLoading = () => {
  message.loading({ content: "Generando imagen...", key: "toPng" });
};

const toJpeg = (data) => {
  message.destroy("toPng");
  const link = document.createElement("a");
  link.download = `segesgraffo-${new Date().getMilliseconds()}.jpeg`;
  link.href = data;
  link.click();
};
const CardGraphics = ({ type, optionsChart, dataAPI, staticVarName, maxHeight, open = false, setOpen, footerType = "PRINCIPAL", fuente = null }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const [_1, convert1, refGrafico1] = useToPng({
    quality: 0.95,
    onLoading,
    onSuccess: toJpeg,
  });

  const steps = [
    {
      title: "Contador total de variables",
      description: "Puedes ver el total de variables en esta sección.",
      target: () => ref1.current,
    },
    {
      title: "Grafico con datos de las variables",
      orientation: "right",
      cover: <img src="/CLICK_GRAF.png" alt="grafico" style={{ width: "180px", height: "70px" }} />,
      description: "Puedes ver el valor individual dando click o pasando por encima de cada punto o barra.",
      target: () => ref2.current,
    },
    {
      title: "Fuente de datos",
      description: "Puedes ver la fuente de datos y el año de los dato.",
      target: () => ref3.current,
    },
    {
      title: "Exportar imagen",
      description: "Finalmente puedes exportar la imagen dando click en el botón de la esquina superior derecha",
      target: () => ref4.current,
    },
  ];
  return (
    <Card
      ref={refGrafico1}
      id="grafico-1"
      style={{ width: "100%", maxWidth: "100%", minHeight: "250px" }}
      title={`${optionsChart.plugins.title.text.toUpperCase()}`}
      extra={
        isTabletOrMobile ? null : (
          <div ref={!isTabletOrMobile ? ref1 : null}>
            <ExtraCardTotalChart id="total" staticVarName={staticVarName} data={dataAPI.data} gap="10px" />
          </div>
        )
      }
    >
      {isTabletOrMobile && (
        <Column justifyContent="center" style={{ marginBottom: 20 }}>
          <div ref={isTabletOrMobile ? ref1 : null}>
            <ExtraCardTotalChart staticVarName={staticVarName} data={dataAPI.data} gap="10px" id="total" />
          </div>
        </Column>
      )}
      {type === "line" && (
        <div ref={type === "line" ? ref2 : null} id="chart">
          <Line
            style={{
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              minHeight: "250px",
              maxHeight: isTabletOrMobile ? "250px" : "auto",
            }}
            options={optionsChart}
            data={
              Array.isArray(dataAPI.data)
                ? DefaultConfig
                : {
                    labels: dataAPI.data?.labels,
                    datasets: dataAPI.data?.datasets,
                  }
            }
          />
        </div>
      )}
      {type === "bar" && (
        <div ref={type === "bar" ? ref2 : null} id="chart">
          <Bar
            style={{
              minWidth: "100%",
              width: "100%",
              maxWidth: "100%",
              minHeight: maxHeight,
              maxHeight: isTabletOrMobile ? maxHeight : "auto",
            }}
            options={optionsChart}
            data={
              Array.isArray(dataAPI.data)
                ? DefaultConfig
                : {
                    labels: dataAPI.data?.labels,
                    datasets: dataAPI.data?.datasets,
                  }
            }
          />
        </div>
      )}

      <Row width={"100%"} justifyContent="space-between" style={{ marginTop: "20px" }}>
        <Button onClick={convert1} ref={ref4} size="small" shape="circle" icon={<i className="ri-image-2-line" />}></Button>

        <div ref={ref3}>
          <FooterCard id="legend" type={footerType} fuente={fuente} />
        </div>
      </Row>

      <Tour
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        steps={steps}
      />
    </Card>
  );
};

export default CardGraphics;
