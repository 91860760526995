import { AUTH_ROUTE_PREFFIX } from "api/constants";
import { generateCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";
import { PersonDropdownDefinition } from "../Person";
import { RoleDropdownDefinition } from "../Role";

export interface User {
  idUsuario?: number;
  usuario?: string;
  contraseña?: string;
  idPersona?: number;
  nombreCompleto?: string;
  idRol?: number;
  rol?: string;
  estado?: number;
  avatar?: string;
}
// *API CONTEXT*
export const UserAPI = generateCrudApi(AUTH_ROUTE_PREFFIX + "user");
export const LoginAPIConfig = {
  method: "POST",
  url: `/api/v1/auth/user/login`,
  executeAtStart: false,
};

export const UserForm = generateMapForm([
  {
    name: "usuario",
    type: "input",
    customProps: {
      displayName: "Usuario",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "contraseña",
    type: "input",
    customProps: {
      displayName: "Contraseña",
      style: {
        maxHeight: "100px",
      },
    },
  },
  PersonDropdownDefinition,
  RoleDropdownDefinition,
  {
    name: "estado",
    type: "radio-button",
    optionsDropdown: [
      { name: "Activo", value: 1 },
      { name: "Inactivo", value: 0 },
    ],
    optionLabel: "name",
    optionValue: "value",
  },
  {
    name: "avatar",
    type: "input",
    customProps: {
      displayName: "URL Foto Perfil",
      spanCol: "span 2",
    },
  },
]);

export const UserDropdownDefinition: IInputPayload = {
  name: "idUsuario",
  type: "dropdown",
  optionsDropdown: [],
  optionLabel: "usuario",
  optionValue: "idUsuario",
  customProps: {
    listApiConfig: UserAPI.list,
    spanCol: "span 2",
    displayName: "Usuario",
    placeholder: "Selecciona un usuario",
  },
};
