import { Button, Card, Modal, Tag } from "antd";
import { NotificationListPregnatData, NotificationsActives } from "api/models/Notifications";
import useAxios from "core/hooks/useAxios";
import useSesionStorage from "core/hooks/useSesionStorage";
import { Column, Row, Typography } from "core/util-styled-components";
import { addHours, format } from "date-fns";
import React, { useEffect } from "react";
const ModalSesionStorageName = "@SEGESPPIURA/Notifications";

const NotificationModal = ({ open, onCancel }) => {
  const NotificationsActivesList = useAxios(NotificationsActives);
  // eslint-disable-next-line
  const NotificationsPregnatDataList = useAxios(NotificationListPregnatData);
  const [setContent] = useSesionStorage(ModalSesionStorageName);
  useEffect(() => {
    if (!NotificationListPregnatData.data?.length && !NotificationsActivesList.data?.length) {
      onCancel();
    }
    return () => {};
    // eslint-disable-next-line
  }, [NotificationsActivesList.data, NotificationListPregnatData.data]);

  return (
    <Modal title="Avisos" open={open} maskClosable={false} onCancel={onCancel} footer={null}>
      <Column gap="20px">
        {NotificationsActivesList.data?.map((el) => (
          <Card
            key={"modal-notification-" + el.idNotificacion}
            title={el.titulo}
            size="small"
            extra={<Tag color="cyan">Creado el día {format(addHours(new Date(el.fechaCreacion), 5), "dd/MM/yyyy")}</Tag>}
          >
            <Typography>{el.contenido}</Typography>
          </Card>
        ))}

        {NotificationsPregnatDataList.data?.map((el) => (
          <Card key={"pregnat-data-" + el.idFechaEstimadaParto} title={`${el.num_Doc} - Está próxima a dar a luz`}>
            <Typography>Estimada {el.fechaEstimadaParto}</Typography>
            <Typography>Ultima regla {el.fechaUltimaRegla}</Typography>
          </Card>
        ))}

        <Row justifyContent="flex-end" gap="10px">
          <Button
            type="link"
            onClick={() => {
              onCancel();
              setContent("true");
            }}
          >
            No volver a mostrar
          </Button>
          <Button
            onClick={() => {
              onCancel();
            }}
            type="primary"
          >
            Aceptar
          </Button>
        </Row>
      </Column>
    </Modal>
  );
};

export default NotificationModal;
