import { generateCrudApi, generateDropdown } from "api/generador";
import { HOST_API } from "core/hooks/host_api";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";

export interface Person {
  idPersona: number;
  apellidoPaterno?: string;
  apellidoMaterno?: string;
  nombres?: string;
  fechaNacimiento?: Date;
  nroDocumento: string;
  telefono?: string;
  direccionDomicilio?: string;
  idTipoSexo?: number;
  idGradoInstruccion?: number;
  idEstadoCivil?: number;
  idDocIdentidad?: number;
  idTipoOcupacion?: number;
  usoWebReniec?: boolean;
  email: string;
  nombreCompleto?: string;
}

export const PersonAPI = {
  ...generateCrudApi("person"),
  reniecService: {
    host_api: HOST_API,
    executeAtStart: false,
    url: "/api/v1/search-person-reniec/",
    method: "POST",
  },
};

export const PersonForm: IInputPayload[] = generateMapForm([
  {
    name: "idDocIdentidad",
    type: "radio-button",
    optionsDropdown: [
      { id: 1, name: "DNI" },
      { id: 2, name: "C.E." },
    ],
    optionLabel: "name",
    optionValue: "id",
    required: true,
    customProps: {
      required: true,
      displayName: "TIp. Documento",
    },
  },
  {
    name: "nroDocumento",
    type: "input",
    required: true,
    customProps: {
      required: true,
      displayName: "# Documento",
    },
  },
  {
    name: "apellidoPaterno",
    type: "input",
    required: true,
    customProps: {
      required: true,
      displayName: "Apellido paterno",
    },
  },
  {
    name: "apellidoMaterno",
    type: "input",
    required: true,
    customProps: {
      required: true,
      displayName: "Apellido materno",
    },
  },
  {
    name: "nombres",
    type: "input",
    required: true,
    customProps: {
      required: true,
      spanCol: "span 2",
      displayName: "Nombres",
    },
  },
  {
    name: "fechaNacimiento",
    type: "input",
    required: true,
    customProps: {
      required: true,
      type: "date",
      displayName: "Fecha de nacimiento",
    },
  },

  {
    name: "idTipoSexo",
    type: "radio-button",
    optionsDropdown: [
      { id: 1, name: "Masculino" },
      { id: 2, name: "Femenino" },
    ],
    optionLabel: "name",
    optionValue: "id",
    required: true,
    customProps: {
      required: true,
      displayName: "Sexo",
    },
  },
  {
    name: "telefono",
    type: "input",
    customProps: {
      displayName: "Telefono",
      spanCol: "span 2",
    },
  },
  // "idGradoInstruccion",
  // "idEstadoCivil",
  // "idTipoOcupacion",
  // "usoWebReniec",
  {
    name: "email",
    type: "input",
    customProps: {
      displayName: "Email",
      type: "email",
      spanCol: "span 2",
    },
  },
  {
    name: "direccionDomicilio",
    type: "input",
    customProps: {
      displayName: "Domicilio",
      spanCol: "span 2",
    },
  },
]);

export const PersonDropdownDefinition: IInputPayload = generateDropdown({
  name: "nombreCompleto",
  idName: "idPersona",
  description: "Persona",
  list: PersonAPI.list,
});
