import { EstablishmentForm, EstablishmentAPI } from ".";
import { EstablishmentTable } from "./table";

export const EstablishmentConfig = {
  id: 2,
  name: "Establishment",
  apiConfig: EstablishmentAPI,
  columns: EstablishmentTable,
  form: EstablishmentForm,
};
