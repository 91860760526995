import { FichaGestacionalAPI } from "api/models/FichaGestacional";
import useAxios from "core/hooks/useAxios";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { DropdownStyled } from "core/ts_formulario_model/src/App/Components/Molecules/FormControl/styled";
import { Column, Row, Typography } from "core/util-styled-components";
import React, { useEffect, useState } from "react";

export const RegionEESSSelector = ({
  setRegistroSeleccionado,
  defaultValues = {
    region: [],
    eess: [],
  },
}) => {
  const Data: any = useAxios(FichaGestacionalAPI.listRegionesYEESS);
  const [regionSeleccionada, setRegionSeleccionada] = useState(defaultValues.region);
  const [eessSeleccionado, setEessSeleccionado] = useState(defaultValues.eess);
  const { isTabletOrMobile } = useMediaQueryCustom();
  useEffect(() => {
    setRegistroSeleccionado({
      region: regionSeleccionada,
      eess: eessSeleccionado,
    });

    return () => {};
    // eslint-disable-next-line
  }, [regionSeleccionada, eessSeleccionado]);

  if (Data.loading) {
    return <Typography>Cargando, espere un momento...</Typography>;
  }

  return (
    <Row responsiveReorder>
      <Column style={{ width: isTabletOrMobile ? "100%" : "50%", alignItems: "flex-start" }}>
        <Typography>Departamento / Provincia / Distrito</Typography>
        <DropdownStyled
          style={{ minWidth: 300, width: "100%" }}
          options={Data.data.regiones?.map((el) => {
            return {
              ...el,
              regionEmoji: el.region.replaceAll("|", " ➡️ "),
            };
          })}
          values={regionSeleccionada}
          labelField="regionEmoji"
          valueField="region"
          onChange={(v) => setRegionSeleccionada(v)}
        />
      </Column>
      <Column style={{ width: isTabletOrMobile ? "100%" : "50%", alignItems: "flex-start" }}>
        <Typography>Establecimiento</Typography>
        <DropdownStyled
          style={{ minWidth: 300, width: "100%" }}
          options={Data.data.establecimientos ? Data.data.establecimientos[regionSeleccionada.length ? regionSeleccionada[0].region : ""] : []}
          values={eessSeleccionado}
          labelField="Nombre_Establecimiento"
          searchBy="Nombre_Establecimiento"
          valueField="Codigo_Unico"
          onChange={(v) => setEessSeleccionado(v)}
        />
      </Column>
    </Row>
  );
};
