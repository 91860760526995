import { Card } from "antd";
import CRUDPage from "core/components/organism/CRUDPage";
import { Column, Grid } from "core/util-styled-components";
import { GridChild } from "core/util-styled-components/components/Grid";
import { Tablas } from "api/crudPageAPI/admin";
import React, { useState } from "react";
import { theme } from "theme";

const Administrador = () => {
  const [tablaSelected, setTablaSelected] = useState(null);

  return (
    <Card
      bodyStyle={{ margin: 0, padding: 0, border: "none", minHeight: "86vh" }}
    >
      <Grid cols={12}>
        <GridChild span={3}>
          <Card
            title="Tablas"
            style={{
              borderTop: "none",
              borderBottom: "none",
              borderLeft: "none",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              minHeight: "86vh",
              cursor: "pointer",
            }}
            bodyStyle={{
              padding: 0,
              margin: 0,
            }}
          >
            <Column>
              {Tablas.map((el) => (
                <Card
                  size="small"
                  style={{
                    borderRadius: tablaSelected?.id === el.id ? 4 : 0,
                    border:
                      tablaSelected?.id === el.id
                        ? "solid 2px " + theme.colors.primary
                        : "dashed 2px #f3f3f3",
                    backgroundColor:
                      tablaSelected?.id === el.id
                        ? theme.colors.primary + "22"
                        : "transparent",
                  }}
                  onClick={() => setTablaSelected(el)}
                >
                  {el.name}
                </Card>
              ))}
            </Column>
          </Card>
        </GridChild>
        <GridChild span={9}>
          <CRUDPage
            title={`Lista de ${tablaSelected?.name}`}
            apiConfig={tablaSelected?.apiConfig}
            rows={tablaSelected?.columns}
            form={tablaSelected?.form}
            useDefaultActions
            relationShip={tablaSelected?.relationShip}
            apiConfigType={"ApiConfig"}
          />
        </GridChild>
      </Grid>
    </Card>
  );
};

export default Administrador;
