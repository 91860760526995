// import { Card, Radio, Typography } from "antd";
import { DashboardMaternalDeathsReportMatrixAPI } from "api/models/MaternalDeaths/api";
import { useLogged } from "core/hooks/loggedContext";
import useAxios from "core/hooks/useAxios";
import React, { useEffect, useState } from "react";
// import { Row } from "core/util-styled-components";
// import FilterRank from "./components/FilterRank";
// import DataView from "./components/DataView";
// import ChartView from "./components/ChartView";
// import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import DashboardMatrixBase from "../DashboardMatrixBase";
import { Theme } from "theme/styled";

const DashboardMatrix2 = ({ anio }) => {
  // const { isTabletOrMobile } = useMediaQueryCustom();
  const { metadata } = useLogged();
  const DashboardMatrix = useAxios(DashboardMaternalDeathsReportMatrixAPI);
  const [dropdownValues, setDropdownValues] = useState({});
  const [rank, setRank] = useState("");
  const getData = async () => {
    const data = await DashboardMatrix.startRequest({
      anio,
    });
    if (data && data.data && data.data.length) {
      setRank(0);
      setDropdownValues({
        [data.jerarquia[0]]: [data.data[0]],
      });
    }
  };

  const getDataEESS = async () => {
    console.log("getDataEESS");
    const data = await DashboardMatrix.startRequest({
      anio,
    });
    if (data && data.data && data.data.length) {
      setRank(data.jerarquia.length - 1);
      // generate dropdown values for each rank

      let _dropdownValues = {
        [data.jerarquia[0]]: [data.data[0]],
      };

      for (let i = 1; i < data.jerarquia.length; i++) {
        const rank = _dropdownValues[data.jerarquia[i - 1]];
        _dropdownValues = {
          ..._dropdownValues,
          [data.jerarquia[i]]: rank[0].data,
        };
      }

      console.log("dropdownValues", _dropdownValues);

      setDropdownValues(_dropdownValues);
    }
  };

  useEffect(() => {
    if (metadata.esDiresa === 0 && metadata.subregion === "0") {
      getDataEESS();
    } else {
      getData();
    }
    return () => {};
    // eslint-disable-next-line
  }, [metadata, anio]);

  return (
    <DashboardMatrixBase
      data={DashboardMatrix.data?.data}
      rank={rank}
      setRank={setRank}
      ranks={DashboardMatrix.data?.jerarquia}
      dropdownValues={dropdownValues}
      setDropdownValues={setDropdownValues}
      footerType="MUERTES MATERNAS"
      fuente={anio === "2023" ? "OEPI-DIRESA PIURA | SE 52-2023 - MUERTES MATERNAS POR OCURRENCIA" : null}
      cardConfig={[
        {
          name: "TOTAL",
          color: Theme.colors.primary,
          prop: "cantidad",
        },
      ]}
    />
  );
};

export default DashboardMatrix2;
