export const PregnatReportOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Gestantes con anemia",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          try {
            const dataIndex = context.dataIndex;
            const total = context.chart.config._config.data.datasets.filter((el) => el.label === "TOTAL")[0].data[dataIndex];

            // const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percent = Math.round((parseInt(context.raw) / total) * 100);
            return `${context.dataset.label}: ${context.formattedValue}\n (${percent}%)`;
          } catch (error) {
            return `${context.dataset.label}: ${context.formattedValue}\n`;
          }
        },
      },
    },
  },
  maintainAspectRatio: false,
};
export const PregnatReport2Options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Gestantes adolecentes",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          try {
            const dataIndex = context.dataIndex;
            const total = context.chart.config._config.data.datasets.filter((el) => el.label === "TOTAL")[0].data[dataIndex];

            // const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percent = Math.round((parseInt(context.raw) / total) * 100);
            return `${context.dataset.label}: ${context.formattedValue}\n (${percent}%)`;
          } catch (error) {
            return `${context.dataset.label}: ${context.formattedValue}\n`;
          }
        },
      },
    },
  },
  maintainAspectRatio: false,
};

export const PregnatReport4Options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Gest. Atendidas en el 1er Trim.",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          try {
            const dataIndex = context.dataIndex;
            const total = context.chart.config._config.data.datasets.filter((el) => el.label === "TOTAL")[0].data[dataIndex];

            // const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percent = Math.round((parseInt(context.raw) / total) * 100);
            return `${context.dataset.label}: ${context.formattedValue}\n (${percent}%)`;
          } catch (error) {
            return `${context.dataset.label}: ${context.formattedValue}\n`;
          }
        },
      },
    },
  },
  maintainAspectRatio: false,
};

export const PieConfig = {
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          try {
            // const dataIndex = context.dataIndex;
            // const total = context.chart.config._config.data.datasets.filter((el) => el.label === "TOTAL")[0].data[dataIndex];

            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percent = Math.round((parseInt(context.raw) / total) * 100);
            return `${context.dataset.label}: ${context.formattedValue}\n (${percent}%)`;
          } catch (error) {
            return `${context.dataset.label}: ${context.formattedValue}\n`;
          }
        },
      },
    },
  },
};
