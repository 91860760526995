import { generateCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { RiskFactorAPI } from "../RiskFactor";
import { HOST_API } from "core/hooks/host_api";

export interface Vigilance {
  idVigilancia: number;
  unidadEjecutora: string;
  distrito: string;
  ipress: string;
  Nombre_Establecimiento: string;
  idPersona: number;
  apellidoPaterno: string;
  apellidoMaterno: string;
  nombres: string;
  nroDocumento: string;
  edad: number;
  procedencia: string;
  fur: string;
  eg: string;
  idFactorRiesgo: number;
  factorRiesgo: string;
  idOtroFactorRiesgo: number;
  otroFactorRiesgo: string;
  referencia: string;
  ipressReferencia: number;
  fechaAtencionReferencia: string;
  observaciones: string;
  respuestaIPRESS: string;
  estado: number;
  idUsuario: number;
  color: string;
  Departamento: string;
  Distrito: string;
  Provincia: string;
  celular: string;
  celular2: string;
  correo: string;
  direccionDomicilio: string;
  nombreOtroFactorRiesgo: string;
}

export const VigilanceAPI = {
  ...generateCrudApi("vigilance"),
  vigilanceListByPregnatData: {
    method: "GET",
    url: `/api/v1/vigilance/list-version-pregnat-data`,
    executeAtStart: true,
    host_api: HOST_API,
  },
  history: {
    method: "POST",
    url: `/api/v1/vigilance/vigilance-history`,
    executeAtStart: false,
    host_api: HOST_API,
  },
  puerperios: {
    method: "POST",
    url: "/api/v1/vigilance/puerperio/list",
    executeAtStart: false,
    host_api: HOST_API,
  },
  terminosEmb: {
    method: "POST",
    url: "/api/v1/vigilance/termino-emb/list",
    executeAtStart: false,
    host_api: HOST_API,
  },
};

export const VigilanceVariablesList = {
  method: "POST",
  url: `/api/v1/vigilance/variables/list`,
  executeAtStart: false,
  host_api: HOST_API,
};

export const VigilanceFormPart1 = (isTabletOrMobile): IInputPayload[] => [
  {
    name: "idVigilancia",
    type: "input",
    customProps: {
      displayName: "",
      gap: "0px",
      style: {
        display: "none",
      },
    },
  },
  {
    name: "Num_doc",
    type: "input",
    customProps: {
      displayName: "",
      gap: "0px",

      style: {
        display: "none",
      },
    },
  },
  {
    name: "unidadEjecutora",
    type: "input",
    customProps: {
      displayName: "",
      gap: "0px",
      style: {
        display: "none",
      },
    },
  },
  {
    name: "distrito",
    type: "input",
    customProps: {
      displayName: "",
      gap: "0px",
      style: {
        display: "none",
      },
    },
  },
  {
    name: "ipress",
    type: "input",
    customProps: {
      displayName: "",
      gap: "0px",
      style: {
        display: "none",
      },
    },
  },
  {
    name: "Datos de la paciente",
    type: "title-separator",
    customProps: {
      orientation: "center",
    },
  },
];
export const VigilanceFormPart2 = (isTabletOrMobile): IInputPayload[] => [
  {
    name: "celular",
    type: "input",
    customProps: {
      type: "number",
      gap: "0px",
      displayName: "Celular",
    },
  },
  {
    name: "celular2",
    type: "input",
    customProps: {
      type: "number",
      gap: "0px",
      displayName: "Celular 2",
    },
  },
  {
    name: "correo",
    type: "input",
    customProps: {
      gap: "0px",
      type: "email",
      displayName: "Correo",
    },
  },
];

export const VigilanceForm_v2 = (isTabletOrMobile): IInputPayload[] => [
  {
    name: "Datos de la paciente",
    type: "title-separator",
    customProps: {
      orientation: "center",
    },
  },
  {
    name: "unidadEjecutora",
    type: "input",
    disabled: true,
    customProps: {
      displayName: "Unidad ejecutora",
      disabled: true,
      gap: "0px",
    },
  },
  {
    name: "distrito",
    type: "input",
    disabled: true,
    customProps: {
      displayName: "Distrito",
      disabled: true,
      gap: "0px",
    },
  },
  {
    name: "ipress",
    type: "input",
    disabled: true,
    customProps: {
      displayName: "IPRESS",
      gap: "0px",
    },
  },
  {
    name: "Num_doc",
    type: "input",
    required: true,
    disabled: true,
    customProps: {
      displayName: "# Documento",
      required: true,
      gap: "0px",
    },
  },
];

export const VigilanceForm_v2_2 = (isTabletOrMobile): IInputPayload[] => [
  {
    name: "celular",
    type: "input",
    customProps: {
      type: "number",
      gap: "0px",
      displayName: "Celular",
    },
  },
  {
    name: "celular2",
    type: "input",
    customProps: {
      type: "number",
      gap: "0px",
      displayName: "Celular 2",
    },
  },
  {
    name: "correo",
    type: "input",
    customProps: {
      gap: "0px",
      type: "email",
      displayName: "Correo",
    },
  },
  {
    name: "fur",
    type: "input",
    customProps: {
      type: "date",
      gap: "0px",
      displayName: "Fecha ultima regla",
    },
  },
  {
    name: "Datos de vigilancia",
    type: "title-separator",
    customProps: {
      orientation: "center",
    },
  },
  {
    name: "idFactorRiesgo",
    type: "dropdown",
    optionsDropdown: [],
    optionLabel: "descripcion",
    optionValue: "idFactorRiesgo",
    required: true,
    fullWidth: true,
    customProps: {
      required: true,
      listApiConfig: RiskFactorAPI.list,
      displayName: "Factor de riesgo",
      gap: "0px",
      placeholder: "Selecciona un factor de riesgo",
    },
  },

  {
    name: "observaciones",
    type: "input-description",
    fullWidth: true,
    customProps: {
      displayName: "Observaciones",
      gap: "0px",
    },
  },
];
