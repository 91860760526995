import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Div, Heading, Typography } from "core/util-styled-components";
import FormControls from "../FormControls";

export default function Formulario({ children, style, ...restProps }: any) {
  const methods = useForm();
  return (
    <Div width={restProps.width || "100%"} margin="auto" padding="1.5rem" style={style}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(restProps.onSubmit)}>{children}</form>
      </FormProvider>
    </Div>
  );
}

Formulario.Title = ({ children, centered, style, ...rest }: any) => (
  <Heading variant="h2" style={{ textAlign: centered ? "center" : "start", ...style }} {...rest}>
    {children}
  </Heading>
);

Formulario.FormDescription = ({ children }: any) => <Typography>{children}</Typography>;

Formulario.FormControls = FormControls;
