import { Modal, Steps, message } from "antd";
import PacientSearch from "components/organisms/PacientSearch";
import React, { useState } from "react";
import CambioSeguroForm from "./CambioSeguroForm";
import useAxios from "core/hooks/useAxios";
import { HOST_API } from "core/hooks/host_api";
import { FichaGestacionalAPI } from "api/models/FichaGestacional";
import { PacientDocs } from "api/models/Pacient/data";

const AgregarEditarCambioSeguro = ({ active, onCancel, onSuccess, defaultValue }) => {
  const AgregarMigracion = useAxios(FichaGestacionalAPI.add);
  const ModificarMigracion = useAxios(FichaGestacionalAPI.update);
  const [registroSeleccionado, setRegistroSeleccionado] = useState(defaultValue);
  const [current, setCurrent] = useState(0);
  const [paciente, setPaciente] = useState(null);

  const UpdateInsert = useAxios({
    host_api: HOST_API,
    executeAtStart: false,
    url: "/api/v1/vigilance/insert-update-pacient-vigilance",
    method: "POST",
  });

  const agregaActualizaPacienteCtrl = async () => {
    const tipo = paciente?.idDocIdentidad;
    const id = PacientDocs.find((x) => x.Abrev_Tipo_Doc === tipo)?.Id_Tipo_Documento;
    const response = await UpdateInsert.startRequest({
      ...paciente,
      idDocIdentidad: id,
    });
    if (response.error) {
      message.error(response.message);
    } else {
      response.message && message.success(response.message);
    }
  };

  const onOk = async (data) => {
    await agregaActualizaPacienteCtrl();
    if (registroSeleccionado?.idRegistro) {
      await actualizarMigracionCtrl(data);
    } else {
      await agregarMigracionCtrl(data);
    }
  };

  const actualizarMigracionCtrl = async (params) => {
    const response = await ModificarMigracion.startRequest(params);
    if (response.check) {
      message.success("Se ha modificado el cambio de seguro");
      onSuccess();
    } else {
      message.error("No se ha modificado ninguna el cambio de seguro");
    }
  };
  const agregarMigracionCtrl = async (params) => {
    if (!paciente.nroDocumento) {
      return message.error("Seleccione un paciente");
    }
    if (paciente?.idTipoSexo === 1) {
      return message.error("Paciente no puede ser masculino");
    }
    const response = await AgregarMigracion.startRequest(params);
    if (response.check) {
      message.success("Se ha guardado un cambio de seguro");
      onCancel();
    } else {
      message.error("No se ha guardado ninguna cambio de seguro");
    }
  };

  return (
    <Modal
      title={`${registroSeleccionado?.idRegistro ? "Editar" : "Nueva"} cambio de seguro`}
      open={active}
      onCancel={() => {
        onCancel();
        setRegistroSeleccionado(null);
      }}
      footer={null}
      width={"95%"}
      bodyStyle={{
        padding: "0 2em 0 0",
      }}
      destroyOnClose
      centered
    >
      <Steps type="navigation" current={current} onChange={(nav) => setCurrent(nav)} items={[{ title: "Seleccione un paciente" }, { title: "Datos del cambio de seguro" }]}></Steps>
      {current === 0 ? (
        <PacientSearch
          onOk={(paciente) => {
            setPaciente(paciente);
            setCurrent(1);
          }}
          defaultSearch={
            registroSeleccionado
              ? {
                  nroDocumento: registroSeleccionado?.nroDocumento,
                }
              : null
          }
        />
      ) : null}
      {current === 1 ? <CambioSeguroForm onOk={onOk} paciente={paciente} registroSeleccionado={registroSeleccionado} /> : null}
    </Modal>
  );
};

export default AgregarEditarCambioSeguro;
