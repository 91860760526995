import { PacientDocs } from "api/models/Pacient/data";

export const _form_busqueda = [
  {
    name: "idDocIdentidad",
    type: "dropdown",
    optionsDropdown: PacientDocs,
    optionLabel: "Abrev_Tipo_Doc",
    optionValue: "Abrev_Tipo_Doc",
    required: true,
    customProps: {
      spanCol: "span 3",
      required: true,
      displayName: "Tip. Documento",
    },
  },
  {
    name: "nroDocumento",
    type: "input",
    required: true,
    customProps: {
      spanCol: "span 9",
      displayName: "Nro. Documento",
      required: true,
      // disabled: true,
    },
  },
];

export const _form_paciente = [
  {
    name: "idDocIdentidad",
    type: "dropdown",
    optionsDropdown: PacientDocs,
    optionLabel: "Abrev_Tipo_Doc",
    optionValue: "Abrev_Tipo_Doc",
    required: true,
    customProps: {
      required: true,
      displayName: "Tip. Documento",
    },
  },
  {
    name: "nroDocumento",
    type: "input",
    required: true,
    customProps: {
      displayName: "Nro. Documento",
      required: true,
      // disabled: true,
    },
  },
  {
    name: "edad",
    type: "input",
    customProps: {
      type: "number",
      displayName: "Edad",
    },
  },
  {
    name: "apellidoPaterno",
    type: "input",
    customProps: {
      displayName: "Apellido paterno",
    },
  },
  {
    name: "apellidoMaterno",
    type: "input",
    customProps: {
      displayName: "Apellido materno",
    },
  },
  {
    name: "nombres",
    type: "input",
    customProps: {
      displayName: "Nombres",
    },
  },
  {
    name: "fechaNacimiento",
    type: "input",
    customProps: {
      type: "date",
      displayName: "Fecha de nacimiento",
    },
  },
  {
    name: "idTipoSexo",
    type: "radio-button",
    optionsDropdown: [
      { name: "Masculino", value: 1 },
      { name: "Femenino", value: 2 },
    ],
    optionLabel: "name",
    optionValue: "value",
    customProps: {
      displayName: "Sexo",
    },
  },
  {
    name: "direccionDomicilio",
    type: "input",
    customProps: {
      displayName: "Dirección",
    },
  },
  {
    name: "celular",
    type: "input",
    customProps: {
      displayName: "Celular 1",
    },
  },
  {
    name: "celular2",
    type: "input",
    customProps: {
      displayName: "Celular 2",
    },
  },
  {
    name: "correo",
    type: "input",
    customProps: {
      displayName: "Correo",
      type: "email",
    },
  },
];
