import React, { useState, useEffect, useMemo } from "react";
import useLocalStorage from "./useLocalStorage";
import { Row, Spin } from "antd";
import { fetchPost } from "core/utils/simpleAxios";
const LocalStorage = "@login/seggesppiura";

const LoggedContext = React.createContext();
export function LoggedProvider(props) {
  const [isLogged, setIsLogged] = useState(true);

  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [setLogin, getLogin] = useLocalStorage(LocalStorage);

  useEffect(() => {
    recuperarUsuario();
    return () => {};
    //eslint-disable-next-line
  }, []);
  const recuperarUsuario = async () => {
    setLoading(true);
    const store = getLogin();
    if (store && store.login) {
      const response = await fetchPost(
        "/api/v1/auth/user/metadata",
        null,
        store?.store
      );

      console.log(response.data);

      if (response.status < 400) {
        setMetadata({
          ...response.data,
          store: store.store,
        });
        setIsLogged(true);
      } else {
        setIsLogged(false);
        logout();
      }
    }
    setLoading(false);
  };

  const login = async ({ SSID, username }) => {
    try {
      setLogin({
        login: true,
        store: SSID,
        username,
      });
      recuperarUsuario();
    } catch (error) {}
  };

  useEffect(() => {
    setIsLogged(!!metadata);

    return () => {};
  }, [metadata]);

  const logout = () => {
    localStorage.removeItem(LocalStorage);
    sessionStorage.clear();
    setMetadata(null);
    setIsLogged(false);
  };

  const value = useMemo(() => {
    return {
      login,
      logout,
      metadata,
      isLogged,
      // validacionREFCON,
    };
    //eslint-disable-next-line
  }, [metadata, isLogged]);

  if (!loading) {
    return <LoggedContext.Provider value={value} {...props} />;
  }
  return (
    <Row>
      <Spin />
      <span>Cargando datos del paciente...</span>
    </Row>
  );
}
export function useLogged() {
  const context = React.useContext(LoggedContext);
  if (!context) {
    throw new Error("No se encontro Usuario en el contexto");
  }
  return context;
}
