import { ModuleRouteForm, ModuleRouteAPI } from ".";
import { ModuleRouteTable } from "./table";

export const ModuleRouteConfig = {
  id: 5,
  name: "ModuleRoute",
  apiConfig: ModuleRouteAPI,
  columns: ModuleRouteTable,
  form: ModuleRouteForm,
};
