import { generateCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
export const RiskFactorAPI = generateCrudApi("risk-factor");

export const RiskFactorDropdownDefinition: IInputPayload = {
  name: "idFactorRiesgo",
  type: "dropdown",
  optionsDropdown: [],
  optionLabel: "descripcion",
  optionValue: "idFactorRiesgo",
  customProps: {
    listApiConfig: RiskFactorAPI.list,
    spanCol: "span 2",
    displayName: "Factor de riesgo",
    placeholder: "Selecciona un factor de riesgo",
  },
};
