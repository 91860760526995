import { Button, Card, Modal, Tabs } from "antd";
import { EstimatedDate, ListByDNIAPI } from "api/models/Pregnat/api";
import CRUDPage from "core/components/organism/CRUDPage";
import useAxios from "core/hooks/useAxios";
import { Row, Typography } from "core/util-styled-components";
import { addDefaultValues } from "core/utils/form";
import { generateMapForm, generateMapTable } from "core/utils/tables";
import { calcularFechaProbableYEdadGestacional } from "core/utils/util.core";
import { addHours, format } from "date-fns";
import React, { useState } from "react";

const DocumentRender = ({ dni }: { dni: string }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const List = useAxios(ListByDNIAPI);

  const onClick = () => {
    setModalVisible(true);
    List.startRequest({
      nroDocumento: dni,
    });
  };

  return (
    <div>
      <Button onClick={onClick} type="link" size="small">
        {dni}
      </Button>
      <Modal width={"80%"} open={modalVisible} onCancel={() => setModalVisible(false)} footer={null} destroyOnClose centered title="Detalle del paciente">
        <Tabs>
          <Tabs.TabPane key="det-key-1" tab="Paridad">
            {List.data.map((d) => {
              return (
                <Card title={`${d.Tipo_Doc_Madre}: ${d.NU_DOC_MADRE}`}>
                  <Row responsiveReorder gap="10px">
                    <strong>Nacimiento 1:</strong>
                    <Typography>{d.Lugar_Nacido1 || "--"}</Typography>
                    <Typography>{d.Condicion_Parto1 || "--"}</Typography>
                    <Typography>{d.FE_NACIDO1 || "--"}</Typography>
                  </Row>
                  <Row responsiveReorder gap="10px">
                    <strong>Nacimiento 2:</strong>
                    <Typography>{d.Lugar_Nacido2 || "--"}</Typography>
                    <Typography>{d.Condicion_Parto2 || "--"}</Typography>
                    <Typography>{d.FE_NACIDO2 || "--"}</Typography>
                  </Row>
                  <Row responsiveReorder gap="10px">
                    <strong>Nacimiento 3:</strong>
                    <Typography>{d.Lugar_Nacido3 || "--"}</Typography>
                    <Typography>{d.Condicion_Parto3 || "--"}</Typography>
                    <Typography>{d.FE_NACIDO3 || "--"}</Typography>
                  </Row>
                </Card>
              );
            })}
          </Tabs.TabPane>

          <Tabs.TabPane key="det-key-2" tab="Fecha probable de parto">
            <CRUDPage
              title="Estimacion de partos"
              apiConfig={{
                ...EstimatedDate,
                list: {
                  method: "GET",
                  url: `/api/v1/pregnat/estimated-date/list/${dni}`,
                  executeAtStart: true,
                },
              }}
              rows={generateMapTable([
                {
                  title: "Año",
                  dataIndex: "anio",
                },
                {
                  title: "N° Documento",
                  dataIndex: "nroDocumento",
                },
                {
                  title: "Ultima regla",
                  dataIndex: "fechaUltRegla",
                  render: (f) => format(addHours(new Date(f), 5), "dd/MM/yyyy"),
                },
                {
                  title: "Estimación del parto",
                  dataIndex: "fechaUltRegla",
                  render: (f) => calcularFechaProbableYEdadGestacional(format(addHours(new Date(f), 5), "yyyy-MM-dd")).fechaProbableParto,
                },
                {
                  title: "Estado",
                  dataIndex: "estado",
                  render: (f) => (f ? "Activo" : "Inactivo"),
                },
              ])}
              searchFields={["nroDocumento"]}
              customDesignProps={{
                formCols: 2,
              }}
              height="auto"
              form={addDefaultValues(
                generateMapForm([
                  {
                    name: "anio",
                    type: "input",
                    required: true,
                    customProps: {
                      required: true,
                      displayName: "Año",
                    },
                  },
                  {
                    name: "nroDocumento",
                    type: "input",
                    required: true,
                    customProps: {
                      displayName: "DNI",
                      disabled: true,
                      required: true,
                    },
                  },
                  {
                    name: "fechaUltRegla",
                    type: "input",
                    required: true,
                    customProps: {
                      displayName: "Ultima regla",
                      type: "date",
                      required: true,
                    },
                  },

                  {
                    name: "estado",
                    type: "radio-button",
                    optionsDropdown: [
                      { id: 1, name: "Activo" },
                      { id: 2, name: "Inactivo" },
                    ],
                    optionValue: "id",
                    optionLabel: "name",
                    customProps: {
                      displayName: "Estado",
                      required: true,
                    },
                  },
                ]),
                {
                  anio: new Date().getFullYear(),
                  nroDocumento: dni,
                  estado: 1,
                }
              )}
              useDefaultActions
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default DocumentRender;
