import { notification } from "antd";
import Axios from "axios";
import { HOST_API } from "core/hooks/host_api";
import { saveAs } from "file-saver";
const baseUrl = HOST_API;
export interface Props {
  informe: any;
}

export const InformePDF = ({ informe }: Props) => {
  notification.info({
    message: "Descargando archivo espere un momento",
  });
  Axios.post(`${baseUrl}/api/v1/ficha-gestacional/exportar/create-pdf`, informe)
    .then(() =>
      Axios.get(`${baseUrl}/api/v1/ficha-gestacional/exportar/fetch-pdf`, {
        responseType: "blob",
      })
    )
    .then((res) => {
      Axios.get(`${baseUrl}/api/v1/ficha-gestacional/exportar/remove-pdf`);
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, `Seguimiento gestante - informe.pdf`);
    });
};
