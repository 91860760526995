import { useEffect, useState } from "react";
import AxiosTokenProvider from "axios-token-interceptor";
import Axios from "axios";
import { HOST_API } from "./host_api";
import { useLogged } from "./loggedContext";

const defaultConfig = {
  method: "GET",
  host_api: HOST_API,
  executeAtStart: false,
  url: "",
};

function useAxios(config = defaultConfig) {
  const [apiConfig, setApiConfig] = useState(config);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { metadata } = useLogged();

  const resetQuery = () => {
    setData([]);
    setError(null);
    setLoading(false);
    setServerResponse(null);
  };

  const createAxiosInstance = () => {
    const instance = Axios.create({ baseURL: apiConfig.host_api || HOST_API });
    if (metadata?.store) {
      instance.interceptors.request.use(
        AxiosTokenProvider({
          getToken: () => metadata.store,
        })
      );
    }
    return instance;
  };

  const sendRequest = async (instance, parameters) => {
    try {
      const response = await (apiConfig.method === "POST" ? instance.post(apiConfig.url || "/", parameters) : instance.get(apiConfig.url || "/"));
      setServerResponse(200);
      setData(response.data);
      setLoading(false);
      console.log(response.data);
      return response.data;
    } catch (error) {
      setServerResponse(400);
      setData([]);
      setLoading(false);
      setError(error);
      throw new Error("Error in data service");
    }
  };

  const startRequest = async (parameters) => {
    if (requestInProgress) {
      return;
    }

    resetQuery();
    setLoading(true);
    setRequestInProgress(true);

    const axiosInstance = createAxiosInstance();
    try {
      const response = await sendRequest(axiosInstance, parameters);
      setData(response);
      return response;
    } catch (e) {
      setLoading(false);
      setRequestInProgress(false);
      return null;
    } finally {
      setLoading(false);
      setRequestInProgress(false);
    }
  };

  const updateApiConfig = (_apiConfig, executeAtUpdate = true) => {
    resetQuery();
    setApiConfig({
      ..._apiConfig,
      executeAtUpdate,
    });
  };

  useEffect(() => {
    if (apiConfig.executeAtStart) {
      startRequest(apiConfig.parameters);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (apiConfig.executeAtStart && apiConfig.executeAtUpdate) {
      startRequest(apiConfig.parameters);
    }
    // eslint-disable-next-line
  }, [apiConfig]);

  return {
    data,
    loading,
    error,
    setData,
    startRequest,
    serverResponse,
    updateApiConfig,
    apiConfig,
  };
}

export const ValidateAxiosData = (data) => Array.isArray(data) && data.length > 0;

export const ValidateAxiosDataWithData = (data) => !Array.isArray(data) && Array.isArray(data.data) && data.data.length > 0;

export default useAxios;
