import { Table } from "antd";
import { FichaGestacionalAPI } from "api/models/FichaGestacional";
import useAxios from "core/hooks/useAxios";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import React, { useEffect } from "react";

const ListaATPPD = ({ documento }) => {
  const atpList = useAxios(FichaGestacionalAPI.atpList);

  const getAtpData = async () => {
    await atpList.startRequest({
      dni: documento,
    });
  };

  useEffect(() => {
    if (documento) {
      getAtpData();
    }

    return () => {};
    // eslint-disable-next-line
  }, [documento]);

  return (
    <Table
      size="small"
      dataSource={atpList.data.sort((a: any, b: any) => new Date(b.Fecha_atencion).getTime() - new Date(a.Fecha_atencion).getTime())}
      columns={generateMapTable([{ dataIndex: "Fecha_atencion", render: (val) => format(addHours(new Date(val), 5), "dd/MM/yyyy") }, { dataIndex: "Nombre_Establecimiento" }, "Variables"])}
    />
  );
};

export default ListaATPPD;
