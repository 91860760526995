import React, { useState, useEffect } from "react";
import Container from ".";
import { Column, Heading, Row, Typography } from "core/util-styled-components";
import Logo from "core/components/atoms/Logo";
import { Alert, Avatar, Button, Card, Collapse, Drawer, Modal, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogged } from "core/hooks/loggedContext";
import { theme } from "theme";
import styled from "styled-components";
import useSesionStorage from "core/hooks/useSesionStorage";
import NotificationModal from "core/components/molecules/NotificationModal";
import { Formulario } from "core/ts_formulario_model";
import useAxios from "core/hooks/useAxios";
import { LoginAPIConfig } from "api/models/User";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";

export const Panel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const CardMenu = styled(Card)`
  .ant-card {
    border: none;
    border-radius: none !important;
  }
`;

const ModalSesionStorageName = "@SEGESPPIURA/Notifications";

const Layout = ({ children }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const nav = useNavigate();
  const [loginModalActive, setLoginModalActive] = useState(false);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [setContent, getContent] = useSesionStorage(ModalSesionStorageName);
  const { login, isLogged, metadata, logout } = useLogged();

  useEffect(() => {
    const active = getContent();
    if (!Boolean(active)) {
      setOpenModalNotification(true);
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLogged) {
      setContent("true");
      // nav("/Login");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);
  const LoginPaciente = useAxios(LoginAPIConfig);

  const onLoginForm = async (data) => {
    const response = await LoginPaciente.startRequest(data);
    if (response.error) {
      message.error(response.message);
    } else {
      message.success(response.message);
      login({ SSID: response.token, username: data.username });
    }
  };

  return (
    <Container>
      <Column gap=".5em">
        <Card size="small">
          <Row justifyContent="space-between">
            <Logo variant="h3" contentWidth={"auto"} subtext={isTabletOrMobile ? "" : "Seguimiento de gestante y puerpera"} />
            {isLogged ? (
              <Row gap=".5em">
                <Typography>Ir a: ↗️</Typography>
                <Button
                  shape="circle"
                  type="primary"
                  size="middle"
                  icon={<i className="ri-menu-line" />}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </Row>
            ) : (
              <Button
                onClick={() => {
                  setLoginModalActive(true);
                }}
              >
                Iniciar sesión
              </Button>
            )}
          </Row>
        </Card>
        <div>{children}</div>
        <Drawer
          // title="Seleccione una opción"
          placement={"left"}
          closable={false}
          onClose={() => {
            setOpen(false);
          }}
          open={open}
          key={"drawer-op-1"}
          bodyStyle={{
            padding: 0,
          }}
        >
          <Card style={{ borderRadius: 0 }} size="small">
            <Row justifyContent="space-between">
              <Row gap="10px">
                <Avatar
                  style={{
                    backgroundColor: theme.colors.primary,
                  }}
                  icon={<i className="ri-user-line" />}
                />
                <Column>
                  <Typography style={{ fontWeight: "bold" }}>{metadata?.usuario}</Typography>
                  <Typography variant="label">Activo(a) ahora</Typography>
                </Column>
              </Row>
              <Button
                size="small"
                onClick={() => {
                  logout();
                  nav("/");
                }}
              >
                Salir
              </Button>
            </Row>
          </Card>
          <Column style={{ padding: "1em .4em" }} gap={"5px"}>
            <Heading style={{ margin: "0 0 10px 0" }}>
              <i className="ri-pushpin-2-line" /> {metadata?.Nombre_Establecimiento || "-----"}
            </Heading>
            {Array.isArray(metadata?.drawerOptions) &&
              metadata?.drawerOptions.map((el) =>
                el.submenus ? (
                  <Collapse
                    size="small"
                    onClick={() => {
                      nav(el.link);
                    }}
                    style={{ borderRadius: 0, border: "none" }}
                  >
                    <Panel
                      header={<Typography>{el.modulo}</Typography>}
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      <Column gap="0px">
                        {el.submenus?.map((submenu) => (
                          <CardMenu
                            size="small"
                            onClick={() => {
                              setOpen(false);
                              nav(submenu.link);
                            }}
                            style={{
                              borderRadius: pathname === submenu.link ? 4 : 0,
                              border: pathname === submenu.link ? "solid 2px " + theme.colors.primary : "dashed 2px transparent",
                              backgroundColor: pathname === submenu.link ? theme.colors.primary + "22" : "transparent",
                            }}
                          >
                            <Row justifyContent="space-between">
                              <Row gap={"5px"}>
                                <i className={submenu.icon} />
                                <Typography>{submenu.modulo}</Typography>
                              </Row>
                              <i className="ri-arrow-right-s-line" />
                            </Row>
                          </CardMenu>
                        ))}
                      </Column>
                    </Panel>
                  </Collapse>
                ) : (
                  <CardMenu
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      nav(el.link);
                    }}
                    style={{
                      borderRadius: pathname === el.link ? 4 : 0,
                      border: pathname === el.link ? "solid 2px " + theme.colors.primary : "dashed 2px transparent",
                      backgroundColor: pathname === el.link ? theme.colors.primary + "22" : "transparent",
                    }}
                  >
                    <Row justifyContent="space-between">
                      <Typography>{el.modulo}</Typography>
                      <i className="ri-arrow-right-s-line" />
                    </Row>
                  </CardMenu>
                )
              )}
          </Column>
        </Drawer>
      </Column>
      {isLogged && <NotificationModal open={openModalNotification} onCancel={() => setOpenModalNotification(false)} />}
      <Modal
        open={loginModalActive}
        onCancel={() => {
          setLoginModalActive(false);
        }}
        footer={null}
        title="Iniciar sesión"
      >
        <Formulario onSubmit={onLoginForm} style={{ width: "90%" }}>
          <div style={{ margin: "0 0 25px 0" }}>
            <Alert type="info" message="Asegurese de tener un usuario y contraseña."></Alert>
          </div>
          <Formulario.FormControls
            cols={1}
            inputs={[
              {
                name: "username",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                  prefix: <i className="ri-user-line" />,
                  displayName: "Usuario",
                },
              },
              {
                name: "password",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                  type: "password",
                  prefix: <i className="ri-key-line" />,
                  displayName: "Contraseña",
                },
              },
            ]}
            submitLabel={
              <Typography color="inherit">
                <i className="ri-lock-line" /> Iniciar sesión
              </Typography>
            }
            buttonProps={{
              cancel: {
                style: { display: "none" },
              },
              submit: {
                size: "large",
                style: { width: "100%" },
              },
            }}
          ></Formulario.FormControls>
        </Formulario>
      </Modal>
    </Container>
  );
};

export default Layout;
