import { RolModuleForm, RolModuleAPI } from ".";
import { RolModuleTable } from "./table";

export const RolModuleConfig = {
  id: 10,
  name: "RolModule",
  apiConfig: RolModuleAPI,
  columns: RolModuleTable,
  form: RolModuleForm,
};
