import { PacientDocs } from "api/models/Pacient/data";
import { calculateAge } from "./_util";

export const transformReniecToFormData = (response) => {
  return {
    ...response,
    edad: calculateAge(response?.fechaNacimiento),
    idTipoSexo: response?.genero === "MASCULINO" ? 1 : 2,
    direccionDomicilio: `${response?.direccion} | ${response?.pais} - ${response?.departamento} - ${response?.distrito} - ${response?.provincia}`,
    nroDocumento: response?.dni,
    idDocIdentidad: PacientDocs.filter((tip) => tip.Id_Tipo_Documento === 1),
  };
};

export const transformLocalData = (response) => {
  return {
    ...response,
    edad: calculateAge(response?.fechaNacimiento),
    idDocIdentidad: PacientDocs.filter((tip) => tip.Id_Tipo_Documento === 1),
  };
};
