import { Card, Tag } from "antd";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Grid, Row, Typography } from "core/util-styled-components";
import { GridChild } from "core/util-styled-components/components/Grid";
import React, { useState, useEffect } from "react";

export const TotalCard = ({ dataSet, cardConfig, cardOrientation, onCardClick }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <Card
      size="small"
      hoverable={Boolean(onCardClick)}
      bodyStyle={
        isTabletOrMobile
          ? {
              padding: "8px",
            }
          : {}
      }
    >
      <Row gap="5px" justifyContent="space-between">
        <Typography
          variant={isTabletOrMobile ? "label" : null}
          style={{
            fontWeight: "500",
            // width: isTabletOrMobile ? "100%" : "300px",
            maxWidth: "100%",
          }}
        >
          TOTAL
        </Typography>{" "}
        <Row
          responsiveReorder
          gap="5px"
          style={{
            minWidth: isTabletOrMobile ? "auto" : "200px",
            flexDirection: cardOrientation,
          }}
        >
          {cardConfig.map((el) => (
            <Tag
              color={el.color}
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Row justifyContent="center" gap={"5px"}>
                <Typography variant="label" color="inherit">
                  {el.name}
                </Typography>
                <Typography color="inherit" style={{ fontWeight: "800" }}>
                  {dataSet?.reduce((prev, acc) => prev + acc[el.prop], 0)}
                </Typography>
              </Row>
            </Tag>
          ))}
        </Row>
      </Row>
    </Card>
  );
};

const ThisCard = ({ data, name, cardConfig, rankName, cardOrientation, onCardClick }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <Card
      size="small"
      hoverable={Boolean(onCardClick)}
      bodyStyle={
        isTabletOrMobile
          ? {
              padding: "8px",
            }
          : {}
      }
      onClick={() => {
        if (typeof onCardClick === "function") {
          onCardClick({ rankName, name, data });
        }
      }}
    >
      <Row gap="5px" justifyContent="space-between">
        <Typography
          variant={isTabletOrMobile ? "label" : null}
          style={{
            fontWeight: "500",
            // width: isTabletOrMobile ? "100%" : "300px",
            maxWidth: "100%",
          }}
        >
          {name}
        </Typography>{" "}
        <Row
          responsiveReorder
          gap="5px"
          style={{
            minWidth: isTabletOrMobile ? "auto" : "200px",
            flexDirection: cardOrientation,
          }}
        >
          {cardConfig.map((el) => (
            <Tag
              color={el.color}
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <Row justifyContent="center" gap={"5px"}>
                <Typography variant="label" color="inherit">
                  {el.name}
                </Typography>
                <Typography color="inherit" style={{ fontWeight: "800" }}>
                  {data[el.prop]}
                </Typography>
              </Row>
            </Tag>
          ))}
        </Row>
      </Row>
    </Card>
  );
};

const DataView = ({ data, dataSet, rank, ranks, cardConfig = [], cardOrientation, onCardClick }) => {
  const [rankName, setRankName] = useState("");

  useEffect(() => {
    if (ranks?.length) {
      setRankName(ranks[rank]);
    }
    return () => {};
    //eslint-disable-next-line
  }, [rank]);

  if (rank === 0) {
    return (
      <Grid cols={1} gap="10px" style={{ maxHeight: "60vh", overflow: "auto" }}>
        {dataSet?.map((el) => (
          <GridChild>
            <ThisCard data={el} cardConfig={cardConfig} name={el[rankName]} cardOrientation={cardOrientation} rankName={rankName} onCardClick={onCardClick}></ThisCard>
          </GridChild>
        ))}
        <TotalCard cardConfig={cardConfig} dataSet={dataSet} cardOrientation={cardOrientation} onCardClick={onCardClick} />
      </Grid>
    );
  }

  if (rankName) {
    return (
      <Grid cols={1} gap="10px" style={{ maxHeight: "60vh", overflow: "auto" }}>
        {ranks?.length && data[ranks[rank - 1]]?.length
          ? data[ranks[rank - 1]][0].data?.map((el) => (
              <GridChild>
                <ThisCard data={el} cardConfig={cardConfig} name={el[rankName]} rankName={rankName} onCardClick={onCardClick}></ThisCard>
              </GridChild>
            ))
          : ""}
        <TotalCard cardConfig={cardConfig} dataSet={data && ranks?.length ? data[ranks[rank - 1]] : []} cardOrientation={cardOrientation} onCardClick={onCardClick} />
      </Grid>
    );
  }

  return "";
};

export default DataView;
