import { PersonForm, PersonAPI } from ".";
import { PersonEstablishmentConfig } from "../PersonEstablishment/config";
import { PersonTable } from "./table";

export const PersonConfig = {
  id: 7,
  name: "Person",
  apiConfig: PersonAPI,
  columns: PersonTable,
  form: PersonForm,
  relationShip: [
    {
      name: "Persona-Establecimiento",
      tableRelation: PersonEstablishmentConfig,
      table: "Establishment",
      relNameId: "idPerson",
    },
  ],
};
