import { FichaGestacionalAPI, FichaGestacionalFormComun } from "api/models/FichaGestacional";
import ListaATPPD from "components/organisms/ListasPorDocumento/ListaATPPD";
import ListaDocumentosPD from "components/organisms/ListasPorDocumento/ListaRiesgosPD";
import { RegionEESSSelector } from "components/organisms/RegionEESSSelector";
import useAxios from "core/hooks/useAxios";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Formulario } from "core/ts_formulario_model";
import { addDefaultValues } from "core/utils/form";
import { addHours, format } from "date-fns";
import React, { useEffect, useState } from "react";
const defaultIpressData = { region: [], eess: [] };

const MigracionForm = ({ paciente, registroSeleccionado, onOk }: any) => {
  const { nroDocumento } = paciente;
  const ListUltimoLugarAtencion = useAxios(FichaGestacionalAPI.ultimoLugarAtencion);
  const Data: any = useAxios(FichaGestacionalAPI.listRegionesYEESS);
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [ipressProcedencia, setIpressProcedencia] = useState(defaultIpressData);
  const [ipressAcudira, setIpressAcudira] = useState(defaultIpressData);
  const [habilitaFormulario, setHabilitaFormulario] = useState(false);

  const getEESS = async () => {
    const response = await ListUltimoLugarAtencion.startRequest({
      dni: nroDocumento,
    });
    if (Array.isArray(response) && response.length) {
      const regionProce = response[0].Departamento + "|" + response[0].Provincia + "|" + response[0].Distrito;
      setIpressProcedencia({
        region: [{ region: regionProce, regionEmoji: regionProce.replaceAll("|", " ➡️ ") }],
        eess: [
          {
            region: regionProce,
            Departamento: response[0].Departamento,
            Provincia: response[0].Provincia,
            Distrito: response[0].Distrito,
            Codigo_Unico: response[0].Codigo_Unico,
            Renaes: response[0].RENAES,
            Nombre_Establecimiento: response[0].Nombre_Establecimiento,
          },
        ],
      });
    }
    getEESSMigra();
    setHabilitaFormulario(true);
  };

  const getEESSMigra = () => {
    const regionMigra = registroSeleccionado?.regionMigra;
    const ipressAcudira = registroSeleccionado?.ipressAcudira;
    if (regionMigra && ipressAcudira) {
      const region = [{ region: regionMigra, regionEmoji: regionMigra.replaceAll("|", " ➡️ ") }];
      const eess = Data.data.establecimientos[regionMigra].filter((el) => el.Codigo_Unico === ipressAcudira);
      setIpressAcudira({
        region,
        eess,
      });
    }
  };

  useEffect(() => {
    if (nroDocumento && !Array.isArray(Data.data)) {
      getEESS();
    }

    return () => {};
    // eslint-disable-next-line
  }, [nroDocumento, Data.data]);

  const onSubmit = (data) => {
    let prev: any = {
      idTipoFicha: 1,
    };

    if (!data.idRegistro) {
      prev = {
        ...prev,
        estado: 1,
        estadoRegistro: 1,
      };
    }

    const dataOk = {
      ...data,
      ...prev,
      nroDocumento: paciente.nroDocumento,
      edadRegistro: paciente.edad,
      ipressAcudira: ipressAcudira.eess[0].Codigo_Unico,
      ipressProcedencia: ipressProcedencia.eess[0].Codigo_Unico,
      regionMigra: ipressAcudira.region[0].region,
      fecha: format(addHours(new Date(), 5), "yyyy-MM-dd"),
    };

    onOk(dataOk);
  };

  return (
    <div style={{ maxHeight: "70vh", width: "100%", overflowY: "auto" }}>
      {habilitaFormulario && (
        <Formulario onSubmit={onSubmit} style={{ width: "96%" }}>
          <Formulario.FormControls
            gap="0px"
            controlGap="5px"
            cols={isTabletOrMobile ? 2 : 4}
            inputs={addDefaultValues(
              [
                ...FichaGestacionalFormComun,
                {
                  name: "",
                  type: "title-separator",
                },
                {
                  name: "Riesgos",
                  type: "custom",
                  customProps: {
                    spanCol: "span 2",
                    render: <ListaDocumentosPD documento={nroDocumento} />,
                  },
                },
                {
                  name: "ATP",
                  type: "custom",
                  customProps: {
                    spanCol: "span 2",
                    render: <ListaATPPD documento={nroDocumento} />,
                  },
                },
                {
                  name: "Migracion",
                  type: "title-separator",
                },
                {
                  name: "IPRESS de procedencia",
                  type: "custom",
                  customProps: {
                    render: <RegionEESSSelector setRegistroSeleccionado={setIpressProcedencia} defaultValues={ipressProcedencia} />,
                    spanCol: "span 2",
                    displayName: "IPRESS de procedencia",
                    placeholder: "Selecciona una IPRESS",
                  },
                },
                {
                  name: "IPRESS que acudirá",
                  type: "custom",
                  customProps: {
                    render: <RegionEESSSelector setRegistroSeleccionado={setIpressAcudira} defaultValues={ipressAcudira} />,
                    spanCol: "span 2",
                    displayName: "IPRESS que acudirá",
                    placeholder: "Selecciona una IPRESS",
                  },
                },
                {
                  name: "motivoCambioDomicilio",
                  type: "input-description",
                  required: true,
                  customProps: {
                    spanCol: isTabletOrMobile ? "span 2" : "span 4",
                    required: true,
                    displayName: "Motivo de cambio de domicilio",
                  },
                },
                {
                  name: "direccionNuevoDomicilio",
                  type: "input",
                  required: true,
                  customProps: {
                    required: true,
                    displayName: "Dirección del nuevo domicilio",
                  },
                },
                {
                  name: "referenciaNuevoDomicilio",
                  type: "input",
                  required: true,
                  customProps: {
                    required: true,
                    displayName: "Referencia de nuevo domicilio",
                  },
                },
                {
                  name: "observacion",
                  type: "input-description",
                  required: true,

                  customProps: {
                    spanCol: isTabletOrMobile ? "span 2" : "span 4",
                    required: true,
                  },
                },
                {
                  name: "Datos familiares",
                  type: "title-separator",
                },

                {
                  name: "datosFamiliar",
                  type: "input",
                  required: true,
                  customProps: {
                    required: true,
                  },
                },
                {
                  name: "parentesco",
                  type: "input",
                  required: true,
                  customProps: {
                    required: true,
                  },
                },
                {
                  name: "estado",
                  type: "radio-button",
                  required: true,
                  optionsDropdown: [
                    { value: 1, label: "Activo" },
                    { value: 2, label: "Inactivo" },
                  ],
                  disabled: true,
                  optionLabel: "label",
                  optionValue: "value",
                  customProps: {
                    required: true,
                  },
                },
                {
                  name: "estadoRegistro",
                  type: "input",
                  disabled: true,
                  required: true,
                  customProps: {
                    required: true,
                  },
                },
              ],
              {
                idTipoFicha: 1,
                estado: 1,
                estadoRegistro: 1,
                ...registroSeleccionado,
                riesgosObstetricos: registroSeleccionado?.riesgoObstetrico,
              }
            )}
          />
        </Formulario>
      )}
    </div>
  );
};

export default MigracionForm;
