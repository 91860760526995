import { RoleForm, RoleAPI } from ".";
import { ModuleConfig, RolModuleConfig } from "../ConfigAPI";
import { RoleTable } from "./table";

export const RoleConfig = {
  id: 9,
  name: "Role",
  apiConfig: RoleAPI,
  columns: RoleTable,
  form: RoleForm,
  relationShip: [
    {
      name: "rol-module",
      tableRelation: RolModuleConfig,
      table: ModuleConfig,
      relNameId: "idRol",
    },
  ],
};
