import { Card } from "antd";
import { Column, Grid } from "core/util-styled-components";
import React, { useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js";
import FilterHomeGlobal from "core/components/molecules/FilterHomeGlobal";
import useAxios from "core/hooks/useAxios";
import { HomeMaternalDeathsReportAPI } from "api/models/MaternalDeaths/api";
import { FirstHomeChartOptions } from "api/models/MaternalDeaths/charts";
import { PregnatReport2Options, PregnatReport4Options, PregnatReportOptions } from "api/models/Pregnat/charts";
import { HomePregnatReport2API, HomePregnatReport4API, HomePregnatReportAPI } from "api/models/Pregnat/api";
import { useState } from "react";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import CardGraphics from "components/molecules/CardGraphics";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

const Home = () => {
  const [maxHeight, setMaxHeight] = useState("250");
  const MaternalDeathsReport = useAxios(HomeMaternalDeathsReportAPI);
  const PregnatReport = useAxios(HomePregnatReportAPI);
  const Pregnat2Report = useAxios(HomePregnatReport2API);
  const Pregnat4Report = useAxios(HomePregnatReport4API);
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [cols, setCols] = useState(2);
  const [open, setOpen] = useState(false);
  const [fuente2023, setFuente2023] = useState(null);

  useEffect(() => {
    if (isTabletOrMobile) {
      setCols(1);
    }

    return () => {};
  }, [isTabletOrMobile]);

  useEffect(() => {
    onFilter({
      anio: "2024",
      nivel: "PROVINCIA",
      ambito: "",
    });
    // eslint-disable-next-line
  }, []);

  const onFilter = async (data) => {
    try {
      if (data.anio === "2023") {
        setFuente2023("OEPI-DIRESA PIURA | SE 52-2023 - MUERTES MATERNAS POR OCURRENCIA");
      } else {
        setFuente2023(null);
      }

      if (data.nivel === "DISTRITO") {
        setCols(1);
        setMaxHeight("500px");
      } else {
        if (!isTabletOrMobile) {
          setMaxHeight("250px");
          setCols(2);
        }
      }
      await MaternalDeathsReport.startRequest(data);
      await PregnatReport.startRequest(data);
      await Pregnat4Report.startRequest(data);
      await Pregnat2Report.startRequest(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Column gap="10px">
      <FilterHomeGlobal onFilter={onFilter} onTutorial={() => setOpen(true)} />
      <Card size="small" style={{ border: "none", background: "transparent" }} bodyStyle={{ padding: 0, maxWidth: "100%" }}>
        <Grid cols={cols} width={"100%"} gap="10px">
          <CardGraphics
            type="bar"
            optionsChart={FirstHomeChartOptions}
            dataAPI={MaternalDeathsReport}
            footerType="MUERTES MATERNAS"
            staticVarName={{
              DISTRITO: "TOTAL MUERTES MATERNAS",
              PROVINCIA: "TOTAL MUERTES MATERNAS",
              SUBREGION: "TOTAL MUERTES MATERNAS",
            }}
            fuente={fuente2023}
          />
          <CardGraphics
            type="bar"
            optionsChart={PregnatReportOptions}
            dataAPI={PregnatReport}
            staticVarName={{
              TOTAL: "TOTAL GEST.",
              ANEMIAG: "GEST. CON ANEMIA",
            }}
            open={open}
            setOpen={setOpen}
            maxHeight={maxHeight}
          />
          <CardGraphics
            type="bar"
            optionsChart={PregnatReport2Options}
            dataAPI={Pregnat2Report}
            staticVarName={{
              TOTAL: "TOTAL GEST.",
              GestanteAdolescente: "GEST. ADOLESCENTES",
            }}
            maxHeight={maxHeight}
          />
          <CardGraphics
            type="bar"
            optionsChart={PregnatReport4Options}
            dataAPI={Pregnat4Report}
            staticVarName={{
              TOTAL: "GEST.",
              GEST_ATD_1ER: "GEST. ATENDIDAS 1ER TRIM.",
            }}
            maxHeight={maxHeight}
          />
        </Grid>
      </Card>
    </Column>
  );
};

export default Home;
