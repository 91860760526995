import axios from "axios";
import AxiosTokenProvider from "axios-token-interceptor";
import { HOST_API } from "core/hooks/host_api";

export const fetchPost = async (uri, params, tokenInterceptor = "") => {
  const _itc = createInstance(HOST_API, tokenInterceptor);
  try {
    return await _itc.post(uri, params);
  } catch (error) {
    throw error;
  }
};

export const fetchGET = async (uri, tokenInterceptor = "", config = {}) => {
  const _itc = createInstance(HOST_API, tokenInterceptor);
  try {
    return await _itc.get(uri, config);
  } catch (error) {
    throw error;
  }
};

export const createInstance = (baseURL, tokenInterceptor) => {
  const instance = axios.create({
    baseURL,
  });
  if (tokenInterceptor) {
    instance.interceptors.request.use(
      AxiosTokenProvider({
        getToken: () => tokenInterceptor,
      })
    );
  }
  return instance;
};
