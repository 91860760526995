import React from "react";
import { LogoStyled } from "./styled";
import { Column, Heading, Typography } from "core/util-styled-components";
// import { useUsuario } from "hooks/ContextUser";
// import { useNavigate } from "react-router-dom";
const Logo = ({
  reverse,
  contentWidth,
  width,
  variant = "span",
  subtextVariant = "span",
  letters = true,
  text = "SEGESPPIURA",
  subtext = "Seguimiento de gestante y puerpera",
}) => {
  // const nav = useNavigate();

  const handleClickLogo = () => {
    //   nav("/");
  };

  return (
    <LogoStyled
      reverse={reverse}
      onClick={handleClickLogo}
      contentWidth={contentWidth}
      width={width}
    >
      <img src={"./LOGO.png"} alt="..." />

      {letters && (
        <Column alignItems="flex-start">
          <Heading variant={variant}>{text}</Heading>
          <Typography variant={subtextVariant}>{subtext}</Typography>
        </Column>
      )}
    </LogoStyled>
  );
};

export default Logo;
