import { HOST_API } from "core/hooks/host_api";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
export const generateCrudApi = (name, prefix = "") => {
  return {
    add: {
      method: "POST",
      url: `/api/v1/${name}/add`,
      executeAtStart: false,
    },
    add2: {
      method: "POST",
      url: `/api/v1/${name}/add-2`,
      executeAtStart: false,
    },
    update: {
      method: "POST",
      url: `/api/v1/${name}/update`,
      executeAtStart: false,
    },
    delete: {
      method: "POST",
      url: `/api/v1/${name}/delete`,
      executeAtStart: false,
    },
    list: {
      method: "GET",
      url: `/api/v1/${name}/list`,
      executeAtStart: true,
    },
  };
};

export const generateRelationCrudApi = (name) => {
  return {
    add: {
      method: "POST",
      url: `/api/v1/${name}/add`,
      executeAtStart: false,
      host_api: HOST_API,
    },
    update: {
      method: "POST",
      url: `/api/v1/${name}/update`,
      executeAtStart: false,
      host_api: HOST_API,
    },
    delete: {
      method: "POST",
      url: `/api/v1/${name}/delete`,
      executeAtStart: false,
      host_api: HOST_API,
    },
    list: {
      method: "POST",
      url: `/api/v1/${name}/list`,
      executeAtStart: false,
      executeAtUpdate: false,
      host_api: HOST_API,
    },
  };
};

export const generateDropdown = ({ name, idName, description, list }): IInputPayload => {
  return {
    name: idName,
    type: "dropdown",
    optionsDropdown: [],
    optionLabel: name,
    optionValue: idName,
    customProps: {
      listApiConfig: list,
      spanCol: "span 2",
      displayName: description,
      placeholder: "Selecciona un " + description?.toLowerCase(),
    },
  };
};
