export function interfaceToPropertyArray<T>(
  obj: T
): { name: string; type: string }[] {
  const propertyArray: { name: string; type: string }[] = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propertyType = typeof obj[key];
      propertyArray.push({ name: key, type: propertyType });
    }
  }

  return propertyArray;
}
