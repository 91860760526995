import { generateMapTable } from "core/utils/tables";

export const VigilanceTable = generateMapTable([
  "idVigilancia",
  "unidadEjecutora",
  "distrito",
  "ipress",
  "idPersona",
  "edad",
  "procedencia",
  "fur",
  "eg",
  "idFactorRiesgo",
  "otroFactorRiesgo",
  "referencia",
  "ipressReferencia",
  "fechaAtencionReferencia",
  "observaciones",
  "respuestaIPRESS",
  "estado",
  "idUsuario",
]);
