import Layout from "core/components/organism/Container/Layout";
import Administrador from "pages/Admin/Administrador";
import Usuario from "pages/Admin/Usuario";
import Login from "pages/Auth/Login";
import Dashboard from "pages/Dashboard";
import Home from "pages/Home";
import BaseCuts from "pages/Modules/BaseCut";
import FichaGestante from "pages/Modules/FichaGestante";
import Notifications from "pages/Modules/Notifications";
import Pregnats from "pages/Modules/Pregnats";
import TimelyAcquisition from "pages/Modules/TimelyAcquisition";
import Usuarios from "pages/Modules/Usuarios";
import Vigilance from "pages/Modules/Vigilance";
import TermEmb from "pages/Modules/Vigilance/TermEmb";
import { createBrowserRouter } from "react-router-dom";

const addLayout = (element) => {
  return <Layout>{element}</Layout>;
};

const generatePrivateRoutes = (routes) => {
  return routes.map((route) => {
    if (route.children) {
      return {
        ...route,
        children: generatePrivateRoutes(route.children),
      };
    }
    return {
      ...route,
      element: addLayout(route.element),
    };
  });
};

const Routesx = createBrowserRouter([
  {
    path: "/Login",
    element: <Login />,
  },
  // {
  //   path: "/RegistarUsuario",
  //   element: <RegistarUsuario />,
  // },
  ...generatePrivateRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/administrador",
      element: <Administrador />,
    },
    {
      path: "/configurar-usuarios",
      element: <Usuario />,
    },
    {
      path: "/notificaciones",
      element: <Notifications />,
    },
    {
      path: "/vigilancia",
      element: <Vigilance />,
    },
    {
      path: "/gestantes",
      element: <Pregnats />,
    },
    {
      path: "/usuarios",
      element: <Usuarios />,
    },
    {
      path: "/captacion-oportuna",
      element: <TimelyAcquisition />,
    },
    {
      path: "/admin/corte-bases",
      element: <BaseCuts />,
    },
    {
      path: "/ficha-gestante",
      element: <FichaGestante />,
    },
    {
      path: "/listado-termino-embarazo",
      element: <TermEmb />,
    },
  ]),
]);

export default Routesx;
