import { ModuleForm, ModuleAPI } from ".";
import { ModuleEndPointConfig } from "../ModuleEndPoint/config";
import { ModuleRouteConfig } from "../ModuleRoute/config";
import { ModuleTable } from "./table";

export const ModuleConfig = {
  id: 3,
  name: "Module",
  apiConfig: ModuleAPI,
  columns: ModuleTable,
  form: ModuleForm,
  relationShip: [
    {
      name: "module-endpoint",
      tableRelation: ModuleEndPointConfig,
      table: "EndPoint",
      relNameId: "idModulo",
    },
    {
      name: "module-route",
      tableRelation: ModuleRouteConfig,
      table: "Route",
      relNameId: "idModulo",
    },
  ],
};
