import { AUTH_ROUTE_PREFFIX } from "api/constants";
import { generateRelationCrudApi } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";
import { RoleDropdownDefinition } from "../Role";
import { ModuleDropdownDefinition } from "../Module";

export interface RolModule {
  idRol: number;
  rol?: string;
  idModulo: number;
  modulo?: string;
}
export const RolModuleAPI = generateRelationCrudApi(
  AUTH_ROUTE_PREFFIX + "rol-module"
);

export const RolModuleForm: IInputPayload[] = generateMapForm([
  RoleDropdownDefinition,
  ModuleDropdownDefinition,
]);
