import { Button, Card, Checkbox, Input, Modal, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import { Column, Div, Grid, Row, Typography } from "core/util-styled-components";
import { Variables } from "api/models/Pregnat";
import { PregnatConfig } from "api/models/Pregnat/config";
import useAxios from "core/hooks/useAxios";
import { generateMapTable } from "core/utils/tables";
import DocumentRender from "./components/DocumentRender";
import { ListAttendanceDetails } from "api/models/Pregnat/api";
import { addHours, format } from "date-fns";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import Excel from "core/utils/excel";
import { PregnatDataDictionary } from "core/constants/Diccionario";
import { orderVariablesTablePregnatData } from "core/utils/util.core";
import Filter from "./components/Filter";
import { useLogged } from "core/hooks/loggedContext";
import { useLocation } from "react-router-dom";

const Pregnats = () => {
  const { state } = useLocation();
  const [varSelected, setVarSelected] = useState(Variables);
  const [activeModal, setActiveModal] = useState(false);
  const [filterUbicacion, setFilterUbicacion] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [DNI, setDNI] = useState(state?.dni);
  const { metadata } = useLogged();

  const [filteredData, setFilteredData] = useState([]);

  const List = useAxios(PregnatConfig?.apiConfig?.list);
  const ListAttDetails = useAxios(ListAttendanceDetails);

  const onClickTag = (data) => {
    ListAttDetails.startRequest({
      nroDocumento: data.Num_doc,
      fechaAtencion: data.val,
      variables: data.name,
    });
    setActiveModal(true);
  };

  const onExcelExport = () => {
    Excel(
      filteredData.map((el) => {
        return {
          Documento: el.Num_doc,
          ...orderVariablesTablePregnatData(varSelected).reduce((prev, item) => {
            return {
              ...prev,
              [item.nuevaDenominacion]: el[item.anteriorDescripcion],
            };
          }, {}),
        };
      }),
      "Gestantes - data 2023"
    );
  };

  const applyFilter = (el) => {
    if (DNI?.length) {
      return el.Num_doc.includes(DNI);
    }

    if (filterUbicacion?.length) {
      return el.Ubicacion.includes(filterUbicacion);
    }
    if (varSelected?.length) {
      return varSelected.reduce((prev, item) => prev || el[item], false);
    }

    return Boolean(el);
  };

  useEffect(() => {
    setFilteredData(List.data.filter(applyFilter));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [List.data, DNI, varSelected, filterUbicacion]);

  return (
    <Card
      title={`Lista de ${PregnatConfig.name}`}
      extra={
        <Row gap="10px">
          <Input placeholder="Buscar por DNI" style={{ maxWidth: "150px" }} value={DNI} onChange={(e) => setDNI(e.target.value)} />
          <Button
            icon={<i className="ri-filter-3-line" />}
            onClick={() => {
              setFilterModal(true);
            }}
          >
            Filtrar variables
          </Button>
          <Button
            type="primary"
            onClick={() => {
              message.loading({ content: "Descargando data...", key: "download" });
              onExcelExport();
              message.success({ content: "Data descargada", key: "download", duration: 2 });
            }}
            loading={false}
            icon={<i className="ri-file-excel-line" />}
          >
            {isTabletOrMobile ? "" : "Descargar data"}
          </Button>
        </Row>
      }
    >
      <Column gap="20px">
        <Modal title="Selecciona las variables a mostrar" open={filterModal} onCancel={() => setFilterModal(false)} footer={false} centered width={"60%"}>
          <Grid cols={4} gap="20px" style={{ marginTop: "20px" }}>
            {PregnatDataDictionary.map((item) => (
              <Row
                gap={"10px"}
                style={{
                  minWidth: "max-content",
                }}
              >
                <Checkbox
                  checked={varSelected.includes(item.anteriorDescripcion)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setVarSelected([...varSelected, item.anteriorDescripcion]);
                    } else {
                      setVarSelected(varSelected.filter((el) => el !== item.anteriorDescripcion));
                    }
                  }}
                />{" "}
                {/* {item.item} {item.nuevaDenominacion} */}
                {item.nuevaDenominacion}
              </Row>
            ))}
          </Grid>
          <Row margin="10px 0" justifyContent="flex-end" gap="10px">
            <Button
              icon={<i className="ri-list-check-3" />}
              type="link"
              onClick={() => {
                setVarSelected(Variables);
              }}
            >
              Marcar todos
            </Button>
            <Button
              icon={<i className="ri-list-check-2" />}
              type="link"
              onClick={() => {
                setVarSelected([]);
              }}
            >
              Desmarcar todos
            </Button>
          </Row>
        </Modal>
        {!(metadata.esDiresa === 0 && metadata.subregion === "0") && <Filter value={filterUbicacion} onChange={setFilterUbicacion} />}

        <Div width={"100%"} style={{ overflow: "auto" }}>
          <Table
            bordered
            size="small"
            loading={List.loading}
            dataSource={filteredData}
            columns={generateMapTable([
              {
                title: "Documento",
                dataIndex: "Num_doc",
                fixed: "left",
                render: (x) => <DocumentRender dni={x} />,
              },
              ...orderVariablesTablePregnatData(varSelected).map((el: any) => {
                return {
                  title: (
                    <Row style={{ minWidth: el.size }} justifyContent="space-between">
                      <Typography variant="label">{el.nuevaDenominacion}</Typography>
                      <Tag color="blue-inverse">
                        <Typography variant="span" color="inherit">
                          {filteredData.reduce((prev, item) => {
                            return prev + (item[el.anteriorDescripcion] ? 1 : 0);
                          }, 0)}
                        </Typography>
                      </Tag>
                    </Row>
                  ),
                  render: (item) => (
                    <Tag
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onClickTag({
                          name: el.anteriorDescripcion,
                          val: item[el.anteriorDescripcion],
                          Num_doc: item.Num_doc,
                        });
                      }}
                    >
                      <Typography color="inherit" variant="label">
                        {item[el.anteriorDescripcion]}
                      </Typography>
                    </Tag>
                  ),
                };
              }),
            ])}
          />
        </Div>
      </Column>
      <Modal
        title={"Detalles de la atención"}
        open={activeModal}
        onOk={() => {
          setActiveModal(false);
        }}
        onCancel={() => {
          setActiveModal(false);
        }}
      >
        {ListAttDetails?.data?.map((el) => {
          return (
            <Column>
              <Row>
                <Typography style={{ minWidth: "55px", fontWeight: "bold" }}>Fecha</Typography>
                <Typography>: {format(addHours(new Date(el.Fecha_atencion), 5), "dd/MM/yyyy")}</Typography>
              </Row>
              <Row>
                <Typography style={{ minWidth: "55px", fontWeight: "bold" }}>EESS</Typography>
                <Typography>
                  : {el.RENAES} - {el.Nombre_Establecimiento}
                </Typography>
              </Row>
              <Row>
                <Typography style={{ minWidth: "55px", fontWeight: "bold" }}>Edad</Typography>
                <Typography>: {el.edadAtencion} años</Typography>
              </Row>
            </Column>
          );
        })}
      </Modal>
    </Card>
  );
};

export default Pregnats;
