import { generateMapTable } from "core/utils/tables";

// export const BaseCutsTable = generateMapTable(["fuente", "fechaCorteBase", "horaCorteBase", "SemanaEpidemiologica", "Estado"]);
export const BaseCutsTable = generateMapTable([
  "tipo",
  "fuente",
  {
    title: "Sem. Epi",
    dataIndex: "SemanaEpidemiologica",
  },
  {
    title: "Fecha de corte de base",
    dataIndex: "fechaCorteBase",
    render: (value: string) => {
      return value.split("T")[0];
    },
  },
]);
