import { generateCrudApi, generateDropdown } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";

export interface Establishment {
  idEstablecimiento?: number;
  codigoRenaes?: string;
  categoria?: string;
  nombre?: string;
  diresa?: string;
  red?: string;
  direccion?: string;
  ubigeo?: string;
  estado?: string;
}

export const EstablishmentAPI = generateCrudApi("establishment");

export const EstablishmentForm: IInputPayload[] = generateMapForm([
  "codigoRenaes",
  "categoria",
  "nombre",
  "diresa",
  "red",
  "direccion",
  "ubigeo",
  "estado",
]);

export const EstablishmentDropdownDefinition: IInputPayload = generateDropdown({
  name: "nombre",
  idName: "idEstablecimiento",
  description: "Establecimiento",
  list: EstablishmentAPI.list,
});
