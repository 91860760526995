import { generateCrudApi } from "api/generador";
import { generateMapTable } from "core/utils/tables";

export const TimelyAcquisitionAPI = generateCrudApi("timely-acquisition");

export const TimelyAcquisitionForm = generateMapTable([]);

export const TimelyAcquisitionTable = generateMapTable([
  "SUBREGION",
  "Provincia",
  "Distrito",
  "renaes",
  "ESTABLECIMIENTO",
  "num_doc",
  "edad_reg",
  "fecha_atc",
  "Atiende",
  "Año",
  "m",
  "Mes",
  "nMes",
  "Gestantes_1er_CPN",
  "CPN_oportuno_I_TRIM",
  "num",
  "Hem",
  "Sif",
  "V",
  "Bact",
]);
