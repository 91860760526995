import React, { useState, useEffect } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js";
import { PieConfig, PregnatReport4Options } from "api/models/Pregnat/charts";
import { Column, Row } from "core/util-styled-components";
import { Card } from "antd";
import ExtraCardTotalChart from "core/components/atoms/ExtraCardTotalChart";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { FooterCard } from "components/molecules/FooterCard";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);
const DefaultConfig = { labels: [], datasets: [] };

const transformToChart = (data, rankName, cardConfig, responsive = false) => {
  return data
    ? {
        labels: data.map((el) => el[rankName]),
        datasets: cardConfig.map((config) => {
          return {
            label: config.name,
            data: data.map((el) => el[config.prop]),
            borderColor: config.color,
            backgroundColor: config.color,
          };
        }),
      }
    : DefaultConfig;
};

const transformToPieChart = (data, rankName) => {
  return data
    ? {
        labels: ["10-17", "18-34", "35-Mas"],
        datasets: [
          {
            label: rankName,
            data: ["10-17", "18-34", "35-Mas"].map((l) => data.reduce((prev, acc) => prev + acc[l], 0)),
            borderColor: ["#F875AA", "#AEDEFC", "#FFDFDF", "#FFF6F6"],
            backgroundColor: ["#F875AA", "#AEDEFC", "#FFDFDF", "#FFF6F6"],
          },
        ],
      }
    : DefaultConfig;
};

const ChartView = ({ data, dataSet, rank, ranks, cardConfig, chartType = "line", footerType = "PRINCIPAL", fuente = null }) => {
  const [rankName, setRankName] = useState("");
  const { isTabletOrMobile } = useMediaQueryCustom();

  const [chartDataSets, setChartDataSets] = useState(DefaultConfig);
  const [chartPieDataSets, setChartPieDataSets] = useState(DefaultConfig);

  useEffect(() => {
    if (ranks?.length) {
      setRankName(ranks[rank]);
    }
    return () => {};
    //eslint-disable-next-line
  }, [rank]);

  const configureChart = () => {
    if (rankName) {
      if (rank === 0) {
        return transformToChart(dataSet, rankName, cardConfig, isTabletOrMobile);
      } else {
        if (ranks?.length) {
          const myDataChart = data[ranks[rank - 1]]?.length ? data[ranks[rank - 1]][0].data : [];
          return transformToChart(myDataChart, rankName, cardConfig, isTabletOrMobile);
        }
      }
    }

    return DefaultConfig;
  };

  const configurePieChart = () => {
    if (rankName) {
      if (rank === 0) {
        return transformToPieChart(dataSet, rankName);
      } else {
        if (ranks?.length) {
          const myDataChart = data[ranks[rank - 1]]?.length ? data[ranks[rank - 1]][0].data : [];
          return transformToPieChart(myDataChart, rankName);
        }
      }
    }
    return DefaultConfig;
  };

  useEffect(() => {
    setChartDataSets(configureChart());
    if (chartType === "pie") {
      setChartPieDataSets(configurePieChart());
    }

    return () => {};
    //eslint-disable-next-line
  }, [rankName, data, dataSet]);

  return (
    <Card
      size={isTabletOrMobile ? "small" : "default"}
      title="Distribución"
      style={{ width: isTabletOrMobile ? "100%" : "60%", alignSelf: "start" }}
      extra={<ExtraCardTotalChart data={chartDataSets} />}
    >
      <Column
        alignItems="center"
        style={
          isTabletOrMobile
            ? {
                minWidth: "100%",
                width: "100%",
                height: "250px",
                minHeight: "250px",
                maxHeight: "250px",
              }
            : {}
        }
      >
        <div style={{ width: "100%" }}>{chartType === "line" && <Bar style={{ minHeight: "50vh" }} options={PregnatReport4Options} data={chartDataSets} />}</div>

        {chartType === "pie" && (
          <div style={{ width: "40%" }}>
            <Pie data={chartPieDataSets} options={PieConfig} />
          </div>
        )}
        <Row width="100%" style={{ marginTop: 20 }} justifyContent="space-between">
          <div></div>
          <FooterCard type={footerType} fuente={fuente} />
        </Row>
      </Column>
    </Card>
  );
};

export default ChartView;
