import {
  TimelyAcquisitionAPI,
  TimelyAcquisitionForm,
  TimelyAcquisitionTable,
} from ".";

export const TimelyAcquisitionConfig = {
  id: 20,
  name: "Capatacion oportuna",
  apiConfig: TimelyAcquisitionAPI,
  columns: TimelyAcquisitionTable,
  form: TimelyAcquisitionForm,
};
