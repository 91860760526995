import React, { useEffect, useState } from "react";
import { message, Table, Button, Modal } from "antd";
import useAxios from "core/hooks/useAxios";
import { HOST_API } from "core/hooks/host_api";
import { Column, Row, Typography } from "core/util-styled-components";
import { generateMapForm, generateMapTable } from "core/utils/tables";
import { Formulario } from "core/ts_formulario_model";
import { addDefaultValues } from "core/utils/form";
import { PacientDocs, TipoDocDropdown } from "api/models/Pacient/data";

const calculateAge = (dateIssue) => {
  try {
    const today = new Date();
    const birthDate = new Date(dateIssue);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } catch (error) {
    console.log(error);
  }
};

const PacientFilterV2 = ({ data, onChange, validateTelf = true, dni_preload, tipoDoc }) => {
  const UpdateInsert = useAxios({
    host_api: HOST_API,
    executeAtStart: false,
    url: "/api/v1/vigilance/insert-update-pacient-vigilance",
    method: "POST",
  });
  const ReniecService = useAxios({
    host_api: HOST_API,
    executeAtStart: false,
    url: "/api/v1/vigilance/get-pacient-data",
    method: "POST",
  });

  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    dni_preload && handleSearch(dni_preload);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dni_preload]);

  const handleSearch = async (value: string) => {
    // Aquí puedes agregar la lógica para filtrar los pacientes
    const response = await ReniecService.startRequest({
      tipoDoc,
      nroDocumento: value,
    });
    if (response?.nombreCompleto !== "undefined undefined undefined") {
      onChange({
        ...response,
        edad: response?.fechaNacimiento ? calculateAge(response?.fechaNacimiento) : null,
      });
    } else {
      onChange({
        nroDocumento: dni_preload,
      });
      // message.error("No se encontró el paciente, es posible que no esté registrado en RENIEC o que haya ingresado un DNI incorrecto");
    }
  };

  return (
    <Column gap="5px">
      <Row gap="20px" width={"100%"} justifyContent="space-between">
        <Typography>Datos del paciente </Typography>
        <Button
          onClick={() => {
            setVisibleModal(true);
          }}
        >
          Modificar datos personales
        </Button>
      </Row>
      <Table
        size="small"
        loading={ReniecService.loading}
        pagination={false}
        dataSource={[data]}
        columns={generateMapTable([
          {
            dataIndex: "dni",
            title: "DNI",
            render: (text, record) => `${record.nroDocumento}`,
          },
          {
            dataIndex: "nombreCompleto",
            title: "Nombre",
            render: (text, record) => `${record.apellidoPaterno || "---"} ${record.apellidoMaterno || "----"} ${record.nombres || "-----"}`,
          },
          {
            dataIndex: "edad",
            title: "Edad",
            render: (text, record) => `${record?.edad || record?.fechaNacimiento ? calculateAge(record?.fechaNacimiento) : "---"} años aprox.`,
          },
          {
            dataIndex: "fechaNacimiento",
            title: "Fecha de nacimiento",
            render: (text, record) => `${record.fechaNacimiento ? record.fechaNacimiento?.split("T")[0] : "----"}`,
          },
          {
            dataIndex: "genero",
            title: "Sexo",
            render: (text, record) => `${record.idTipoSexo ? (record.idTipoSexo === 1 ? "MASCULINO" : "FEMENINO") : "------"}`,
          },
          {
            title: "Ubigeo",
            render: (text, record) => `${record.direccionDomicilio || "------"}`,
          },
          {
            title: "Celular",
            render: (text, record) => `${record.celular ?? "----"}`,
          },
          {
            title: "Celular 2",
            render: (text, record) => `${record.celular2 ?? "----"}`,
          },
          {
            title: "Correo",
            render: (text, record) => `${record.correo ?? "----"}`,
          },
          {
            title: "Fuente",
            render: (text, record) => (record.type === "reniec" ? "RENIEC" : "LOCAL"),
          },
        ])}
      />
      {validateTelf && data?.genero === "MASCULINO" && (
        <Typography style={{ color: "red", fontWeight: "bold", textAlign: "center", width: "100%", marginTop: 20 }}>
          El paciente es de sexo masculino, por lo que no puede ser atendido en este módulo
        </Typography>
      )}

      <Modal
        title="Modificar información de paciente"
        open={visibleModal}
        onCancel={() => {
          setVisibleModal(false);
        }}
        footer={false}
        destroyOnClose
        width={"60%"}
        bodyStyle={{
          maxWidth: "95%",
        }}
      >
        <Formulario
          onSubmit={async (__data: any) => {
            const tipo = __data?.idDocIdentidad;
            console.log(tipo);

            const id = PacientDocs.find((x) => x.Abrev_Tipo_Doc === tipo)?.Id_Tipo_Documento;
            console.log(id);

            const response = await UpdateInsert.startRequest({
              ...__data,
              nroDocumento: dni_preload,
              idPaciente: data?.idPaciente,
              idDocIdentidad: id,
            });
            if (response.error) {
              message.error(response.message);
            } else {
              response.message && message.success(response.message);
              setVisibleModal(false);
              handleSearch(dni_preload);
            }
          }}
        >
          <Formulario.FormControls
            onCancel={() => {
              setVisibleModal(false);
            }}
            cols={3}
            inputs={addDefaultValues(
              generateMapForm([
                TipoDocDropdown,
                {
                  name: "nroDocumento",
                  type: "input",
                  customProps: {
                    displayName: "Nr. Documento",
                    disabled: true,
                  },
                },
                {
                  name: "apellidoPaterno",
                  type: "input",
                  customProps: {
                    displayName: "Apellido paterno",
                  },
                },
                {
                  name: "apellidoMaterno",
                  type: "input",
                  customProps: {
                    displayName: "Apellido materno",
                  },
                },
                {
                  name: "nombres",
                  type: "input",
                  customProps: {
                    displayName: "Nombres",
                  },
                },
                {
                  name: "fechaNacimiento",
                  type: "input",
                  customProps: {
                    type: "date",
                    displayName: "Fecha de nacimiento",
                  },
                },
                {
                  name: "idTipoSexo",
                  type: "radio-button",
                  optionsDropdown: [
                    { name: "Masculino", value: 1 },
                    { name: "Femenino", value: 2 },
                  ],
                  optionLabel: "name",
                  optionValue: "value",
                  customProps: {
                    displayName: "Sexo",
                  },
                },
                {
                  name: "direccionDomicilio",
                  type: "input",
                  customProps: {
                    displayName: "Ubigeo",
                  },
                },
                {
                  name: "celular",
                  type: "input",
                  customProps: {
                    displayName: "Celular 1",
                  },
                },
                {
                  name: "celular2",
                  type: "input",
                  customProps: {
                    displayName: "Celular 2",
                  },
                },
                {
                  name: "correo",
                  type: "input",
                  customProps: {
                    displayName: "Correo",
                    type: "email",
                  },
                },
              ]),
              {
                ...data,
                idDocIdentidad: tipoDoc,
                fechaNacimiento: data?.fechaNacimiento?.split("T")[0],
              }
            )}
          />
        </Formulario>
      </Modal>
    </Column>
  );
};

export default PacientFilterV2;
