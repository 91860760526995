import { EndPoint } from "api/models/EndPoint";
import { Module } from "api/models/Module";
import { ModuleEndPoint } from "api/models/ModuleEndPoint";
import { ModuleRoute } from "api/models/ModuleRoute";
import { RolModule } from "api/models/RolModule";
import { Role } from "api/models/Role";
import { Route } from "api/models/Route";
import { User } from "api/models/User";
import { Person } from "./Person";
import { Establishment } from "./Establishment";
import { PersonEstablishment } from "./PersonEstablishment";
import { Pacient } from "./Pacient";

export const defaultUser: User = {
  idUsuario: 1,
  usuario: "usuario123",
  contraseña: "contraseña123",
  idPersona: 101,
  nombreCompleto: "asdas",
  idRol: 201,
  rol: "sadasd",
  estado: 0,
  avatar: "avatar.jpg",
};

export const defaultRole: Role = {
  idRol: 1,
  nombre: "Rol de Administrador",
  tokenAccess: "token123",
  fechaRegistro: new Date("2023-10-15"),
  estado: 0,
};

export const defaultModule: Module = {
  idModulo: 1,
  nombre: "Módulo de Usuarios",
  icono: "icono-usuario.png",
  estado: 0,
  link: "/usuarios",
  idSuperModulo: null,
};

export const defaultRoute: Route = {
  idRuta: 1,
  ruta: "ruta-usuario",
  path: "/usuarios",
  estado: 0,
};

export const defaultEndPoint: EndPoint = {
  idEndPoint: 1,
  metodoHTTP: "GET",
  url: "/api/usuarios",
  estado: 0,
  nombre: "Obtener Usuarios",
  descripcion: "Endpoint para obtener la lista de usuarios",
};

export const defaultRolModule: RolModule = {
  idRol: 201,
  rol: "Adm",
  idModulo: 1,
  modulo: "aosdkaos",
};

export const defaultModuleEndPoint: ModuleEndPoint = {
  idModulo: 1,
  idEndPoint: 1,
};

export const defaultModuleRoute: ModuleRoute = {
  idModulo: 1,
  idRuta: 1,
};

export const defaultPerson: Person = {
  idPersona: 201,
  nombreCompleto: "Loreasado",
  nroDocumento: "12345678",
  telefono: "987654321",
  email: "maria.lopez@example.com",
};
export const defaultPacient: Pacient = {
  idPaciente: 201,
  nombreCompleto: "Loreasado",
  nroDocumento: "12345678",
  telefono: "987654321",
  email: "maria.lopez@example.com",
};

export const defaultEstablishment: Establishment = {
  idEstablecimiento: 1,
  categoria: "aosdkpa",
  nombre: "adsdasd",
  codigoRenaes: "adsas",
  diresa: "asdasd",
  red: "adsdas",
};

export const defaultPersonEstablishment: PersonEstablishment = {
  idEstablecimiento: 1,
  idPersona: 1,
};
