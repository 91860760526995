import { message } from "antd";
import { HOST_API } from "core/hooks/host_api";
import { useLogged } from "core/hooks/loggedContext";
import useAxios from "core/hooks/useAxios";
import { Formulario } from "core/ts_formulario_model";
import { generateMapForm } from "core/utils/tables";
import React from "react";

const TerminoEmbarazo = ({ paciente, onClose }) => {
  const { metadata } = useLogged();
  const Tipos: any = useAxios({
    method: "GET",
    executeAtStart: true,
    host_api: HOST_API,
    url: "/api/v1/embarased-end/types/list",
  });

  const APICrear = useAxios({
    method: "POST",
    executeAtStart: false,
    host_api: HOST_API,
    url: "/api/v1/embarased-end/insert",
  });

  const onSave = async (data) => {
    try {
      const params = {
        fechaTerminoEmbarazo: data.fechaTerminoEmbarazo,
        idTipoDocumento: paciente.idTipoDocumento,
        num_doc: paciente.Num_doc,
        idTipoTerminoEmbarazo: data.idTipoTerminoEmbarazo[0].idTipoTerminoEmbarazo,
        observaciones: "",
        estado: 1,
        idUsuarioRegistra: metadata.idUsuario,
      };
      console.log(params);
      const response = await APICrear.startRequest(params);
      message[response.error ? "error" : "success"](response.message);
      onClose();
    } catch (error) {
      message.error("No se ha podido crear Termino de embarazo");
    }
  };

  return (
    <div style={{ width: "95%" }}>
      <Formulario onSubmit={onSave}>
        <Formulario.FormControls
          inputs={generateMapForm([
            {
              name: "fechaTerminoEmbarazo",
              type: "input",
              customProps: {
                type: "date",
                displayName: "Fecha termino de embarazo",
              },
            },
            {
              name: "idTipoTerminoEmbarazo",
              type: "dropdown",
              optionsDropdown: Tipos.data?.recordset || [],
              optionLabel: "descripcion",
              optionValue: "idTipoTerminoEmbarazo",
            },
          ])}
        ></Formulario.FormControls>
      </Formulario>
    </div>
  );
};

export default TerminoEmbarazo;
