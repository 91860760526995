import xlsx from "xlsx";

export const readExcel = (file, sheetName = "data") => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, {
        type: "binary",
      });
      var wsnames = workbook.SheetNames;
      if (wsnames.includes(sheetName)) {
        const worksheet = workbook.Sheets[sheetName];
        const arr = xlsx.utils.sheet_to_json(worksheet);
        resolve(arr);
      } else {
        resolve([]);
      }
      // wsnames = wsnames.map((sheetname) => {
      //   const worksheet = workbook.Sheets[sheetname];
      //   // areaSeleccionable: "A1:G50"
      //   const areaEnNumeros = calcularAreaEnNumeros(worksheet["!ref"]);
      //   const table = [];
      //   for (let i = areaEnNumeros.rowStart; i <= areaEnNumeros.rowEnd; i++) {
      //     const row = [];
      //     for (let j = areaEnNumeros.colStart; j <= areaEnNumeros.colEnd; j++) {
      //       row.push(worksheet[calcularLetras(j) + i]?.v);
      //     }
      //     row.filter((item) => item !== undefined).length > 0 &&
      //       table.push(row);
      //   }

      //   const result = {
      //     dependencia: sheetname,
      //     data: table,
      //   };
      //   return result;
      // });
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};
