import XLSX from "xlsx";
import { saveAs } from "file-saver";

export default function Excel(data: any[], title: string) {
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: title,
    Subject: "Test file",
    Author: "Frank Nolasco",
    CreatedDate: new Date(),
  };
  wb.SheetNames.push(title);
  var ws_data = data;
  var ws = XLSX.utils.json_to_sheet(ws_data);
  wb.Sheets[title] = ws;
  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const s2ab = (s) => {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let index = 0; index < s.length; index++) {
      view[index] = s.charCodeAt(index) & 0xff;
    }
    return buf;
  };
  saveAs(new Blob([s2ab(wbout)]), title + ".xlsx");
}

export function ExcelMoreTabs(data: any[], title: string) {
  var wb = XLSX.utils.book_new();
  wb.Props = {
    Title: title,
    Subject: "Test file",
    Author: "Frank Nolasco",
    CreatedDate: new Date(),
  };

  for (const sheet of Object.keys(data)) {
    wb.SheetNames.push(sheet);
    var ws_data = data[sheet];
    var ws = XLSX.utils.json_to_sheet(ws_data);
    wb.Sheets[sheet] = ws;
  }
  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  const s2ab = (s) => {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let index = 0; index < s.length; index++) {
      view[index] = s.charCodeAt(index) & 0xff;
    }
    return buf;
  };
  saveAs(new Blob([s2ab(wbout)]), title + ".xlsx");
}

export const GetDataExcel = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      resolve(json);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};
