import { Button, Card, Radio, Tabs } from "antd";
import { ExportDataMaternalDeath } from "api/models/MaternalDeaths/api";
import { ExportDataByBirth, ExportDataByTeens, ExportDataByVariable } from "api/models/Pregnat/api";
import DashboardMatrix from "components/organisms/DashboardMatrix1";
import DashboardMatrix2 from "components/organisms/DashboardMatrix2";
import DashboardMatrix3 from "components/organisms/DashboardMatrix3";
import DashboardMatrix4 from "components/organisms/DashboardMatrix4";
import DashboardMatrix5 from "components/organisms/DashboardMatrix5";
import { useLogged } from "core/hooks/loggedContext";
import useAxios from "core/hooks/useAxios";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Column, Row, Typography } from "core/util-styled-components";
import Excel from "core/utils/excel";
import React, { useState } from "react";

const Dashboard = () => {
  const { metadata } = useLogged();
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [anio, setAnio] = useState("2023");
  const Exports1 = useAxios(ExportDataByBirth);
  const Exports2 = useAxios(ExportDataMaternalDeath);
  const Exports3 = useAxios(ExportDataByVariable);
  const Exports4 = useAxios(ExportDataByTeens);
  const Exports5 = useAxios(ExportDataByVariable);

  const [keyTab, setKeyTab] = useState("1");

  const addYear = (__data) => __data.map((el) => ({ AÑO: anio, ...el }));

  const onExcelExport = async () => {
    if (keyTab === "1") {
      const response = await Exports1.startRequest({ anio });
      Excel(addYear(response), "Gestantes por edad - Data");
    }
    if (keyTab === "2") {
      const response = await Exports2.startRequest({ anio });
      Excel(response, "Muerte maternal - Data");
    }

    if (keyTab === "3") {
      const response = await Exports3.startRequest({
        anio,
        variable: "ANEMIAG",
      });
      Excel(addYear(response), "Gestantes con anemia - Data");
    }
    if (keyTab === "4") {
      const response = await Exports4.startRequest({ anio });
      Excel(addYear(response), "Gestante adolescente - Data");
    }

    if (keyTab === "5") {
      const response = await Exports5.startRequest({
        anio,
        variable: "GEST_ATD_1ER",
      });
      Excel(addYear(response), "Atenciones del 1ER TRIM - Data");
    }
  };

  return (
    <Card
      title={
        <Typography>
          {isTabletOrMobile ? "" : "Mostrando datos de "}
          <strong>{metadata.EJECUTORA}</strong> {"> "}
          <strong>{metadata.Nombre_Establecimiento}</strong> {"> "}
          <strong>{metadata.Distrito}</strong>
        </Typography>
      }
      extra={
        <Row gap="5px">
          <Typography>Año:</Typography>
          <Radio.Group value={anio} onChange={(e) => setAnio(e.target.value)}>
            <Radio value={"2023"}>2023</Radio>
            <Radio value={"2024"}>2024</Radio>
          </Radio.Group>
        </Row>
      }
    >
      <Column gap="10px">
        <Tabs
          activeKey={keyTab}
          onChange={(k) => setKeyTab(k)}
          tabBarExtraContent={
            <Button
              type="primary"
              onClick={onExcelExport}
              loading={Exports1.loading || Exports2.loading || Exports3.loading || Exports4.loading || Exports5.loading}
              icon={<i className="ri-file-excel-line" />}
            >
              {isTabletOrMobile ? "" : "Descargar data"}
            </Button>
          }
        >
          <Tabs.TabPane tab="Gestantes" key="1">
            <DashboardMatrix anio={anio} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Muerte materna" key="2">
            <DashboardMatrix2 anio={anio} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Anemia y gestantes" key="3">
            <DashboardMatrix3 anio={anio} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Gestante adolescente" key="4">
            <DashboardMatrix4 anio={anio} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Atenciones del primer trimestre" key="5">
            <DashboardMatrix5 anio={anio} />
          </Tabs.TabPane>
        </Tabs>
      </Column>
    </Card>
  );
};

export default Dashboard;
