import { Card, Tabs } from "antd";
import { Column } from "core/util-styled-components";
import React, { useState } from "react";
import Migracion from "./Migracion";
import CambioSeguro from "./CambioSeguro";
import MigracionRecibidos from "./MigracionRecibidos";

const FichaGestante = () => {
  const [keySeletion, setKeySeletion] = useState("id-1-migracion");

  return (
    <Column>
      <Card>
        <Tabs activeKey={keySeletion} onChange={(ak) => setKeySeletion(ak)}>
          <Tabs.TabPane key={"id-1-migracion"} tab="Migración (enviados)">
            <Migracion />
          </Tabs.TabPane>
          <Tabs.TabPane key={"id-1-2-migracion"} tab="Migración (recibidos)">
            <MigracionRecibidos />
          </Tabs.TabPane>
          <Tabs.TabPane key={"id-2-cambio-seguro"} tab="Cambio de Seguro">
            <CambioSeguro />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Column>
  );
};

export default FichaGestante;
