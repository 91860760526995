import { RouteForm, RouteAPI } from ".";
import { RouteTable } from "./table";

export const RouteConfig = {
  id: 11,
  name: "Route",
  apiConfig: RouteAPI,
  columns: RouteTable,
  form: RouteForm,
};
