import { TimelyAcquisitionConfig } from "api/models/TimelyAcquisition/config";
import CRUDPage from "core/components/organism/CRUDPage";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import React from "react";

const TimelyAcquisition = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <CRUDPage
      masiveUpload
      title={`Lista de ${TimelyAcquisitionConfig.name}`}
      apiConfig={TimelyAcquisitionConfig?.apiConfig}
      rows={TimelyAcquisitionConfig?.columns}
      form={TimelyAcquisitionConfig?.form}
      useDefaultActions
      customDesignProps={{
        formCols: isTabletOrMobile ? 1 : 4,
        modalWidth: "80%",
        bodyWidth: isTabletOrMobile ? "90%" : "96%",
      }}
    />
  );
};

export default TimelyAcquisition;
