import { PregnatDataDictionary } from "core/constants/Diccionario";

export interface EdadGestacional {
  semanas: number;
  dias: number;
}

export interface ResultadoCalculo {
  fechaProbableParto: string;
  edadGestacional: EdadGestacional;
}

export function calcularFechaProbableYEdadGestacional(fur: string): ResultadoCalculo {
  // Convertir la FUR a un objeto de fecha
  const furDate = new Date(fur);

  // Calcular la FPP sumando 280 días a la FUR
  const fppDate = new Date(furDate);
  fppDate.setDate(fppDate.getDate() + 280);

  // Calcular la Edad Gestacional en días
  const currentDate = new Date();
  const gestationalDays = Math.floor((currentDate.getTime() - furDate.getTime()) / (1000 * 60 * 60 * 24));

  // Convertir la Edad Gestacional de días a semanas
  const gestationalWeeks = Math.floor(gestationalDays / 7);

  // Crear el objeto de respuesta
  const resultado: ResultadoCalculo = {
    fechaProbableParto: fppDate.toISOString().split("T")[0], // Formato YYYY-MM-DD
    edadGestacional: {
      semanas: gestationalWeeks,
      dias: gestationalDays % 7,
    },
  };

  return resultado;
}

export const orderVariablesTablePregnatData = (selected): any[] => {
  return PregnatDataDictionary.filter((el) => {
    return selected.includes(el.anteriorDescripcion);
  });
};
