import { Tag } from "antd";
import { Row, Typography } from "core/util-styled-components";
import React from "react";

const getTotalDataSet = (data) => {
  return data?.reduce((prv, curr) => prv + curr, 0);
};

const ExtraCardTotalChart = ({ ref, staticVarName = {}, data }) => {
  return (
    <Row gap="10px" ref={ref}>
      {data?.datasets?.map((dataset) => (
        <Tag color={dataset.backgroundColor + "22"}>
          <Row gap=".5em">
            <Typography variant="label" style={{ fontWeight: "bold" }} color="black">
              {staticVarName[dataset.label] || dataset.label}
            </Typography>
            <Typography
              color={dataset.backgroundColor}
              style={{
                fontWeight: "800",
                fontSize: "1.7em",
              }}
            >
              {getTotalDataSet(dataset.data)}
            </Typography>
          </Row>
        </Tag>
      ))}
    </Row>
  );
};

export default ExtraCardTotalChart;
