import { AUTH_ROUTE_PREFFIX } from "api/constants";
import { generateCrudApi, generateDropdown } from "api/generador";
import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";
import { generateMapForm } from "core/utils/tables";

export interface EndPoint {
  idEndPoint?: number;
  metodoHTTP?: string;
  url?: string;
  estado?: number;
  nombre?: string;
  descripcion?: string;
}

export const EndpointAPI = generateCrudApi(AUTH_ROUTE_PREFFIX + "endpoint");

export const EndPointForm: IInputPayload[] = generateMapForm([
  {
    name: "nombre",
    type: "input",
    customProps: {
      spanCol: "span 2",
      displayName: "Nombre",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "descripcion",
    type: "input-description",
    customProps: {
      spanCol: "span 2",
      displayName: "Detalle de la tarea",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "metodoHTTP",
    type: "radio-button",
    optionsDropdown: [{ name: "GET" }, { name: "POST" }],
    optionLabel: "name",
    optionValue: "name",
  },
  {
    name: "url",
    type: "input",
    customProps: {
      spanCol: "span 2",
      displayName: "URL destino",
      style: {
        maxHeight: "100px",
      },
    },
  },
  {
    name: "estado",
    type: "radio-button",
    optionsDropdown: [
      { name: "Activo", value: 1 },
      { name: "Inactivo", value: 0 },
    ],
    optionLabel: "name",
    optionValue: "value",
  },
]);

export const EndpointDropdownDefinition: IInputPayload = generateDropdown({
  name: "url",
  idName: "idEndPoint",
  description: "Endpoint",
  list: EndpointAPI.list,
});
