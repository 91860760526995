export const FirstHomeChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Muertes maternas",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const total = context.dataset.data.reduce((a, b) => a + b, 0);
          const percent = Math.round((parseInt(context.raw) / total) * 100);
          return `${context.dataset.label}: ${context.formattedValue}\n (${percent}%)`;
        },
      },
    },
  },
  maintainAspectRatio: false,
};
