import React, { useEffect } from "react";
import { Input, message, Card, Breadcrumb, Descriptions } from "antd";
import useAxios from "core/hooks/useAxios";
import { HOST_API } from "core/hooks/host_api";
import { Column, Heading, Row, Typography } from "core/util-styled-components";

const calculateAge = (dateIssue) => {
  try {
    const today = new Date();
    const birthDate = new Date(dateIssue);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } catch (error) {
    console.log(error);
  }
};

const PacientFilter = ({ data, onChange, defaultSearch = "", validateTelf = true }) => {
  const ReniecService = useAxios({
    host_api: HOST_API,
    executeAtStart: false,
    url: "/api/v1/search-person-reniec/",
    method: "POST",
  });

  const handleSearch = async (value: string) => {
    console.log(value);
    // Aquí puedes agregar la lógica para filtrar los pacientes
    const response = await ReniecService.startRequest({
      dni: value,
    });
    if (response?.nombreCompleto !== "undefined undefined undefined") {
      onChange({
        ...response,
        edad: calculateAge(response?.fechaNacimiento),
      });
    } else {
      onChange(null);
      message.error("No se encontró el paciente, es posible que no esté registrado en RENIEC o que haya ingresado un DNI incorrecto");
    }
  };

  useEffect(() => {
    if (!data && defaultSearch) {
      handleSearch(defaultSearch);
    }

    return () => {};
  }, [defaultSearch]);

  return (
    <Column gap="10px">
      {onChange && (
        <Input.Search
          placeholder="Buscar paciente por dni"
          onSearch={handleSearch}
          style={{ minWidth: 300 }}
          loading={ReniecService.loading}
          enterButton="Buscar"
          onPressEnter={(e) => {
            e.preventDefault();
            handleSearch(e.currentTarget.value);
          }}
        />
      )}
      <Card
        size="small"
        bodyStyle={{ padding: "0" }}
        title={
          data && data.dni ? (
            <Heading
              variant="h3"
              style={{
                textTransform: "uppercase",
                color: "#1890ff",
              }}
            >
              {data?.nombreCompleto}{" "}
            </Heading>
          ) : (
            <></>
          )
        }
        extra={data && data.dni ? <Typography>Edad: {data?.edad || calculateAge(data?.fechaNacimiento)} años.</Typography> : <></>}
      >
        {data && data.dni ? (
          <Row alignItems="flex-start" gap="20px" responsiveReorder style={{ width: "100%" }}>
            <Descriptions bordered style={{ width: "100%" }}>
              <Descriptions.Item label="DNI">{data?.dni}</Descriptions.Item>
              <Descriptions.Item label="Fecha de nacimiento">{data?.fechaNacimiento?.split("T")[0] || "----"}</Descriptions.Item>
              <Descriptions.Item label="Sexo">{data?.genero}</Descriptions.Item>
              <Descriptions.Item label="Direccion">{data?.direccion}</Descriptions.Item>
              <Descriptions.Item label="Ubigeo">
                {data?.pais} - {data?.departamento} - {data?.distrito} - {data.provincia}{" "}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        ) : (
          <Typography>Busque un paciente por su DNI para ver sus datos</Typography>
        )}
        {validateTelf && data?.genero === "MASCULINO" && (
          <Typography style={{ color: "red", fontWeight: "bold", textAlign: "center", width: "100%", marginTop: 20 }}>
            El paciente es de sexo masculino, por lo que no puede ser atendido en este módulo
          </Typography>
        )}
      </Card>
    </Column>
  );
};

export default PacientFilter;
