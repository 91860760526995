import { UserForm, UserAPI } from ".";
import { UserTable } from "./table";

export const UserConfig = {
  id: 12,
  name: "User",
  apiConfig: UserAPI,
  columns: UserTable,
  form: UserForm,
};
