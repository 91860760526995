import { Tag } from "antd";
import { generateCrudApi } from "api/generador";
import { Typography } from "core/util-styled-components";
import { generateMapTable } from "core/utils/tables";

export const PregnatAPI: any = {
  ...generateCrudApi("pregnat"),
  masive: {
    method: "POST",
    url: "/api/v1/pregnat/masive",
    executeAtStart: false,
  },
};

export const MatrixPregnatColumns = generateMapTable([
  "Num_doc",
  ...[
    "1RA BATERIA_OPORT",
    "ANEMIAG",
    "ANEMIAG_PR",
    "GEST_2do_Ctrl",
    "GEST_3er_Ctrl",
    "GEST_4to_Ctrl",
    "GEST_5to_Ctrl",
    "GEST_6to_Ctrl",
    "GEST_ATD",
    "GEST_ATD_1ER",
    "G_SF1",
    "G_SF2",
    "G_SF3",
    "G_SF4",
    "G_SF5",
    "G_SF6",
    // "Infec_Urinarias",
    // "PAQ_GEST",
    "P_Parto1",
    "P_Parto2",
    "P_Parto3",
    "P_PartoEfec",
    "VIOLENCIA_G",
  ].map((el: any) => {
    return {
      title: el,
      dataIndex: el,
      render: (val) => (
        <Tag>
          <Typography color="inherit" variant="label">
            {val}
          </Typography>
        </Tag>
      ),
    };
  }),
]);

export const PregnatColumns = generateMapTable([
  "SUB_REGION",
  "PROVINCIA",
  "UBIGEO",
  "IPRESS",
  "ESTABLECIMIENTO",
  "CATEGORIA",
  "DISTRITO",
  "CLAS",
  "MICRORED",
  "RED",
  "EJECUTORA",
  "Mes",
  "GEST_ATD",
  "GEST_ATD_1ER",
  "PAQ_GEST",
  "P_Parto1",
  "ADOLES_1ER_Ctrl",
  "GEST_2do_Ctrl",
  "GEST_3er_Ctrl",
  "GEST_4to_Ctrl",
  "GEST_5to_Ctrl",
  "GEST_6to_Ctrl",
  "BATERIA_OPORT_1RA",
  "BATERIA_OPORT_2DA",
  "G_SF1",
  "G_SF2",
  "G_SF3",
  "G_SF4",
  "G_SF5",
  "G_SF6",
  "P_Parto2",
  "P_Parto3",
  "P_PartoEfec",
  "F34",
  "ANEMIAG",
  "ANEMIAG_PR",
  "VIOLENCIA_G",
  // "Preeclampsia",
  // "Eclampsia",
  // "Sindrome_Hellp",
]);

export const Variables = [
  "1RA BATERIA_OPORT",
  "Hem",
  "Vih",
  "Sif",
  "Bact",
  "2DA BATERIA_OPORT",
  "ADOLES_1ER_Ctrl",
  "ANEMIAG",
  "ANEMIAG_PR",
  "GEST_2do_Ctrl",
  "GEST_3er_Ctrl",
  "GEST_4to_Ctrl",
  "GEST_5to_Ctrl",
  "GEST_6to_Ctrl",
  "GEST_7mo_Ctrl",
  "GEST_8vo_Ctrl",
  "GEST_9no_Ctrl",
  "GEST_10mo_Ctrl",
  "GEST_11vo_Ctrl",
  "GEST_12vo_Ctrl",
  "GEST_ATD",
  "GEST_ATD_1ER",
  "G_SF1",
  "G_SF2",
  "G_SF3",
  "G_SF4",
  "G_SF5",
  "G_SF6",
  "G_SF7",
  "G_SF8",
  "G_SF9",
  // "Infec_Urinarias",
  // "PAQ_GEST",
  "P_Parto1",
  "P_Parto2",
  "P_Parto3",
  "P_PartoEfec",
  "VIOLENCIA_G",
  "ITU_1",
  "ITU_2",
  "ITU_3",
  // "Eclampsia",
  // "Preeclampsia",
  // "S. Hellp",
  "TTO_SF1",
  "TTO_SF2",
  "TTO_SF3",
  "TTO_SF4",
  "TTO_SF5",
  "TTO_SF6",
  "TTO_SF7",
  "TTO_SF8",
  "TTO_SF9",
];

export const VariablesDropdown = Variables.map((el) => {
  return { name: el };
});

export const ListByDNIColumn = generateMapTable([
  "Tipo_Doc_Madre",
  "NU_Doc_Madre",
  "Lugar_Nacido1",
  "FE_NACIDO1",
  "Condicion_Parto1",
  "Lugar_Nacido2",
  "FE_NACIDO2",
  "Condicion_Parto2",
  "Lugar_Nacido3",
  "FE_NACIDO3",
  "Condicion_Parto3",
  "Lugar_Nacido4",
  "FE_NACIDO4",
  "Condicion_Parto4",
]);
