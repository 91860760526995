import { Table } from "antd";
import { FichaGestacionalAPI } from "api/models/FichaGestacional";
import useAxios from "core/hooks/useAxios";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import React, { useEffect } from "react";

const getColor = (color) => {
  return color === "AMARILLO" ? "🟡" : color === "ROJO" ? "🔴" : color === "VERDE" ? "🟢" : "⚪";
};
const ListaDocumentosPD = ({ documento }) => {
  const FactoresRiesgo = useAxios(FichaGestacionalAPI.listaFactoresRiesgo);
  const getFactoresRiesgo = async () => {
    await FactoresRiesgo.startRequest({
      dni: documento,
    });
  };

  useEffect(() => {
    if (documento) {
      getFactoresRiesgo();
    }

    return () => {};
    // eslint-disable-next-line
  }, [documento]);
  return (
    <Table
      size="small"
      dataSource={FactoresRiesgo.data.sort((a: any, b: any) => new Date(b.Fecha_atencion).getTime() - new Date(a.Fecha_atencion).getTime())}
      columns={generateMapTable([
        { dataIndex: "Fecha_atencion", render: (val) => format(addHours(new Date(val), 5), "dd/MM/yyyy") },
        { dataIndex: "Riesgo", render: (val, row) => `${getColor(row.color)} ${row.descripcion}` },
      ])}
    />
  );
};

export default ListaDocumentosPD;
