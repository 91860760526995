import { IInputPayload } from "core/ts_formulario_model/src/types/interfaces";

export const addDefaultValues = (
  form: IInputPayload[],
  defaultValues?: { [key: string]: any }
) => {
  if (defaultValues) {
    return form.map((el) => {
      return {
        ...el,
        defaultValue: getValue(el, defaultValues),
        required:
          el.type === "input" &&
          el.customProps &&
          el.customProps.type === "file"
            ? false
            : el.required,
        customProps: {
          ...el.customProps,
          defaultValue: getValue(el, defaultValues),
        },
      };
    });
  }

  return form;
};

const getValue = (
  el: IInputPayload,
  registroSeleccionado: { [key: string]: any }
) => {
  // if (el.type === "dropdown") {
  // return getValueDropdown(el, registroSeleccionado[el.name]);
  // }

  if (el.type === "input" && el.customProps && el.customProps.type === "date") {
    return Boolean(registroSeleccionado[el.name])
      ? new Date(registroSeleccionado[el.name]).toISOString().split("T")[0]
      : "";
  }

  if (el.type === "switch") {
    return Boolean(registroSeleccionado[el.name]);
  }

  return registroSeleccionado[el.name];
};

export const getValueDropdown = (
  data: IInputPayload[],
  dataForm: { [key: string]: any }
) => {
  const dropdownFields = data.filter((item) => item.type === "dropdown");

  // Crea un objeto para almacenar los resultados
  const result = {};

  // Itera a través de los elementos 'dropdown' y extrae los valores de 'optionValue' del objeto 'dataForm'
  dropdownFields.forEach((field) => {
    const fieldName = field.optionValue;
    if (fieldName in dataForm) {
      if (dataForm[fieldName].length && dataForm[fieldName][0]) {
        result[fieldName] = dataForm[fieldName][0][fieldName];
      }
    }
  });
  return result;
};
