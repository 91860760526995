import { FichaGestacionalAPI, FichaGestacionalFormComun } from "api/models/FichaGestacional";
import ListaDocumentosPD from "components/organisms/ListasPorDocumento/ListaRiesgosPD";
import useAxios from "core/hooks/useAxios";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Formulario } from "core/ts_formulario_model";
import { addDefaultValues } from "core/utils/form";
import { addHours, format } from "date-fns";
import React from "react";

const CambioSeguroForm = ({ paciente, registroSeleccionado, onOk }: any) => {
  const { nroDocumento } = paciente;
  const TiposSeguro = useAxios(FichaGestacionalAPI.listTipoSeguro);
  const { isTabletOrMobile } = useMediaQueryCustom();

  const onSubmit = (data) => {
    let prev: any = {
      idTipoFicha: 2,
    };

    if (!data.idRegistro) {
      prev = {
        ...prev,
        estado: 1,
        estadoRegistro: 1,
      };
    }

    const dataOk = {
      ...data,
      ...prev,
      nroDocumento: paciente.nroDocumento,
      edadRegistro: paciente.edad,
      fecha: format(addHours(new Date(), 5), "yyyy-MM-dd"),
    };

    onOk(dataOk);
  };
  return (
    <div style={{ maxHeight: "70vh", width: "100%", overflowY: "auto" }}>
      <Formulario onSubmit={onSubmit} style={{ width: "96%" }}>
        <Formulario.FormControls
          gap="0px"
          controlGap="5px"
          cols={isTabletOrMobile ? 2 : 4}
          inputs={addDefaultValues(
            [
              ...FichaGestacionalFormComun,
              {
                name: "",
                type: "title-separator",
              },
              {
                name: "Riesgos",
                type: "custom",
                customProps: {
                  spanCol: "span 2",
                  render: <ListaDocumentosPD documento={nroDocumento} />,
                },
              },
              {
                name: "Cambio de seguro",
                type: "title-separator",
              },
              {
                name: "tipoSeguro",
                type: "radio-button",
                optionsDropdown: TiposSeguro.data,
                optionLabel: "Descripcion",
                optionValue: "idTipoSeguro",
                required: true,
                customProps: {
                  spanCol: "span 2",
                  required: true,
                },
              },
              {
                name: "motivoCambioSeguro",
                type: "input-description",
                required: true,
                customProps: {
                  spanCol: "span 2",
                  required: true,
                },
              },

              {
                name: "Datos familiares",
                type: "title-separator",
              },

              {
                name: "datosFamiliar",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                },
              },
              {
                name: "parentesco",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                },
              },
              {
                name: "observacion",
                type: "input-description",
                required: true,

                customProps: {
                  spanCol: isTabletOrMobile ? "span 2" : "span 4",
                  required: true,
                },
              },
              {
                name: "estado",
                type: "radio-button",
                required: true,

                optionsDropdown: [
                  { value: 1, label: "Activo" },
                  { value: 2, label: "Inactivo" },
                ],
                disabled: true,
                optionLabel: "label",
                optionValue: "value",
                customProps: {
                  required: true,
                },
              },
              {
                name: "estadoRegistro",
                type: "input",
                disabled: true,
                required: true,
                customProps: {
                  required: true,
                },
              },
            ],
            {
              idTipoFicha: 1,
              fecha: format(addHours(new Date(), 5), "yyyy/MM/dd"),
              estado: 1,
              estadoRegistro: 1,
              ...registroSeleccionado,
              riesgosObstetricos: registroSeleccionado?.riesgoObstetrico,
            }
          )}
        />
      </Formulario>
    </div>
  );
};

export default CambioSeguroForm;
