import { DashboardMatrix5API, ExportDataByBirth } from "api/models/Pregnat/api";
import { useLogged } from "core/hooks/loggedContext";
import useAxios from "core/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import DashboardMatrixBase from "../DashboardMatrixBase";
import { Theme } from "theme/styled";
import { Button, Modal, Table, Tabs, Tag } from "antd";
import { generateMapTable } from "core/utils/tables";
import { addHours, format } from "date-fns";
import { Column, Row, Typography } from "core/util-styled-components";
import Excel from "core/utils/excel";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardMatrix = ({ anio }) => {
  // const { isTabletOrMobile } = useMediaQueryCustom();

  const Exports1 = useAxios(ExportDataByBirth);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [resumen, setResumen] = useState({});
  const { metadata } = useLogged();
  const DashboardMatrix = useAxios(DashboardMatrix5API);
  const [dropdownValues, setDropdownValues] = useState({});
  const [rank, setRank] = useState("");
  const getData = async () => {
    const data = await DashboardMatrix.startRequest({
      anio,
    });
    if (data && data.data && data.data.length) {
      setRank(0);
      setDropdownValues({
        [data.jerarquia[0]]: [data.data[0]],
      });
    }
    Exports1.startRequest({ anio });
  };

  const getDataEESS = async () => {
    console.log("getDataEESS");
    const data = await DashboardMatrix.startRequest({
      anio,
    });
    if (data && data.data && data.data.length) {
      setRank(data.jerarquia.length - 1);
      // generate dropdown values for each rank

      let _dropdownValues = {
        [data.jerarquia[0]]: [data.data[0]],
      };

      for (let i = 1; i < data.jerarquia.length; i++) {
        const rank = _dropdownValues[data.jerarquia[i - 1]];
        _dropdownValues = {
          ..._dropdownValues,
          [data.jerarquia[i]]: rank[0].data,
        };
      }

      console.log("dropdownValues", _dropdownValues);

      setDropdownValues(_dropdownValues);
    }
    Exports1.startRequest({ anio });
  };

  useEffect(() => {
    if (metadata.esDiresa === 0 && metadata.subregion === "0") {
      getDataEESS();
    } else {
      getData();
    }
    return () => {};
    // eslint-disable-next-line
  }, [metadata, anio]);

  return (
    <div>
      <DashboardMatrixBase
        name="Gestantes atendidas por grupo etario"
        data={DashboardMatrix.data?.data}
        rank={rank}
        setRank={setRank}
        ranks={DashboardMatrix.data?.jerarquia}
        dropdownValues={dropdownValues}
        setDropdownValues={setDropdownValues}
        chartConfig={[
          {
            name: "10-17 Años",
            color: "#F875AA",
            prop: "10-17",
          },
          {
            name: "18-34 Años",
            color: "#48B9FF",
            prop: "18-34",
          },
          {
            name: "35-Mas",
            color: "#FFA3A3",
            prop: "35-Mas",
          },
        ]}
        onCardClick={({ rankName, name, data }) => {
          setResumen({
            ...data,
            rankName,
          });
          let breadcum = [];
          const ranks = DashboardMatrix.data?.jerarquia;
          for (let index = 0; index < rank; index++) {
            breadcum.push({
              name: ranks[index],
              value: dropdownValues[ranks[index]][0][ranks[index]],
            });
          }
          setModalData(Exports1.data?.filter((data) => data[rankName] === name && breadcum.reduce((prev, el) => prev && data[el.name] === el.value, true)));
          setModalVisible(true);
        }}
        cardConfig={[
          {
            name: "Total",
            color: Theme.colors.primary,
            prop: "cantidad",
          },
        ]}
        chartType="pie"
      />
      <Modal
        title={"Detallado de gestantes por edad - " + resumen[resumen.rankName]}
        footer={null}
        open={modalVisible}
        onCancel={() => {
          setModalData([]);
          setModalVisible(false);
        }}
        centered
        bodyStyle={{
          overflow: "auto",
        }}
        width={"80%"}
      >
        <Tabs
          tabBarExtraContent={
            <Button
              type="primary"
              onClick={() => {
                Excel(
                  modalData.sort((a, b) => a.edad - b.edad).map((el) => ({ año: anio, ...el })),
                  "Gestantes por edad - Data"
                );
              }}
              icon={<i className="ri-file-excel-line" />}
            >
              Descargar data
            </Button>
          }
        >
          <Tabs.TabPane tab="Resumen" key="resumen">
            <Row width={"100%"} gap="30px" justifyContent="center">
              <Tag color="#F875AA" style={{ width: "150px" }}>
                <Column alignItems="center" gap="5px" margin="5px">
                  <Typography color="inherit">10 - 17 años </Typography>
                  <Typography variant="h3" color="inherit" style={{ fontWeight: "bold" }}>
                    {resumen["10-17"]} ({parseFloat((resumen["10-17"] / resumen["cantidad"]) * 100).toFixed(2) + "%"})
                  </Typography>
                </Column>
              </Tag>
              <Tag color="#48B9FF" style={{ width: "150px" }}>
                <Column alignItems="center" gap="5px" margin="5px">
                  <Typography color="inherit">18 - 34 años </Typography>
                  <Typography variant="h3" color="inherit" style={{ fontWeight: "bold" }}>
                    {resumen["18-34"]} ({parseFloat((resumen["18-34"] / resumen["cantidad"]) * 100).toFixed(2) + "%"})
                  </Typography>
                </Column>
              </Tag>
              <Tag color="#FFA3A3" style={{ width: "150px" }}>
                <Column alignItems="center" gap="5px" margin="5px">
                  <Typography color="inherit">35 a más años </Typography>
                  <Typography variant="h3" color="inherit" style={{ fontWeight: "bold" }}>
                    {resumen["35-Mas"]} ({parseFloat((resumen["35-Mas"] / resumen["cantidad"]) * 100).toFixed(2) + "%"})
                  </Typography>
                </Column>
              </Tag>
              <Tag color={Theme.colors.primary} style={{ width: "150px" }}>
                <Column alignItems="center" gap="5px" margin="5px">
                  <Typography color="inherit">TOTAL </Typography>
                  <Typography variant="h3" color="inherit" style={{ fontWeight: "bold" }}>
                    {resumen["cantidad"]}
                  </Typography>
                </Column>
              </Tag>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Detalle" key="detalle">
            <Table
              size="small"
              dataSource={
                // order by edad desc
                modalData.sort((a, b) => a.edad - b.edad)
              }
              columns={generateMapTable([
                "SUBREGION",
                "CLAS",
                "DISA",
                "MICRORED",
                "PROVINCIA",
                "DISTRITO",
                {
                  title: "IPRESS",
                  dataIndex: "NOMBRE_ESTABLECIMIENTO",
                },
                {
                  title: "DOCUMENTO",
                  dataIndex: "NUM_DOC",
                },
                {
                  title: "NACIMIENTO",
                  dataIndex: "FECHA_NAC",
                  render: (f) => format(addHours(new Date(f), 5), "dd/MM/yyyy"),
                },
                {
                  title: "EDAD",
                  dataIndex: "edad",
                },
              ])}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default DashboardMatrix;
